import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import KeenSlider from 'keen-slider';

import { AvifPipe } from '../../pipe/avif/avif.pipe';

/**
 * Компонент для отображения карусели/слайдера с использованием KeenSlider.
 */
@Component({
  selector: 'app-keen-slider',
  standalone: true,
  imports: [CommonModule, AvifPipe],
  templateUrl: './keen-slider.component.html',
  styleUrls: ['./keen-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeenSliderComponent implements OnDestroy, AfterViewInit {
  /**
   * Представляет ссылку на элемент слайдера, либо как ElementRef типа HTMLElement, либо как null.
   *
   * @type {ElementRef<HTMLElement> | null}
   */
  @ViewChild('sliderRef') sliderRef: ElementRef<HTMLElement> | null = null;
  /**
   * Массив слайдов.
   *
   * Каждый слайд имеет следующие свойства:
   *
   * - image: URL изображения, связанного со слайдом.
   *
   * - opacity: Уровень прозрачности слайда. Это должно быть число от 0 (полностью прозрачный) до 1 (полностью непрозрачный).
   *
   * @type {Array<{ image: string, opacity: number }>}
   */
  @Input({ required: true }) slides: { image: string; opacity: number }[] = [];
  /**
   * Представляет генератор событий, который испускает события, связанные с движением.
   *
   * @type {EventEmitter<{ distance: number; progress: number }[]>}
   */
  @Output() move = new EventEmitter<{ distance: number; progress: number }[]>();
  /**
   * Представляет элемент слайдера.
   *
   * @typedef {Object} KeenSlider
   * @property {function(): void} prev - Переходит к предыдущему слайду.
   * @property {function(): void} next - Переходит к следующему слайду.
   * @property {function(number): void} moveToSlide - Переходит к указанному слайду.
   * @property {function(): void} destroy - Уничтожает экземпляр слайдера.
   */
  slider: KeenSlider | null = null;
  /**
   * Выполняет действия после инициализации представления.
   * @return {void}
   */
  ngAfterViewInit(): void {
    if (this.sliderRef) {
      this.slider = new KeenSlider(this.sliderRef.nativeElement, {
        loop: true,
        touchControl: false,
        virtualSlides: this.slides.length,
        moveDuration: 3000,
        move: (details): void => this.move.emit(details.progressSlides as unknown as { distance: number; progress: number }[]),
      });
      setInterval(() => this.slider?.next(), 4000);
    }
  }

  /**
   * Очищает компонент и уничтожает использованные ресурсы.
   * @return {void}
   */
  ngOnDestroy(): void {
    this.slider?.destroy();
  }
}
