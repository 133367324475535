<button class="btn btn_left" type="button" [class.btn_hidden]="activeSlideIndex() === 0" (click)="switchSlide(-1)">
  <svg-icon name="arrowRight" />
</button>
<button class="btn btn_right" type="button" [class.btn_hidden]="activeSlideIndex() === images().length - 1" (click)="switchSlide(1)">
  <svg-icon name="arrowRight" />
</button>
<ul class="list" (click)="!isModal && openModal()">
  @for (image of images(); track $index) {
    <li class="list__item" [style.margin-left.%]="$index === 0 ? activeSlideIndex() * -100 : null">
      <img alt="Картинка" class="list__img" i18n-alt="@@object-card.info.image-alt.carousel" loading="eager" [src]="image" />
    </li>
  }
</ul>
