import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * Сервис для управления панелью инструментов.
 */
@Injectable({ providedIn: 'root' })
export class ToolbarService {
  /**
   * Представляет поток событий для закрытия подпанелей.
   * boolean parameter means withoutNavigate
   * @typedef {Subject<boolean>} CloseSubpanelsEvent
   */
  closeSubpanelsEvent$ = new Subject<boolean>();
}
