import { Injectable, inject } from '@angular/core';

import { SetControlMode } from './control-modes.interface';
import { ControlModesStore } from './control-modes.store';

/**
 * Класс сервиса для управления контрольным режимом в приложении.
 *
 * @class ControlModesService
 */
@Injectable({ providedIn: 'root' })
export class ControlModesService {
  /**
   * Представляет переменную "store", в которую внедряется экземпляр ControlModelStore.
   * "Store" используется для управления и хранения контрольных моделей.
   *
   * @class
   * @constructor
   * @param {ControlModesStore} ControlModelStore - Экземпляр ControlModelStore для внедрения.
   */
  store = inject(ControlModesStore);

  /**
   * Представляет режим управления системы.
   *
   * @typedef {string} ControlMode
   */
  controlMode = this.store.controlMode;

  /**
   * Переменная, указывающая на то, сохраняются ли данные в данный момент.
   * @type {boolean}
   */
  isSaving = this.store.isSaving;

  /**
   * Переменная isMovementMode, которая содержит значение из хранилища isMovementMode.
   * @type {boolean}
   */
  isMovementMode = this.store.isMovementMode;

  /**
   * Получает предыдущий активный тип, не связанный с движением, из хранилища.
   * @type {string}
   */
  prevActiveNonMovementType = this.store.prevActiveNonMovementType;

  /**
   * Получает значение свойства isHidden из объекта store
   * @type {boolean}
   */
  isHidden = this.store.isHidden;

  /**
   * Проверяет, является ли значение переменной isUninitialized неопределенным или неинициализированным.
   * @type {boolean}
   */

  isUninitialized = this.store.isUninitialized;

  /**
   * Извлекает режим управления из хранилища.
   *
   * @returns {void} Этот метод не возвращает значение.
   */
  getControlMode(): void {
    this.store.getControlMode();
  }

  /**
   * Устанавливает режим управления активным один из 4 режимов управления(Криптаграфическое управление, Свободная камера, Режим
   * 'Аватар', Режим 'Автомобиль')
   *
   * @param {SetControlMode} controlMode - Устанавливаемый режим управления.
   * @param {boolean} [sendReq=true] - Указывает, следует ли отправить запрос на установку режима управления. По умолчанию устанавливается в true.
   *
   * @return {void}
   */
  setControlMode({ controlMode, sendReq = true }: SetControlMode): void {
    this.store.setControlMode({ controlMode, sendReq });
  }

  /**
   * Выход из режима размещения персонажа.
   * @returns {void}
   */
  exitCharacterPlacementMode(): void {
    this.store.exitCharacterPlacementMode();
  }
}
