import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ofType } from '@ngrx/effects';
import { ActionsSubject } from '@ngrx/store';
import { EMPTY, Observable, debounceTime, distinctUntilChanged, filter, forkJoin, map, of, switchMap, startWith } from 'rxjs';

import { ControlMode } from '../components/control-modes/control-modes.interface';
import { LightToggleService } from '../components/light-toggle-button/light-toggle.service';
import { LightType } from '../components/light-toggle-button/light.enum';
import { ObjectCardService } from '../components/object-card/object-card.service';
import { DEBOUNCE_TIME } from '../constants/app.constant';
import { RpcMessagesFrontend } from '../enums/rpc-messages.enum';
import { OsmCardService } from '../modules/osm/osm-card.service';
import { OsmService } from '../modules/osm/osm.service';
import { LevelsNames } from '../modules/toolbar/levels/levels.interface';
import { openLevelByName } from '../modules/toolbar/levels/store/levels.actions';
import {
  OpenIncidentCardParam,
  OpenObjectCardParam,
  SetCartographicDragEnabledParam,
  UpdateLightActivationTypeParam,
  setCameraCoordinatesParam,
} from '../types/jsonrpc.interface';
import { deleteObjectProperty } from '../utils/immutable.util';
import { isEmpty } from '../utils/is-empty.util';
import { PixelStreamingService } from './pixelstreaming.service';

/**
 * Сервис для обработки событий фронтенда, связанных с функциональностью потоковой передачи пикселей.
 */
@Injectable({ providedIn: 'root' })
export class FrontendEventsService {
  /**
   * RxJS Observable, представляющий состояние возможности перетаскивания картографического изображения.
   *
   * @type {Observable<boolean>}
   */
  cartographicDragEnabled$ = of(false);

  /**
   * Создает экземпляр Конструктора.
   *
   * @param {Document} document - Объект документа, используемый в приложении.
   * @param {Router} router - Объект маршрутизатора, используемый для навигации.
   * @param {PixelStreamingService} pixelStreamingService - Сервис функционала пиксельного потокового вещания.
   * @param {ObjectCardService} objectCardService - Сервис функционала указателя объектов.
   * @param {ActivatedRoute} activatedRoute - Фактически используемый маршрут.
   * @param {LightToggleService} lightToggleService - Сервис для переключения света.
   * @param {ActionsSubject} actionsSubject$ - Подписчик на действия.
   *
   *
   */
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private pixelStreamingService: PixelStreamingService,
    private objectCardService: ObjectCardService,
    private activatedRoute: ActivatedRoute,
    private lightToggleService: LightToggleService,
    private actionsSubject$: ActionsSubject,
    private osmService: OsmService,
    private osmCardService: OsmCardService,
  ) {}

  /**
   * Создает подписки на различные события от PixelStreamingService.
   * Этот метод отвечает за подписку на такие события, как блокировка и разблокировка курсора,
   * включение drag and drop на карте, открытие информационных карточек объектов и показ фрейма с функциями.
   *
   * @returns {void}
   */
  initEvents(): void {
    this.lockCursorEvent();
    this.openIncidentCard();
    this.openObjectCardEvent();
    this.unlockCursorEvent();
    this.closeObjectCardEvent();
    this.clearSearchPathEvent();
    this.screenCalibrationEvent();
    this.setCameraCoordinatesEvent();
    this.updateLightActivationTypeEvent();
    this.setCartographicDragEnabledEvent();
  }

  /**
   * Обновить событие режима управления.
   *
   * @return {Observable<ControlMode>} Наблюдаемый объект, который выдает обновленный режим управления.
   */
  updateControlModeEvent(): Observable<ControlMode> {
    return this.pixelStreamingService.on<{ params: ControlMode }>(RpcMessagesFrontend.UPDATE_CONTROL_MODE).pipe(map((data) => data.params));
  }

  /**
   * Обновляет параметры запроса в URL.
   *
   * @param {string} key - Ключ параметра запроса для обновления. Должен быть одним из: 'coords', 'data' или 'q'.
   * @param {string} [value] - Новое значение для параметра запроса. Необязательный параметр.
   * @return {Promise<boolean>} - Промис, который разрешается в true, если навигация прошла успешно, или false в противном случае.
   */
  updateQueryParams(key: 'coords' | 'data' | 'q', value?: string): Promise<boolean> {
    const queryParamsWithoutKey = this.activatedRoute.snapshot.queryParams[key]
      ? deleteObjectProperty(this.activatedRoute.snapshot.queryParams, key)
      : this.activatedRoute.snapshot.queryParams;
    const queryParams = { ...queryParamsWithoutKey, ...(value && { [key]: value }) };

    return this.router.navigate([this.document.location.pathname], { queryParams: isEmpty(queryParams) ? null : queryParams });
  }

  /**
   * Блокирует событие курсора, запрашивая блокировку указателя на элементе контейнера видео.
   *
   * @private
   * @returns {void}
   */
  private lockCursorEvent(): void {
    this.pixelStreamingService
      .on(RpcMessagesFrontend.LOCK_CURSOR)
      .subscribe(() => this.document.querySelector('.video-container')?.requestPointerLock());
  }

  /**
   * Осуществляет разблокировку события курсора.
   *
   * Данный метод отвечает за обработку события, осуществляющего разблокировку курсора.
   * Он отписывается от "RpcMessagesFrontend.UNLOCK_CURSOR" события и вызывает метод "exitPointerLock" объекта документа.
   *
   * @returns {void}
   *
   * @private
   */
  private unlockCursorEvent(): void {
    this.pixelStreamingService.on(RpcMessagesFrontend.UNLOCK_CURSOR).subscribe(() => this.document.exitPointerLock());
  }

  /**
   * Включает или отключает событие перетаскивания на картографическом изображении.
   *
   * @return {void}
   */
  private setCartographicDragEnabledEvent(): void {
    this.cartographicDragEnabled$ = this.pixelStreamingService
      .on<{ params: SetCartographicDragEnabledParam }>(RpcMessagesFrontend.SET_CARTOGRAPHIC_DRAG_ENABLED)
      .pipe(
        distinctUntilChanged(),
        map((data) => data.params.value),
      );
  }

  /**
   * Событие открытия информационной карточки объекта.
   *
   * @private
   * @returns {void} Этот метод не возвращает никакого значения.
   */
  private openObjectCardEvent(): void {
    this.pixelStreamingService.on<{ params: OpenObjectCardParam }>(RpcMessagesFrontend.OPEN_OBJECT_CARD).subscribe((data) => {
      this.objectCardService.setData(data.params.objectInfo);
    });
  }

  /**
   * Обрабатывает событие закрытия отображения карточки объекта.
   *
   * @returns {void}
   */
  private closeObjectCardEvent(): void {
    this.pixelStreamingService.on(RpcMessagesFrontend.CLOSE_OBJECT_CARD).subscribe(() => {
      this.objectCardService.clearData();
    });
  }

  /**
   * Очищает событие поискового пути.
   *
   * @returns {void}
   */
  private clearSearchPathEvent(): void {
    this.pixelStreamingService.on(RpcMessagesFrontend.CLEAR_SEARCH_PATH).subscribe(() => this.router.navigate(['']));
  }

  /**
   * Запускает событие калибровки экрана и обновляет разрешение области просмотра.
   *
   * @private
   * @returns {void}
   */
  private screenCalibrationEvent(): void {
    this.pixelStreamingService
      .on(RpcMessagesFrontend.SCREEN_CALIBRATION)
      .subscribe(() => this.pixelStreamingService.matchViewportResolution());
  }

  /**
   * Устанавливает событие для координат камеры при движении по основной карте
   *
   * @private
   * @returns {void}
   */
  private setCameraCoordinatesEvent(): void {
    this.actionsSubject$
      .pipe(
        ofType(openLevelByName),
        startWith({ level: { name: LevelsNames.bpMetaMoscowMap } }),
        switchMap(({ level }) => {
          if (level.name === LevelsNames.bpMetaMoscowMap) {
            return this.pixelStreamingService
              .on<{
                params: setCameraCoordinatesParam;
              }>(RpcMessagesFrontend.SET_CAMERA_COORDINATES)
              .pipe(
                filter(() => !this.objectCardService.isSearchObjectCardOpen()),
                debounceTime(DEBOUNCE_TIME),
              );
          } else {
            this.updateQueryParams('coords');
            return EMPTY;
          }
        }),
      )
      .subscribe((data) => {
        if (data.params.coordinates.length === 3 && data.params.rotation.length === 2) {
          this.updateQueryParams('coords', data.params.coordinates.join(',') + ',' + data.params.rotation.join(','));
        } else {
          console.error('UE: Wrong setCameraCoordinates response', data);
        }
      });
  }

  /**
   * Обновляет событие активации света.
   *
   * @private
   * @returns {void}
   */
  private updateLightActivationTypeEvent(): void {
    this.pixelStreamingService
      .on<{
        params: UpdateLightActivationTypeParam;
      }>(RpcMessagesFrontend.UPDATE_LIGHT_ACTIVATION_TYPE)
      .subscribe((data) => {
        const type = data.params.type;

        if (![LightType.NIGHT_LIGHT, LightType.NO_LIGHT, LightType.DAY_LIGHT].some((lightType) => lightType === type)) {
          console.error('UE: Wrong updateLightActivationType', type);
        } else {
          this.lightToggleService.setLightType(data.params.type as LightType);
        }
      });
  }

  private openIncidentCard(): void {
    this.pixelStreamingService
      .on<{ params: OpenIncidentCardParam }>(RpcMessagesFrontend.OPEN_INCIDENT_CARD)
      .pipe(
        switchMap((data) => {
          const { id, type: tabType } = data.params;
          if (!['incident', 'object'].includes(tabType)) {
            console.error('UE: Wrong openIncidentCard type', tabType);
            return EMPTY;
          } else {
            return forkJoin([this.osmService.getIncidentById(id), this.osmService.getObjectById(id)]).pipe(
              map(([incident, object]) => ({ incident, object, tabType })),
            );
          }
        }),
      )
      .subscribe((osm) => {
        this.osmCardService.setOsmData({ osm });
      });
  }
}
