/**
 * Проверяет, является ли текущий браузер Firefox.
 *
 * @return {boolean} - Возвращает `true`, если текущий браузер является Firefox, в противном случае `false`.
 *
 * @description
 * Этот метод проверяет, является ли текущий браузер Firefox, сравнивая строку агента пользователя со строкой 'firefox'.
 * Рекомендуется использовать обнаружение функций вместо обнаружения агента пользователя для более надежных результатов.
 */
export function isFFCheck(): boolean {
  return navigator.userAgent.toLowerCase().includes('firefox');
}
