import { Pipe, PipeTransform } from '@angular/core';

/**
 * Труба, которая добавляет префикс 'data:image/png;base64,' к входному значению.
 */
@Pipe({ name: 'prefixBase64', standalone: true })
export class PrefixBase64Pipe implements PipeTransform {
  /**
   * Преобразует входное значение в строку.
   *
   * @param {string | undefined} value - Значение, подлежащее преобразованию.
   * @return {string} - Преобразованное значение.
   */
  transform(value: string | undefined): string {
    if (!value) {
      return '';
    }

    if (value.startsWith('data:image/png;base64,')) {
      return value;
    }

    return 'data:image/png;base64,' + value;
  }
}
