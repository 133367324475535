@if (loggedIn$ | ngrxPush) {
  <ng-template #content>
    @if (isStreamReady$ | ngrxPush) {
      <app-object-card *appFeatureFlag="isObjectCardVisible() ? 'OBJECT_CARD_ENABLED' : undefined" />
      <app-control-modes *appFeatureFlag="'CONTROL_MODES'" />
      <app-light-toggle-button *appFeatureFlag="'LIGHT_TOGGLE'" />
      <router-outlet (activate)="activateRoute()" />
    }
  </ng-template>

  @if (canShowIntoPage) {
    <app-intro *ngIf="clickToPlayVisible$ | ngrxPush; else content" (clickToPlayVisibleChange)="clickToPlayVisibleChange($event)" />
  } @else {
    <ng-container *ngTemplateOutlet="content" />
  }
  <section id="video-block">
    <div #videoContainer class="video-container" [class.drag]="cartographicDragEnabled$ | ngrxPush"></div>
  </section>
} @else {
  <app-auth (loggedInChange)="loggedInChange($event)" />
}

<app-loading *ngrxLet="isPreloaderVisible$; let isPreloaderVisible" type="animation" [isVisible]="isPreloaderVisible">
  @if (manuallyShowPreloader$ | async) {
    <ng-container i18n="@@app.loading.signaling">Идёт переподключение к серверу</ng-container>
  } @else {
    <ng-container i18n="@@app.loading.data">Идет подгрузка данных</ng-container>
  }
</app-loading>
