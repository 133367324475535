import { CommonModule, DOCUMENT, PlatformLocation } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Inject,
  OnInit,
  WritableSignal,
  computed,
  inject,
  signal,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, NavigationEnd, Router, RouterModule, RouterOutlet } from '@angular/router';
import { PushPipe } from '@ngrx/component';
import { Subject, delay, filter, map, merge, mergeMap, startWith, switchMap, take } from 'rxjs';

import { ControlModesService } from '../../components/control-modes/control-modes.service';
import { DELAY_TIME, INPUT_UE_EVENTS } from '../../constants/app.constant';
import { InfoConstant } from '../../constants/info.constant';
import { BlurAfterFocusDirective } from '../../directives/blur-after-focus/blur-after-focus.directive';
import { ClickOutsideModule } from '../../directives/click-outside/click-outside.module';
import { ConvertTypePipe } from '../../pipe/convert-type/convert-type.pipe';
import { BackendEventsService } from '../../services/backend-events.service';
import { EventService } from '../../services/event.service';
import { PixelStreamingService } from '../../services/pixelstreaming.service';
import { RouterData } from '../../types/router-data.interface';
import { deleteObjectProperty } from '../../utils/immutable.util';
import { isFFCheck } from '../../utils/is-ff-check.util';
import { getPlatform } from '../../utils/platform.util';
import { FeatureFlagDirective } from '../feature-flag/feature-flag.directive';
import { FeatureFlagService } from '../feature-flag/feature-flag.service';
import { NotificationType } from '../notifications/notifications.enum';
import { NotificationsService } from '../notifications/notifications.service';
import { SvgIconModule } from '../svg-icon/svg-icon.module';
import { LayersComponent } from './layers/layers.component';
import { MAIN_MAP_LEVEL_NAME } from './levels/levels.mocks';
import { LevelsService } from './levels/levels.service';
import { PCGService } from './pcg/pcg.service';
import { SearchComponent } from './search/search.component';
import { SettingsComponent } from './settings/settings.components';
import { DELAY_TIME_FF } from './toolbar.constant';
import { TOOLBAR_ROUTES } from './toolbar.routes';
import { ToolbarService } from './toolbar.service';
import { ActivePanel } from './toolbar.types';

/**
 * Компонент панели инструментов, который предоставляет навигацию и функциональность для приложения.
 *
 * @selector app-toolbar
 * @standalone true
 * @uses [
 *   CommonModule,
 *   MatButtonModule,
 *   MatCheckboxModule,
 *   HttpClientModule,
 *   SettingsComponent,
 *   SearchComponent,
 *   ClickOutsideModule,
 *   LayersComponent,
 *   RouterModule,
 *   PushPipe,
 *   SvgIconModule,
 *   ConvertTypePipe,
 *   FeatureFlagDirective,
 *   MatTooltipModule,
 * ]
 * @templateUrl ./toolbar.component.html
 * @styleUrls ['./toolbar.component.scss']
 * @changeDetection OnPush
 */
@Component({
  selector: 'app-toolbar',
  standalone: true,
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatButtonModule,
    MatCheckboxModule,
    SettingsComponent,
    SearchComponent,
    ClickOutsideModule,
    LayersComponent,
    RouterModule,
    PushPipe,
    SvgIconModule,
    ConvertTypePipe,
    FeatureFlagDirective,
    MatTooltipModule,
    BlurAfterFocusDirective,
  ],
})
export class ToolbarComponent implements OnInit {
  /**
   * Представляет маршруты, доступные для панели инструментов.
   * @type {Array}
   * @property {Array} toolbarRoutes - Массив маршрутов панели инструментов.
   * @property {Array} toolbarRoutes.children - Массив дочерних маршрутов в маршрутах панели инструментов.
   */
  readonly toolbarRoutes = TOOLBAR_ROUTES[0].children ?? [];

  /**
   * Доступный для записи сигнал, представляющий активную панель.
   *
   * @type {WritableSignal<ActivePanel | null>}
   */
  readonly activePanel: WritableSignal<ActivePanel | null> = signal(this.getInitActivePanel());

  /**
   * Переменная ViewChild, ссылающаяся на элемент RouterOutlet.
   *
   * @type {RouterOutlet}
   * @memberof MyComponent
   */
  readonly routerOutlet = viewChild(RouterOutlet);

  /**
   * Представляет текущий выбранный уровень.
   *
   * @type {Level}
   * @name currentLevel
   * @memberOf this
   * @instance
   */
  readonly currentLevel = this.levelsService.currentLevel;

  /**
   * Представляет переменную `data$`.
   *
   * @typedef {Observable<RouterData>} DataObservable
   */
  data$ = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    map(() => this.activatedRoute.snapshot.firstChild?.data as RouterData),
    startWith(this.activatedRoute.snapshot.firstChild?.data as RouterData),
  );

  /**
   * Переменная `destroyRef` используется для добавления экземпляра `DestroyRef` в текущий контекст.
   *
   * @type {DestroyRef}
   * @memberof module:myModule
   */
  readonly #destroyRef = inject(DestroyRef);
  /**
   * Представляет тему подпанели переключения.
   *
   * @class
   * @constructor
   * @generic ActivePanel
   */
  readonly #toggleSubpanel$ = new Subject<ActivePanel>();

  /**
   * Вычисляемая переменная, определяющая, отключена ли функция поиска.
   *
   * @return {boolean} true, если поиск отключен, иначе false.
   */
  isDisabledSearch = this.featureFlagService.isFeatureOn('LEVELS')
    ? computed(() => this.currentLevel()?.name !== MAIN_MAP_LEVEL_NAME)
    : signal(false);

  /**
   * Проверяет активность сервиса PCG.
   *
   * @returns {boolean} True, если сервис PCG активен, в противном случае false.
   */
  readonly isPCGActive = this.pcgService.isGeographicToolPanelActive.asReadonly();

  /**
   * @constructor
   *
   * Создает новый экземпляр конструктора.
   *
   * @param {Document} document - Объект HTML Document.
   * @param {PixelStreamingService} pixelStreamingService - Сервис для обработки потока пикселей.
   * @param {EventService} eventService - Сервис для обработки событий.
   * @param {Router} router - Объект маршрутизатора Angular.
   * @param {ActivatedRoute} activatedRoute - Объект активированного маршрута.
   * @param {PlatformLocation} platformLocation - Объект, определяющий место в зависимости от платформы.
   * @param {ToolbarService} toolbarService - Сервис для обработки действий на панели инструментов.
   * @param {LevelsService} levelsService - Сервис для управления уровнями.
   * @param {BackendEventsService} backendEventsService - Сервис для обработки событий на стороне сервера.
   * @param {ControlModesService} controlModelService - Сервис для управления режимами.
   * @param {FeatureFlagService} featureFlagService - Сервис для управления функциональными флагами.
   * @param {NotificationsService} notificationsService - Сервис для управления уведомлениями.
   * @param {PCGService} pcgService - Сервис для обработки данных PCG.
   */
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private pixelStreamingService: PixelStreamingService,
    private eventService: EventService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private platformLocation: PlatformLocation,
    private toolbarService: ToolbarService,
    private levelsService: LevelsService,
    private backendEventsService: BackendEventsService,
    private controlModelService: ControlModesService,
    private featureFlagService: FeatureFlagService,
    private notificationsService: NotificationsService,
    private pcgService: PCGService,
  ) {}

  /**
   * Инициализирует компонент.
   *
   * @returns {void}
   */
  ngOnInit(): void {
    this.backendEventsService.setBuildingsOption(false).pipe(take(1)).subscribe();
    this.keydownListener();
    this.toolbarService.closeSubpanelsEvent$
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((withoutNavigate) => this.closeSubpanels(withoutNavigate));
    this.animateOpenSubpanel();
    this.levelsService.getLevelsAction();
    this.featureFlagService.isFeatureOn('CONTROL_MODES') && this.controlModelService.getControlMode();
    this.macRightMouseClick();
  }

  /**
   * По клику открывает новую вкладку панели мени или закрывает панель в случае получения события закрытия панели
   *
   * @param {Event} [event] - Событие, которое вызывает переключение панели. По умолчанию не определено.
   * @param {string} [panel] - Название панели, которую нужно переключить. По умолчанию не определено.
   * @returns {void}
   */
  toggleSubpanel(event?: Event, panel?: string): void {
    if (panel === 'pcg') {
      this.togglePCG();
      return;
    }

    if (panel) {
      (event?.target as HTMLButtonElement)?.blur();
      this.#toggleSubpanel$.next(panel as ActivePanel);

      if (!this.activePanel()) {
        this.pixelStreamingService.destroySearchAnchor();
      }
    }
  }

  /**
   * Закрывает все субпанели.
   *
   * @return {void}
   */
  closeSubpanels(withoutNavigate = false): void {
    this.pixelStreamingService.setInputState(true);
    this.activePanel.set(null);
    !withoutNavigate && this.router.navigate([''], { queryParams: deleteObjectProperty(this.activatedRoute.snapshot.queryParams, 'q') });
  }

  /**
   * Срабатывает, когда мышь входит в определенную область.
   *
   * @return {void}
   */
  mouseenter(): void {
    this.pixelStreamingService.setInputState(false);
  }

  /**
   * Метод, срабатывающий при выходе мыши из элемента.
   * Обновляет состояние ввода стримингового сервиса.
   *
   * @return {void}
   */
  mouseleave(): void {
    if (this.document.activeElement?.hasAttribute(INPUT_UE_EVENTS)) {
      return;
    }

    this.pixelStreamingService.setInputState(true);
  }

  /**
   * Отображает уведомление с сообщением об том, что функциональность доступна только в локации "Основная карта".
   * @returns {void}
   */
  nonMainMapSnackBar(): void {
    this.notificationsService.open({ type: NotificationType.INFO, duration: DELAY_TIME, text: InfoConstant.toolbar.nonMainMapSnackBar });
  }

  /**
   * Слушает события клавиатуры и выполняет определенное действие, если нажаты определенные комбинации клавиш.
   *
   * @private
   * @function keydownListener
   * @memberof SomeClass
   * @returns {void}
   */
  private keydownListener(): void {
    this.eventService.keyboardDown$.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe((e) => {
      if (e.code === 'F3' || ((e.ctrlKey || e.metaKey) && e.code === 'KeyF')) {
        e.preventDefault();
        this.toggleSubpanel(undefined, 'search');
      }
    });
  }

  /**
   * Выполнить событие правого клика мыши на macOS.
   *
   * @private
   * @function macRightMouseClick
   * @returns {void}
   */
  private macRightMouseClick(): void {
    if (getPlatform() === 'mac' && !this.activePanel()) {
      merge(this.eventService.rightMouseUp$, this.eventService.rightMouseDown$)
        .pipe(
          mergeMap((event) => {
            const down = event.type === 'mousedown';
            return this.backendEventsService.macRightMouseClick(down);
          }),
          takeUntilDestroyed(this.#destroyRef),
        )
        .subscribe();
    }
  }

  /**
   * Получить начальную активную панель на основе текущего пути.
   *
   * @private
   * @returns {ActivePanel | null} Начальная активная панель или null, если не найдена.
   */
  private getInitActivePanel(): ActivePanel | null {
    const path = this.platformLocation.pathname.replace(/^\//g, '');

    if ((TOOLBAR_ROUTES[0].children ?? []).map((child) => child.path).includes(path)) {
      return path as ActivePanel;
    } else {
      return null;
    }
  }

  /**
   * Анимация открытия панели
   *
   * @private
   * @returns {void}
   */
  private animateOpenSubpanel(): void {
    this.#toggleSubpanel$
      .pipe(
        map((panel) => {
          const activePanel = this.activePanel();

          const newActivePanel = panel !== this.activePanel() ? panel : null;
          panel !== activePanel && this.activePanel.set(null);
          return newActivePanel;
        }),
        filter((panel) => panel !== 'pcg'),
        switchMap((newActivePanel) =>
          this.router
            .navigate(newActivePanel ? [newActivePanel] : [''], { queryParams: this.activatedRoute.snapshot.queryParams })
            .then(() => newActivePanel),
        ),
        delay(isFFCheck() ? DELAY_TIME_FF : 0),
        map((newActivePanel) => {
          this.activePanel() !== newActivePanel && this.activePanel.set(newActivePanel);
          return newActivePanel;
        }),
        takeUntilDestroyed(this.#destroyRef),
      )
      .subscribe((activePanel) => {
        this.pixelStreamingService.setInputState(!activePanel);
      });
  }

  /**
   * Переключение состояния PCG.
   *
   * @private
   * @returns {void}
   */
  private togglePCG(): void {
    this.pcgService.toggleGeographicToolPanel();
    this.backendEventsService
      .toggleGeographicToolPanel(this.pcgService.isGeographicToolPanelActive())
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe();
  }
}
