// https://habr.com/ru/companies/tinkoff/articles/808655/
type Primitive = bigint | boolean | number | string | symbol | null | undefined;

// const onlyA: 'a'[];
// const onlyA = ['a', 'b', 'c'].filter(is('a'));
// const ok$: Observable<State.Ok>;
// const ok$ = state$.pipe(filter(is(State.Ok)));
/**
 * Проверяет, является ли данное значение конкретным типом.
 *
 * @template T - конкретный тип для проверки.
 * @template S - обобщенный тип значения для проверки.
 * @param {T} matcher - значение, используемое для сравнения типов.
 * @param {S} x - значение, которое нужно проверить с конкретным типом.
 * @returns {boolean} - Возвращает true, если значение соответствует конкретному типу, иначе false.
 */
export function is<T extends Primitive & S, S>(matcher: T) {
  return (x: S): x is T => x === (matcher as Primitive);
}

type Guard<P = unknown, T extends P = P> = (x: P) => x is T;

type SomeOf<T extends Guard[]> = T[number] extends Guard<infer P, infer R> ? (x: P) => x is R : never;

// const is2or3: (x: unknown) => x is 2 | 3
// const is2or3 = someOf(is(2), is(3));
/**
 * Возвращает функцию, которая применяет логическую операцию ИЛИ к нескольким охранникам.
 *
 * @template T - Тип охранников.
 * @param {...T} guards - Прикладываемые охранники.
 * @returns {SomeOf<T>} - Функция, выполняющая логическую операцию ИЛИ на охранниках.
 */
export function someOf<T extends Guard[]>(...guards: T): SomeOf<T> {
  return ((x) => guards.some((guard) => guard(x))) as SomeOf<T>;
}

/**
 * Представляет функцию-конструктор.
 *
 * @template T - Тип объекта, создаваемого конструктором.
 * @template P - Типы аргументов, принимаемых конструктором.
 */
interface Constructor<T = unknown, P extends unknown[] = never> {
  new (...args: P): T;
}

// const filtered$: Observable<Event1 | Event2>
// const filtered$ = events$.pipe(
//   filter(someOf(instanceOf(Event1), instanceOf(Event2))),
// );
// const started$: Observable<NavigationStart>
// const started$ = router.events.pipe(filter(instanceOf(NavigationStart)));
/**
 * Определяет, является ли объект экземпляром определённого класса.
 *
 * @template T - Тип класса.
 * @template S - Тип суперкласса.
 * @param {Constructor<T>} clazz - Конструктор класса для проверки.
 * @param {S} x - Проверяемый объект.
 * @returns {boolean} - Возвращает true, если объект является экземпляром указанного класса, false в противном случае.
 */
export function instanceOf<T extends S, S>(clazz: Constructor<T>) {
  return (x: S): x is T => x instanceof clazz;
}
