import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, mergeMap, of, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';

import { is } from '../../../../utils/typeguard.util';
import { FavoritesService } from '../favorites.service';
import { addFavorite, getFavorites, getFavoritesSuccess, removeFavorite, replaceFavorites } from './favourites.actions';

/**
 * Класс FavoritesEffects обрабатывает эффекты, связанные с избранными.
 */
@Injectable()
export class FavoritesEffects {
  /**
   * Возвращает observable, который выдает список избранных карточек зданий, когда действие `getFavorites` отправляется.
   *
   * @returns {Observable<Action>} Observable с действием `getFavoritesSuccess`.
   */
  getFavorites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getFavorites),
      switchMap(() =>
        this.favoritesService
          .getFavorites()
          .pipe(map((buildingCards) => getFavoritesSuccess({ buildingCards: buildingCards.filter((item) => item.buildingId) }))),
      ),
    ),
  );

  /**
   * Представляет переменную addFavorite$.
   *
   * @type {Observable<unknown>}
   */
  addFavorite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addFavorite),
      filter(({ skipRequest }) => !skipRequest),
      mergeMap(({ favorite }) =>
        this.favoritesService.addFavorite(favorite).pipe(
          catchError(() => of(null)),
          filter(is(null)),
          map(() => removeFavorite({ favorite, skipRequest: true })),
        ),
      ),
    ),
  );

  /**
   * Удаляет избранное
   *
   * @function removeFavorite$
   * @type {Effect}
   *
   * @returns {Observable} Результирующий observable
   *
   * @throws {Error} Если в процессе удаления избранного происходит ошибка
   *
   * @example
   * removeFavorite$.subscribe(
   *   () => {
   *     // Обработка успеха
   *   },
   *   (error) => {
   *     // Обработка ошибки
   *   }
   * );
   */
  removeFavorite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeFavorite),
      filter(({ skipRequest }) => !skipRequest),
      mergeMap(({ favorite }) =>
        this.favoritesService.removeFavorite(favorite.buildingId).pipe(
          catchError(() => of(null)),
          filter(is(null)),
          map(() => addFavorite({ favorite, skipRequest: true })),
        ),
      ),
    ),
  );

  /**
   * Создает эффект, который заменяет избранное.
   *
   * @returns {Observable<Action>} Наблюдаемый объект, который испускает действия.
   */
  replaceFavorites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(replaceFavorites),
      filter(({ skipRequest }) => !skipRequest),
      mergeMap(({ prevFavorites, newFavorites }) =>
        this.favoritesService.replaceFavorites(newFavorites).pipe(
          catchError(() => of(null)),
          filter(is(null)),
          map(() => replaceFavorites({ prevFavorites: [], newFavorites: prevFavorites, skipRequest: true })),
        ),
      ),
    ),
  );

  /**
   * Создает экземпляр Конструктора.
   *
   * @param {Actions} actions$ - Сервис действий.
   * @param {FavoritesService} favoritesService - Сервис избранного.
   */
  constructor(
    private actions$: Actions,
    private favoritesService: FavoritesService,
  ) {}
}
