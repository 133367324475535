import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, EventEmitter, Input, Output, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { PushPipe } from '@ngrx/component';
import { take } from 'rxjs';

import { PixelStreamingService } from '../../services/pixelstreaming.service';
import { KeenSliderComponent } from '../keen-slider/keen-slider.component';
import { SLIDES } from './intro.constant';

/**
 * Компонент для вводного раздела приложения.
 *
 * @class
 */
@Component({
  selector: 'app-intro',
  standalone: true,
  imports: [CommonModule, MatButtonModule, KeenSliderComponent, PushPipe],
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntroComponent {
  /**
   * Отображает видимость кнопки "Нажмите, чтобы играть".
   *
   * @type {boolean}
   */
  @Input() clickToPlayVisible = true;
  /**
   * Источник событий для отслеживания изменений видимости функции click-to-play.
   *
   * @type {EventEmitter<boolean>}
   */
  @Output() clickToPlayVisibleChange = new EventEmitter<false>();

  /**
   * Массив, содержащий объекты слайдов.
   *
   * @type {Array<{image: string, opacity: number}>}
   */
  slides = SLIDES;

  /**
   * Представляет статус готовности потока.
   *
   * @returns {Observable<boolean>} - An observable that emits a boolean value indicating whether the stream is ready or not.
   */
  readonly isStreamReady$ = this.pixelStreamingService.isStreamReady$;

  /**
   * Переменная `destroyRef` используется для внедрения экземпляра класса `DestroyRef`.
   * Обычно используется для управления уничтожением ссылок или ресурсов в приложении.
   *
   * @type {DestroyRef}
   */
  readonly #destroyRef = inject(DestroyRef);

  /**
   * Создает экземпляр класса.
   *
   * @param {PixelStreamingService} pixelStreamingService - Экземпляр службы PixelStreamingService.
   */
  constructor(private pixelStreamingService: PixelStreamingService) {}
  /**
   * Запускает процесс потоковой передачи.
   *
   * @returns {void}
   */
  onStartStreaming(): void {
    this.pixelStreamingService.streaming?.play();
    this.pixelStreamingService.setInputState(true);
    this.pixelStreamingService.matchViewportResolution(5000);
    this.clickToPlayVisibleChange.emit(false);
    this.pixelStreamingService.getVersion().pipe(take(1), takeUntilDestroyed(this.#destroyRef)).subscribe();
  }

  /**
   * Обновляет прозрачность каждого слайда в зависимости от прогресса соответствующего события.
   *
   * @param {object[]} event - Массив объектов событий.
   * @param {number} event[].distance - Расстояние, пройденное в событии.
   * @param {number} event[].progress - Прогресс события.
   * @throws {TypeError} - Если параметр event не является массивом.
   * @throws {TypeError} - Если какой-либо элемент в параметре event не является объектом со свойствами distance и progress.
   * @throws {RangeError} - Если параметр event не имеет такой же длины, как массив слайдов.
   * @returns {void}
   */
  moveSlide(event: { distance: number; progress: number }[]): void {
    this.slides = this.slides.map((slide, idx) => ({ ...slide, opacity: event[idx].progress }));
  }
}
