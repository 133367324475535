export const EventTypes = {
  MOUSEMOVE: 'mousemove',
  MOUSEUP: 'mouseup',
  MOUSEDOWN: 'mousedown',
  KEYUP: 'keyup',
  KEYDOWN: 'keydown',
  RESIZE: 'resize',
  TOUCHEND: 'touchend',
  TOUCHMOVE: 'touchmove',
} as const;

export type EventTypes = (typeof EventTypes)[keyof typeof EventTypes];
