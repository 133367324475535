import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, map, take } from 'rxjs';

import { RpcMessagesBackend } from '../../../enums/rpc-messages.enum';
import { Status } from '../../../enums/status.enum';
import { PixelStreamingService } from '../../../services/pixelstreaming.service';
import { GetLayersResult, ToggleLayersParam, ToggleLayersResult } from '../../../types/jsonrpc.interface';
import { Layer, LayerState } from './layers.interface';
import { getLayers, setLayers, toggleHybridEnabled } from './store/layers.actions';
import { LayersState } from './store/layers.interface';
import { selectEnabledLayers, selectHybridEnabled, selectLayers, selectLayersLoadingStatus } from './store/layers.selectors';

/**
 * Представляет собой сервис для управления слоями.
 */
@Injectable({ providedIn: 'root' })
export class LayersService {
  /**
   * Получает выбранные слои из хранилища.
   *
   * @return {Observable<Array<Layer>>} Поток выбранных слоев.
   */
  layers = this.store$.selectSignal(selectLayers);

  /**
   * Представляет переменную enabledLayers.
   *
   * Эта переменная хранит результат селектора `selectEnabledLayers`, вызванного на экземпляре `store$`.
   *
   * @type {Observable<Array<Layer>>}
   */
  enabledLayers = this.store$.selectSignal(selectEnabledLayers);

  /**
   * Представляет статус загрузки слоев.
   *
   * @typedef {Object} LayersLoadingStatus
   * @property {Observable<boolean>} signal - Наблюдаемый объект :  объект, который передает статус загрузки слоев.
   */
  layersLoadingStatus = this.store$.selectSignal(selectLayersLoadingStatus);

  /**
   * Извлекает текущее значение флага hybridEnabled из хранилища.
   *
   * @function
   * @returns {Observable<boolean>} Observable, который передает значение флага hybridEnabled.
   */
  hybridEnabled = this.store$.selectSignal(selectHybridEnabled);

  /**
   * Создает новый экземпляр конструктора.
   *
   * @param {Store<LayersState>} store$ - Хранилище для LayersState.
   * @param {PixelStreamingService} pixelStreamingService - Сервис для пиксельного потока.
   */
  constructor(
    private store$: Store<LayersState>,
    private pixelStreamingService: PixelStreamingService,
  ) {}

  /**
   * Выполняет метод getLayersAction.
   * Этот метод отправляет действие getLayers, если layersLoadingStatus имеет статус UNINITIALIZED.
   *
   * @return {void}
   */
  getLayersAction(): void {
    this.layersLoadingStatus() === Status.UNINITIALIZED && this.store$.dispatch(getLayers());
  }

  /**
   * Переключает предоставленные слои с помощью отправки действия setLayers.
   *
   * @param {Layer[]} layers - Массив слоев для переключения.
   * @return {void}
   */
  toggleLayersAction(layers: Layer[]): void {
    this.store$.dispatch(setLayers({ layers, prevLayers: this.layers() }));
  }

  /**
   * Переключает включенный/выключенный режим гибридного отображения.
   *
   * @returns {void}
   */
  toggleHybridEnabled(): void {
    this.store$.dispatch(toggleHybridEnabled());
  }

  /**
   * Извлекает слои из pixelStreamingService.
   * @returns {Observable<Layer[]>} - Observable, который передает массив объектов Layer.
   */
  getLayers(): Observable<Layer[]> {
    return this.pixelStreamingService
      .sendRequest<{ result: GetLayersResult }>(RpcMessagesBackend.GET_LAYERS)
      .pipe(map((data) => data.result?.layers ?? []));
  }

  /**
   * Переключает слои, указанные в данном массиве.
   *
   * @param {Layer[]} layers - Массив слоев для переключения.
   * @return {Observable<LayerState[]>} - Observable, который передает массив объектов LayerState.
   * @throws {Error} - Вызывает ошибку, если объект результата не содержит { layers: LayerState[] }.
   */
  toggleLayers(layers: Layer[]): Observable<LayerState[]> {
    return this.pixelStreamingService
      .sendRequest<{ params: ToggleLayersParam; result: ToggleLayersResult }>(RpcMessagesBackend.TOGGLE_LAYERS, {
        layers: layers.map(({ guid, isEnabled }) => ({ guid, isEnabled })),
      })
      .pipe(
        map((data) => {
          if (data.result?.layers) {
            return data.result.layers;
          } else {
            throw new Error('UE result toggleLayers error, no { layers: LayerState[] } in result');
          }
        }),
        take(1),
      );
  }
}
