export interface Level {
  name: string; // name is uniq
  displayName: string;
  isEnabled: boolean;
  isCurrent: boolean;
}

export const LevelsNames = {
  bpMetaMoscowMap: 'BP_MetaMoscow_map',
  kolomenskyPark: 'LVL_KolomenskyParkMain_SeparatedLVL_map',
  moskino: 'L_Moskino_SeparatedLVL',
} as const;

export type LevelsNames = (typeof LevelsNames)[keyof typeof LevelsNames];
