import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MAT_SNACK_BAR_DATA, MatSnackBarAction, MatSnackBarActions, MatSnackBarLabel, MatSnackBarRef } from '@angular/material/snack-bar';

import { SvgIconModule } from '../../svg-icon/svg-icon.module';
import { NotificationsIconPipe } from '../notifications-icon.pipe';
import { NotificationData } from '../notifications.type';

/**
 * Компонент для отображения уведомлений.
 *
 * @Component
 * @selector app-notifications
 * @standalone true
 * @imports SvgIconModule, MatSnackBarLabel, MatSnackBarActions, MatSnackBarAction, MatButton
 * @templateUrl ./notifications.component.html
 * @styleUrl ./notifications.component.scss
 * @changeDetection OnPush
 */
@Component({
  selector: 'app-notifications',
  standalone: true,
  imports: [SvgIconModule, MatSnackBarLabel, MatSnackBarActions, MatSnackBarAction, MatButton, NotificationsIconPipe],
  templateUrl: './notifications.component.html',
  styleUrl: './notifications.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsComponent {
  /**
   * Представляет переменную под названием 'data'. Это тип 'NotificationData' и он внедряется с использованием константы 'MAT_SNACK_BAR_DATA'.
   *
   * @type {NotificationData}
   * @constant
   */
  data = inject<NotificationData>(MAT_SNACK_BAR_DATA);

  /**
   * Представляет ссылку на внедренный объект `MatSnackBarRef`.
   *
   * @type {MatSnackBarRef}
   */
  #matSnackBarRef = inject(MatSnackBarRef);

  /**
   * Закрывает снэк-бар.
   *
   * @return {void}
   */
  close(): void {
    this.#matSnackBarRef.dismiss();
  }

  /**
   * Выполняет действие и закрывает ссылку на MatSnackBar при помощи действия.
   *
   * @returns {void}
   */
  doAction(): void {
    this.#matSnackBarRef.dismissWithAction();
  }
}
