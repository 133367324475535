import { Injectable, signal } from '@angular/core';

import { LightType } from './light.enum';

/**
 * Сервис для переключения типа освещения.
 * @class
 * @public
 * @Injectable
 * @property {Signal<LightType>} lightType - Сигнал для хранения текущего типа освещения.
 */
@Injectable({
  providedIn: 'root',
})
export class LightToggleService {
  /**
   * Представляет тип освещения.
   *
   * @typedef {number} LightType
   * @property {number} NO_LIGHT - Отсутствует освещение.
   * @property {number} LOW_LIGHT - Наличие света низкой интенсивности.
   * @property {number} HIGH_LIGHT - Наличие света высокой интенсивности.
   */
  lightType = signal<LightType>(LightType.NO_LIGHT);

  /**
   * Устанавливает тип освещения данного LightType.
   *
   * @param {LightType} lightType - Тип освещения, который необходимо установить.
   * @returns {void}
   */
  setLightType(lightType: LightType): void {
    this.lightType.set(lightType);
  }

  /**
   * Обновляет тип света.
   *
   * @param {LightType} lightType Текущий тип света.
   *
   * @return {void} Ничего не возвращает.
   */
  updateLightType(lightType: LightType): void {
    const updatedLightType =
      lightType === LightType.NO_LIGHT || lightType === LightType.DAY_LIGHT ? LightType.NIGHT_LIGHT : LightType.NO_LIGHT;

    this.lightType.set(updatedLightType);
  }
}
