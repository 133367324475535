import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Config, Flags, PixelStreaming } from '@epicgames-ps/lib-pixelstreamingfrontend-ue5.3';
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  Observer,
  Subject,
  TimeoutError,
  catchError,
  debounceTime,
  delay,
  filter,
  map,
  of,
  retry,
  take,
  timeout,
} from 'rxjs';
import { share } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { ObjectCardService } from '../components/object-card/object-card.service';
import {
  DEBOUNCE_TIME,
  INPUT_UE_EVENTS,
  INTERVAL_TIME,
  RETRY_TIME,
  RpcErrorsCodes,
  SIGNALLING_URL_LOCAL_STORAGE,
  STREAMING_CONNECT_FAIL_TIME,
  STREAMING_RECONNECT_DELAY,
} from '../constants/app.constant';
import ErrorsConstant from '../constants/errors.constant';
import { RpcMessagesBackend, RpcMessagesFrontend } from '../enums/rpc-messages.enum';
import { FeatureFlagService } from '../modules/feature-flag/feature-flag.service';
import { NotificationType } from '../modules/notifications/notifications.enum';
import { NotificationsService } from '../modules/notifications/notifications.service';
import { ShortSearchResult } from '../modules/toolbar/search/search.interface';
import { WINDOW } from '../tokens/window.token';
import {
  ActivateOsmIncidentParams,
  EventData,
  EventDataParams,
  EventDataResult,
  GetUnrealProjectVersionResult,
  PONG,
  SUCCESS,
  TranslateCameraToBuildingParam,
  teleportCameraParam,
} from '../types/jsonrpc.interface';
import { is } from '../utils/typeguard.util';
import { generateGUID } from '../utils/uuid.util';
import { ConfigService } from './config.service';
import { DataUrlQueryParamService } from './data-url-query-param.service';
import { MainHtmlPreloaderService } from './main-html-preloader.service';
import { MatchmakerService } from './matchmaker.service';

/**
 * Класс PixelStreamingService для управления функциональностью PixelStreaming.
 * @class PixelStreamingService
 */
@Injectable({ providedIn: 'root' })
export class PixelStreamingService {
  /**
   * Представляет объект потоковой передачи пиксельных данных.
   *
   * @typedef {Object} PixelStreaming
   * @property {function} startStream - Запускает потоковую передачу пикселей.
   * @property {function} stopStream - Останавливает потоковую передачу пикселей.
   */
  streaming: PixelStreaming | null = null;

  /**
   * Переменная, которая представляет собой BehaviorSubject для отображения прелоадера вручную.
   *
   * @type {BehaviorSubject<boolean>}
   */
  manuallyShowPreloader$ = new BehaviorSubject(false);

  /**
   * Представляет собой переменную, указывающую, воспроизводится ли видео или нет.
   *
   * @name #isVideoPlayed$
   * @type {BehaviorSubject<Boolean>}
   * @description Используется BehaviorSubject для отслеживания текущего состояния переменной. Он содержит текущее значение и передает его подписчикам при произошедших изменениях.
   * @param {Boolean} [initialValue=false] - Начальное значение переменной. По умолчанию равно false.
   * @returns {BehaviorSubject<Boolean>} - Экземпляр BehaviorSubject, представляющий статус воспроизведения видео.
   */
  readonly #isVideoPlayed$ = new BehaviorSubject(false);

  /**
   * Определяет, готов ли видеопоток к воспроизведению.
   *
   * @type {Observable<boolean>}
   */
  readonly isStreamReady$ = this.#isVideoPlayed$.pipe(filter(is(true)));

  /**
   * Переменная для хранения версии приложения.
   * @type {BehaviorSubject<string>}
   */
  readonly appVersion$ = new BehaviorSubject('');

  /**
   * ID таймера для таймера отказа в подключении для потока.
   *
   * @type {ReturnType<typeof setTimeout> | null}
   */
  #streamingConnectFailTimerId: ReturnType<typeof setTimeout> | null = null;

  /**
   * Объект, содержащий события и их обработчики.
   * Ключи объекта - сообщения RPC от фронтенда.
   * Значения объекта - подписчики на соответствующие события.
   * @type {Record<RpcMessagesFrontend, Subject<unknown>>}
   */
  #events = {} as Record<RpcMessagesFrontend, Subject<unknown>>;

  /**
   * Идентификатор интервала для проверки пинга.
   * @type {ReturnType<typeof setInterval>}
   */
  #intervalPingCheckIntervalId?: ReturnType<typeof setInterval>;

  /**
   * Флаг, указывающий, загружается ли страница в первый раз.
   *
   * @type {boolean}
   * @description Переменная 'firstLoad' устанавливается в 'true', когда страница загружается впервые.
   *              Она обычно используется для выполнения определенных действий только при первой загрузке страницы,
   *              таких как инициализация переменных, получение начальных данных или отображение приветственного сообщения.
   *              Эту переменную можно использовать для контроля логики в приложении, в зависимости от того,
   *              загружается ли страница в первый раз или нет.
   */
  #firstLoad = true;

  /**
   * Создает новый экземпляр конструктора.
   *
   * @param {Window} window - Объект window.
   * @param {FeatureFlagService} featureFlagService - Сервис для управления функциональными флагами.
   * @param {ActivatedRoute} activatedRoute - Активированный маршрут.
   * @param {Router} router - Маршрутизатор.
   * @param {MainHtmlPreloaderService} mainHtmlPreloaderService - Сервис предзагрузки основного HTML.
   * @param {ObjectCardService} objectCardService - Сервис для работы с карточками объектов.
   * @param {MatchmakerService} matchmakerService - Сервис мэтчмейкинга.
   * @param {NotificationsService} notificationsService - Сервис уведомлений.
   * @param {DataUrlQueryParamService} dataUrlQueryParamService - Сервис для работы с URL параметрами запроса данных.
   * @param {ConfigService} configService - сервис для управления конфигурацией приложения
   */
  constructor(
    @Inject(WINDOW) private window: Window,
    private featureFlagService: FeatureFlagService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private mainHtmlPreloaderService: MainHtmlPreloaderService,
    private objectCardService: ObjectCardService,
    private matchmakerService: MatchmakerService,
    private notificationsService: NotificationsService,
    private dataUrlQueryParamService: DataUrlQueryParamService,
    private configService: ConfigService,
  ) {}

  get #needActivateOSMMode(): boolean {
    return !!(
      !this.activatedRoute.snapshot.url.length &&
      this.activatedRoute.snapshot.queryParams.mode &&
      this.activatedRoute.snapshot.queryParams.mode === 'osm'
    );
  }

  /**
   * Этот метод проверяет, нужно ли камеру телепортировать к объекту.
   * Он проверяет текущий URL и параметры запроса, чтобы определить, требуется ли телепортация.
   *
   * @returns {boolean} Возвращает true, если камеру нужно телепортировать, false в противном случае.
   */
  get #needTeleportCameraToObject(): boolean {
    return (
      !!(
        !this.activatedRoute.snapshot.url.length &&
        this.activatedRoute.snapshot.queryParams.data &&
        !this.activatedRoute.snapshot.queryParams.data.includes('buildingId')
      ) && this.featureFlagService.isFeatureOn('TELEPORT_CAMERA_TO_OBJECT')
    );
  }

  /**
   * Определяет, нужно ли передвинуть камеру к зданию по его идентификатору.
   *
   * @returns {boolean} Возвращает true, если камеру нужно передвинуть к зданию по его идентификатору, в противном случае - false.
   */
  get #needTeleportCameraToBuilding(): boolean {
    return !!(!this.activatedRoute.snapshot.url.length && this.activatedRoute.snapshot.queryParams.data?.includes('buildingId'));
  }

  /**
   * Определяет, нужно ли производить телепортацию камеры.
   * @returns {boolean} Вернет true, если нужно телепортировать камеру, иначе false.
   */
  get #needTeleportCamera(): boolean {
    return (
      !!(!this.activatedRoute.snapshot.url.length && this.activatedRoute.snapshot.queryParams.coords) &&
      this.featureFlagService.isFeatureOn('TELEPORT_CAMERA')
    );
  }

  /**
   * Инициализирует потоковую передачу видео.
   *
   * @param {HTMLElement} videoContainer - Контейнер, в который будет добавлен элемент видео.
   * @return {void}
   */
  init(videoContainer: HTMLElement): void {
    this.getPixelStreamingConfig()
      .pipe(take(1))
      .subscribe((config) => {
        this.mainHtmlPreloaderService.changeSubTitle2ServerConnection();
        const streaming = new PixelStreaming(config, { videoElementParent: videoContainer });
        this.streaming = streaming;
        this.loadFlags(streaming);
        this.streamingEvents(videoContainer);
      });
  }

  /**
   * Устанавливает флаг 'MatchViewportRes' конфигурации потоковой передачи на true на указанную продолжительность в миллисекундах
   * а затем устанавливает его обратно на false.
   *
   * @param {number} duration - Продолжительность в миллисекундах, в течение которой должен быть включен флаг 'MatchViewportRes'.
   * @return {void}
   */
  matchViewportResolution(duration = 1000): void {
    this.streaming?.config.setFlagEnabled('MatchViewportRes', true);
    setTimeout(() => this.streaming?.config.setFlagEnabled('MatchViewportRes', false), duration);
  }

  /**
   * Устанавливает версию приложения.
   *
   * @param {string} appVersion - Версия приложения.
   * @return {void}
   */
  setAppVersion(appVersion: string): void {
    this.appVersion$.next(appVersion);
  }

  // Отправка запроса с обработкой ответа
  /**
   * Отправляет запрос с необязательными параметрами на сервер и возвращает наблюдаемый поток данных события.
   * @param method - RPC-метод, который будет вызываться.
   * @param params - Необязательные параметры для RPC-метода.
   * @returns Наблюдаемый объект, который излучает объекты EventData в ответ на запрос.
   */
  sendRequest<T extends { params?: EventDataParams; result?: EventDataResult }>(
    method: RpcMessagesBackend,
    params?: EventDataParams,
  ): Observable<EventData<T>> {
    return new Observable((observer: Observer<EventData<T>>) => {
      const guid = generateGUID();

      this.streaming?.emitUIInteraction({ jsonrpc: '2.0', method, params, guid });
      this.streaming?.addResponseEventListener(guid, (data: string) => {
        const eventData = JSON.parse(data);

        if (eventData?.guid === guid) {
          if (eventData.error) {
            if (eventData.error?.code) {
              console.error(RpcErrorsCodes[`${eventData.error?.code as number}`]?.description, { method, params, eventData, guid });
            } else {
              console.error({ method, params, eventData, guid });
            }

            observer.error({ method, params, eventData });
          }

          if (this.featureFlagService.isFeatureOn('RPC_LOGS_ENABLED')) {
            console.info('Бэкенд  метод', { method, params, eventData, guid });
          }

          observer.next(eventData);
          this.streaming?.removeResponseEventListener(guid);
        }
      });
    });
  }

  // Подписка на входящие сообщения
  /**
   * Observable метод для обработки событий потоковой передачи
   *
   * @template T - Обобщенный тип для параметров и результатов данных события
   * @param message - Сообщение для прослушивания
   * @returns - Наблюдаемый объект данных события
   */
  on<T extends { params?: EventDataParams; result?: EventDataResult }>(message: RpcMessagesFrontend): Subject<EventData<T>> {
    if (!this.#events[message]) {
      this.#events[message] = new Subject();

      if (this.streaming) {
        this.streaming?.addResponseEventListener(message, (data) => {
          const eventData = JSON.parse(data);

          if (message === eventData.method) {
            if (this.featureFlagService.isFeatureOn('RPC_LOGS_ENABLED')) {
              console.info('Фронтенд  метод', { eventData });
            }

            this.#events[message]?.next(eventData);
          }
        });
      } else {
        console.error(new Error(`No found PixelStreaming object for message: ${message}`));
      }
    }

    return this.#events[message] as Subject<EventData<T>>;
  }

  /**
   * Получает версию проекта Unreal Engine.
   *
   * @returns {Observable<string>} Возвращает обсервабл, который излучает строку версии проекта Unreal Engine.
   * Если данных не получено, генерируется ошибка.
   */
  getVersion(): Observable<string> {
    return this.sendRequest<{ result?: GetUnrealProjectVersionResult }>(RpcMessagesBackend.GET_UE_VERSION).pipe(
      map((data) => {
        if (data === undefined) {
          throw new Error('No data');
        }

        const version = data.result?.version ?? '';
        this.setAppVersion(version);

        return version;
      }),
      retry({ count: 3, delay: RETRY_TIME }),
      take(1),
    );
  }

  /**
   * Устанавливает состояние ввода для различных типов ввода.
   *
   * @param {boolean} isUEManagesEvents - Состояние, которое нужно установить для типов ввода.
   *
   * @return {void}
   */
  setInputState(isUEManagesEvents: boolean): void {
    if (this.streaming?.config) {
      this.streaming.config.setFlagEnabled('MouseInput', isUEManagesEvents);
      this.streaming.config.setFlagEnabled('KeyboardInput', isUEManagesEvents);
      this.streaming.config.setFlagEnabled('GamepadInput', isUEManagesEvents);
      this.streaming.config.setFlagEnabled('XRControllerInput', isUEManagesEvents);
      this.streaming.config.setFlagEnabled('TouchInput', isUEManagesEvents);
    } else {
      this.featureFlagService.isFeatureOn('SHOW_PING_ERROR') &&
        this.notificationsService.open({
          type: NotificationType.ERROR,
          text: ErrorsConstant.pixelStreaming.setInputState,
          duration: 0,
          isClosable: true,
        });
      console.error('No streaming found');
    }
  }

  /**
   * Запрос на удаление метки объекта поиска
   * @returns {void}
   */
  destroySearchAnchor(): void {
    if (this.featureFlagService.isFeatureOn('TELEPORT_CAMERA_TO_OBJECT')) {
      this.sendRequest(RpcMessagesBackend.DESTROY_SEARCH_ANCHOR).pipe(take(1)).subscribe();
    }
  }

  /**
   * Загрузить флаги для данного объекта PixelStreaming.
   * @param {PixelStreaming} streaming - Объект PixelStreaming.
   * @returns {void} - Этот метод ничего не возвращает.
   */
  private loadFlags(streaming: PixelStreaming): void {
    if (streaming?.config) {
      streaming.config.setFlagEnabled(Flags.HoveringMouseMode, true);
      streaming.config.setFlagEnabled(Flags.BrowserSendOffer, true);
      this.setInputState(true);
    } else {
      console.error('No streaming found in loadFlags');
    }
  }

  /**
   * Отправляет запрос ping на сервер.
   *
   * @param {boolean} hasRetry - Если true, метод повторит запрос в случае его неудачи.
   *
   * @returns {Observable<PONG>} - Observable, который выдаёт ответ PONG при успешной операции.
   * Observable генерирует ошибку, если ответ не является 'pong' или отсутствует.
   */
  private ping(hasRetry = false): Observable<PONG> {
    if (hasRetry) {
      return this.sendRequest<{ result: PONG }>(RpcMessagesBackend.PING).pipe(
        debounceTime(DEBOUNCE_TIME),
        map((data) => {
          if (data === undefined) {
            throw new Error('No data');
          } else {
            if (data.result === 'pong') {
              return 'pong' as const;
            } else {
              this.featureFlagService.isFeatureOn('SHOW_PING_ERROR') && this.openNotification('ping');
              console.error('UE: Wrong ping pong response', data);
              throw new Error('UE: Wrong ping pong response');
            }
          }
        }),
        retry({ delay: RETRY_TIME }),
      );
    } else {
      return this.sendRequest<{ result: PONG }>(RpcMessagesBackend.PING).pipe(
        map((data) => {
          if (data === undefined) {
            throw new Error('No data');
          } else {
            if (data.result === 'pong') {
              return 'pong' as const;
            } else {
              this.featureFlagService.isFeatureOn('SHOW_PING_ERROR') && this.openNotification('ping');
              console.error('UE: Wrong ping pong response', data);
              throw new Error('UE: Wrong non pong response');
            }
          }
        }),
      );
    }
  }

  /**
   * Выполняет периодическую проверку ping.
   *
   * @private
   * @returns {void}
   */
  private intervalPingCheck(): void {
    this.#intervalPingCheckIntervalId && clearInterval(this.#intervalPingCheckIntervalId);
    this.#intervalPingCheckIntervalId = setInterval(() => {
      this.ping()
        .pipe(
          take(1),
          timeout(INTERVAL_TIME / 2),
          catchError((err) => {
            if (err instanceof TimeoutError && this.featureFlagService.isFeatureOn('SHOW_PING_ERROR')) {
              this.setInputStateAfterReconnect();
              this.openNotification('ping');
            }

            return EMPTY;
          }),
        )
        .subscribe(() => {
          this.featureFlagService.isFeatureOn('SHOW_PING_ERROR') && this.notificationsService.dismiss();
        });
    }, INTERVAL_TIME);
  }

  /**
   * Устанавливает состояние ввода после повторного подключения.
   * Если функция RECONNECT включена и активный элемент документа не имеет атрибута INPUT_UE_EVENTS,
   * то устанавливается состояние ввода в true.
   *
   * @returns {void}
   */
  private setInputStateAfterReconnect(): void {
    if (this.featureFlagService.isFeatureOn('RECONNECT') && !this.window.document.activeElement?.hasAttribute(INPUT_UE_EVENTS)) {
      this.setInputState(true);
    }
  }

  /**
   * Прикрепляет обработчики событий для различных событий потоковой передачи и протоколирует эти события.
   *
   * @param {HTMLElement} videoContainer - Элемент контейнера видео.
   * @returns {void}
   */
  private streamingEvents(videoContainer: HTMLElement): void {
    if (this.streaming && videoContainer) {
      if (this.featureFlagService.isFeatureOn('SHOW_PIXEL_STREAMING_LOGS')) {
        this.streaming.addEventListener('playerCount', (event) => {
          console.info('[PIXEL_STREAMING] playerCount', event);
          this.mainHtmlPreloaderService.showConnectionLogs('playerCount');
        });

        this.streaming.addEventListener('webRtcConnecting', (event) => {
          this.mainHtmlPreloaderService.showConnectionLogs('webRtcConnecting');

          this.#streamingConnectFailTimerId = setTimeout(() => {
            this.featureFlagService.isFeatureOn('SHOW_PING_ERROR') &&
              this.notificationsService
                .open({
                  type: NotificationType.ERROR,
                  text: ErrorsConstant.pixelStreaming.streamingEvents,
                  duration: 0,
                  isClosable: true,
                })
                .onAction()
                .pipe(take(1))
                .subscribe(() => this.window.location.reload());
            console.error(`webRtcConnecting timeout ${STREAMING_CONNECT_FAIL_TIME / 1000}сек`, 'Проблемы с соединением к UE');
          }, STREAMING_CONNECT_FAIL_TIME);
          console.info('[PIXEL_STREAMING] webRtcConnecting', event);
        });

        this.streaming.addEventListener('webRtcSdp', (event) => {
          this.#streamingConnectFailTimerId && clearTimeout(this.#streamingConnectFailTimerId);
          console.info('[PIXEL_STREAMING] webRtcSdp', event);
          this.mainHtmlPreloaderService.showConnectionLogs('webRtcSdp');
          this.startReconnectTimer();
        });

        this.streaming.addEventListener('webRtcConnected', (event) => {
          console.info('[PIXEL_STREAMING] webRtcConnected', event);
          this.mainHtmlPreloaderService.showConnectionLogs('webRtcConnected');
        });

        this.streaming.addEventListener('dataChannelOpen', (event) => {
          console.info('[PIXEL_STREAMING] dataChannelOpen', event);
          this.mainHtmlPreloaderService.showConnectionLogs('dataChannelOpen');
        });

        this.streaming.addEventListener('initialSettings', (event) => {
          console.info('[PIXEL_STREAMING] initialSettings', event);
          this.mainHtmlPreloaderService.showConnectionLogs('initialSettings');
        });

        this.videoInitializedEvent(videoContainer);

        this.streaming.addEventListener('playStream', (event) => {
          console.info('[PIXEL_STREAMING] playStream', event);
          this.mainHtmlPreloaderService.showConnectionLogs('playStream');
        });

        this.streaming.addEventListener('afkWarningActivate', (event) => console.info('[PIXEL_STREAMING] afkWarningActivate', event));
        this.streaming.addEventListener('afkWarningUpdate', (event) => console.info('[PIXEL_STREAMING] afkWarningUpdate', event));
        this.streaming.addEventListener('afkWarningDeactivate', (event) => console.info('[PIXEL_STREAMING] afkWarningDeactivate', event));
        this.streaming.addEventListener('afkTimedOut', (event) => console.info('[PIXEL_STREAMING] afkTimedOut', event));
        !environment.disableShowPSExtendedLogs &&
          this.featureFlagService.isFeatureOn('SHOW_PIXEL_STREAMING_EXTENDED_LOGS') &&
          this.streaming.addEventListener('videoEncoderAvgQP', (event) => console.info('[PIXEL_STREAMING] videoEncoderAvgQP', event));
        this.streaming.addEventListener('webRtcAutoConnect', (event) => console.info('[PIXEL_STREAMING] webRtcAutoConnect', event));
        this.streaming.addEventListener('webRtcFailed', (event) => console.info('[PIXEL_STREAMING] webRtcFailed', event));
        this.streaming.addEventListener('dataChannelClose', (event) => console.info('[PIXEL_STREAMING] dataChannelClose', event));
        this.streaming.addEventListener('dataChannelError', (event) => console.info('[PIXEL_STREAMING] dataChannelError', event));
        this.streaming.addEventListener('streamLoading', (event) => console.info('[PIXEL_STREAMING] streamLoading', event));
        this.streaming.addEventListener('streamConnect', (event) => console.info('[PIXEL_STREAMING] streamConnect', event));
        this.streaming.addEventListener('streamDisconnect', (event) => console.info('[PIXEL_STREAMING] streamDisconnect', event));
        this.streaming.addEventListener('webRtcDisconnected', (event) => {
          console.info('[PIXEL_STREAMING] webRtcDisconnected', event);
          if (this.featureFlagService.isFeatureOn('RECONNECT')) {
            this.setStatusManuallyPreloader('show');
            this.setInputStateAfterReconnect();
          }
        });
        this.streaming.addEventListener('streamReconnect', (event) => console.info('[PIXEL_STREAMING] streamReconnect', event));
        this.streaming.addEventListener('playStreamError', (event) => console.info('[PIXEL_STREAMING] playStreamError', event));
        this.streaming.addEventListener('playStreamRejected', (event) => console.info('[PIXEL_STREAMING] playStreamRejected', event));
        this.streaming.addEventListener('loadFreezeFrame', (event) => console.info('[PIXEL_STREAMING] loadFreezeFrame', event));
        this.streaming.addEventListener('hideFreezeFrame', (event) => console.info('[PIXEL_STREAMING] hideFreezeFrame', event));
        !environment.disableShowPSExtendedLogs &&
          this.featureFlagService.isFeatureOn('SHOW_PIXEL_STREAMING_EXTENDED_LOGS') &&
          this.streaming.addEventListener('statsReceived', (event) => console.info('[PIXEL_STREAMING] statsReceived', event));
        this.streaming.addEventListener('streamerListMessage', (event) => {
          console.info('[PIXEL_STREAMING] streamerListMessage', event);

          if ((event.data.messageStreamerList?.ids || []).length === 0) {
            console.error('UE server error, no stream message ids, but in most of cases must be ids:["DefaultStreamer"]');
          }
        });
        this.streaming.addEventListener('latencyTestResult', (event) => console.info('[PIXEL_STREAMING] latencyTestResult', event));
        this.streaming.addEventListener('dataChannelLatencyTestResponse', (event) =>
          console.info('[PIXEL_STREAMING] dataChannelLatencyTestResponse', event),
        );
        this.streaming.addEventListener('dataChannelLatencyTestResult', (event) =>
          console.info('[PIXEL_STREAMING] dataChannelLatencyTestResult', event),
        );
        !environment.disableShowPSExtendedLogs &&
          this.featureFlagService.isFeatureOn('SHOW_PIXEL_STREAMING_EXTENDED_LOGS') &&
          this.streaming.addEventListener('settingsChanged', (event) => console.info('[PIXEL_STREAMING] settingsChanged', event));
        this.streaming.addEventListener('xrSessionStarted', (event) => console.info('[PIXEL_STREAMING] xrSessionStarted', event));
        this.streaming.addEventListener('xrSessionEnded', (event) => console.info('[PIXEL_STREAMING] xrSessionEnded', event));
      }
    }
  }

  /**
   * Обрабатывает событие 'videoInitialized'.
   * @param {HTMLElement} videoContainer - Элемент-контейнер, содержащий видео.
   * @returns {void}
   */
  private videoInitializedEvent(videoContainer: HTMLElement): void {
    if (this.streaming) {
      this.streaming.addEventListener('videoInitialized', (event) => {
        this.featureFlagService.isFeatureOn('SHOW_PIXEL_STREAMING_LOGS') && console.info('[PIXEL_STREAMING] videoInitialized', event);
        this.mainHtmlPreloaderService.showConnectionLogs('videoInitialized');
        const video = videoContainer.querySelector('video');

        if (video) {
          video.muted = true;
          video.autoplay = true;
          const playPromise = video?.play();

          if (playPromise !== undefined) {
            playPromise
              .then(() => {
                this.featureFlagService.isFeatureOn('SHOW_PIXEL_STREAMING_LOGS') && console.info('[PIXEL_STREAMING] PlayPromise success');
                this.mainHtmlPreloaderService.showConnectionLogs('PlayPromiseSuccess');

                if (this.featureFlagService.isFeatureOn('UE_PING') && !environment.disableUEPing) {
                  this.ping(true)
                    .pipe(take(1))
                    .subscribe(() => {
                      this.afterReady();
                      this.intervalPingCheck();
                    });
                } else {
                  this.afterReady();
                }
              })
              .catch((error) => console.error('playPromise error', error));
          } else {
            console.error('Not found video playPromise');
          }
        } else {
          console.error('Not found video tag');
        }
      });
    }
  }

  /**
   * Скрывает предзагрузчик.
   *
   * @private
   * @returns {void}
   */
  private hidePreloader(firstLoad = false): void {
    if (!this.#isVideoPlayed$.value || firstLoad) {
      this.matchViewportResolution();
      this.featureFlagService.isFeatureOn('SHOW_PIXEL_STREAMING_LOGS') && console.info('[PIXEL_STREAMING] Hide application preloader');
      this.#isVideoPlayed$.next(true);
      this.mainHtmlPreloaderService.hidePreloader();
      this.setInputState(false);
      this.#firstLoad = false;
    }

    // the connection is broken - new reconnect
    this.setStatusManuallyPreloader('hide');
  }

  /**
   * Устанавливает статус прелоадера вручную.
   * @param {('show' | 'hide')} value - Значение для установки статуса прелоадера ('show' - показать, 'hide' - скрыть).
   * @returns {void}
   */
  private setStatusManuallyPreloader(value: 'hide' | 'show'): void {
    this.manuallyShowPreloader$.next(value === 'show');
  }

  /**
   * Переподключает потоковую передачу.
   *
   * @return {void}
   */
  private startReconnectTimer(): void {
    of(true)
      .pipe(
        delay(STREAMING_RECONNECT_DELAY),
        take(1),
        filter(() => !this.#isVideoPlayed$.value),
      )
      .subscribe(() => {
        console.info('Reconnect', this.streaming);
        this.streaming?.reconnect();
      });
  }

  /**
   * Переносит камеру к указанному объекту.
   *
   * @private
   * @return {void}
   */
  private teleportCameraToObject(): void {
    try {
      const { ...place } = this.dataUrlQueryParamService.decodeUrl(this.activatedRoute.snapshot.queryParams.data);
      this.sendRequest<{ params: ShortSearchResult }>(RpcMessagesBackend.TELEPORT_CAMERA_TO_OBJECT, place)
        .pipe(take(1))
        .subscribe({
          next: () => {
            this.hidePreloader();
            this.objectCardService.setData(this.objectCardService.docToObjectInfo({ ...place }));
          },
          error: () => this.hidePreloaderAfterClearUrl(),
        });
    } catch (error) {
      console.error('teleportCameraToObject decodeHash error', error);
      this.hidePreloaderAfterClearUrl();
    }
  }

  /**
   * Переносит камеру к указанному объекту.
   *
   * @private
   * @return {void}
   */
  private teleportCameraToBuilding(): void {
    try {
      const { buildingId } = this.dataUrlQueryParamService.decodeUrl(this.activatedRoute.snapshot.queryParams.data);

      if (buildingId) {
        this.#isVideoPlayed$.next(true);
        this.sendRequest<{ params: TranslateCameraToBuildingParam }>(RpcMessagesBackend.TELEPORT_CAMERA_TO_BUILDING, { buildingId })
          .pipe(take(1))
          .subscribe({
            next: () => {
              this.hidePreloader(this.#firstLoad);
            },
            error: () => {
              this.hidePreloaderAfterClearUrl(this.#firstLoad);
              this.#isVideoPlayed$.next(false);
            },
          });
      }
    } catch (error) {
      console.error('translateCameraToBuildingById decodeHash error', error);
      this.hidePreloaderAfterClearUrl();
    }
  }

  /**
   * Проверяет координаты для телепортации камеры.
   *
   * @param {string} coords - Координаты в формате строки.
   * @returns {boolean} - Возвращает true если координаты верные, false в противном случае.
   * @throws Генерирует ошибку, если данные в URL некорректны или если координаты в URL некорректны.
   */
  private validateTeleportCamera(coords: string): boolean {
    const data = coords.split(',');

    if (data.length !== 5) {
      throw new Error('Wrong data in url: ' + data);
    }

    if (data.length !== 5 || !data.every((item) => /^[+-]?\d+(\.\d+)?$/.test(item))) {
      throw new Error('Wrong coords in url: ' + data);
    }

    return true;
  }

  /**
   * Телепортирует камеру к указанным координатам и вращению.
   */
  private teleportCamera(): void {
    try {
      const { coords } = this.activatedRoute.snapshot.queryParams;

      if (this.validateTeleportCamera(coords)) {
        const [x, y, z, yaw, pitch] = coords.split(',');

        this.sendRequest<{ params: teleportCameraParam; result: SUCCESS }>(RpcMessagesBackend.TELEPORT_CAMERA, {
          coordinates: [x, y, z],
          rotation: [yaw, pitch],
        })
          .pipe(
            map((data) => {
              if (data.result && 'success' in data.result) {
                return data.result;
              } else {
                throw new Error('UE result teleportCamera error, no success property in response');
              }
            }),
            take(1),
          )
          .subscribe({
            next: () => this.hidePreloader(),
            error: () => this.hidePreloaderAfterClearUrl(),
          });
      } else {
        throw new Error('Wrong data in url: ' + this.activatedRoute.snapshot.queryParams.coords);
      }
    } catch (error) {
      console.error('teleportCamera error', error);
      this.hidePreloaderAfterClearUrl();
    }
  }

  /**
   * Выполняет действия после того, как сцена готова.
   * Если флаг 'needTeleportCameraToObject' истинен, он телепортирует камеру к объекту.
   * В противном случае, он скрывает предзагрузчик.
   *
   * @returns {void}
   */
  private afterReady(): void {
    this.updateSignallingUrl(this.streaming?.config.getTextSettingValue('ss'));

    if (this.#needActivateOSMMode) {
      this.activateOSMMode();
    } else if (this.#needTeleportCameraToObject) {
      this.teleportCameraToObject();
    } else if (this.#needTeleportCameraToBuilding) {
      this.teleportCameraToBuilding();
    } else if (this.#needTeleportCamera) {
      this.teleportCamera();
      this.destroySearchAnchor();
    } else {
      this.destroySearchAnchor();
      this.hidePreloader();
    }
  }

  /**
   * Скрывает прелоадер после очистки URL.
   *
   * @returns {void}
   */
  private hidePreloaderAfterClearUrl(firstLoad = false): void {
    this.router.navigate(['']).then(() => this.hidePreloader(firstLoad));
  }

  /**
   * Получает конфигурацию Pixel Streaming.
   *
   * @returns {Observable<Config>} Observable, который выводит конфигурацию Pixel Streaming.
   */
  private getPixelStreamingConfig(): Observable<Config> {
    return (
      this.featureFlagService.isFeatureOn('MATCH_MAKER')
        ? this.matchmakerService.getSignalingServerUrl()
        : of(this.configService.getValue('SIGNALING_URL'))
    ).pipe(
      take(1),
      filter((signallingServer) => !!signallingServer),
      map((ss) => {
        return new Config({
          initialSettings: {
            ss,
            AutoPlayVideo: true,
            AutoConnect: true,
            StartVideoMuted: true,
            HoveringMouse: true,
            MatchViewportRes: false,
            UseMic: false,
          },
          useUrlParams: true,
        });
      }),
    );
  }

  /**
   * Открывает уведомление определенного типа.
   * @param {string} type - Тип уведомления ('intervalPingCheck' или 'ping').
   * @returns {void}
   */
  private openNotification(type: 'intervalPingCheck' | 'ping'): void {
    if (!this.notificationsService.openedNotification) {
      this.notificationsService
        .open({
          type: NotificationType.ERROR,
          text: type === 'ping' ? ErrorsConstant.pixelStreaming.ping : ErrorsConstant.pixelStreaming.intervalPingCheck,
          duration: 0,
          isClosable: true,
          action: $localize`:@@streaming.ping.timeout-error:Обновить страницу`,
        })
        .onAction()
        .pipe(take(1))
        .subscribe(() => this.window.location.reload());
    }
  }

  /**
   * Сбрасывает контролы.
   *
   * @return {Observable<boolean>} Наблюдаемый поток, который испускает булево значение, указывающее, были ли контролы успешно сброшены или нет.
   */
  private resetControls(): Observable<boolean> {
    return this.sendRequest(RpcMessagesBackend.RESET_CONTROLS).pipe(
      map(() => true),
      share(),
      take(1),
    );
  }

  private activateOSMMode(): void {
    this.sendRequest<{ params: ActivateOsmIncidentParams }>(RpcMessagesBackend.ACTIVATE_OSM_INCIDENT, {
      type: 'incident',
      id: '0',
    }).subscribe({
      next: () => {
        this.hidePreloader();
      },
      error: () => this.hidePreloaderAfterClearUrl(),
    });
  }

  /**
   * Обновляет URL сигнального сервера и сбрасывает элементы управления.
   *
   * @param {string|undefined} ss - Новый URL сигнализации.
   * @returns {void}
   */
  private updateSignallingUrl(ss: string | undefined): void {
    const ssFromStorage = this.window.localStorage.getItem(SIGNALLING_URL_LOCAL_STORAGE);
    if (ss && ssFromStorage !== ss) {
      this.window.localStorage.setItem(SIGNALLING_URL_LOCAL_STORAGE, ss);
      this.resetControls().subscribe((reset) => {
        reset && console.info('controls have been reset');
      });
    }
  }
}
