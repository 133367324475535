import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { FeatureFlagService } from '../modules/feature-flag/feature-flag.service';

/**
 * Проверяет, включен ли данный ключ функциональности и защищает навигацию по маршруту.
 *
 * @param {Object} route - Объект маршрута, содержащий данные маршрута.
 * @param {string} route.data.featureFlagKey - Ключ функционального флага для проверки.
 *
 * @return {Promise<boolean> | boolean} - Промис, который разрешается в true, если флаг функциональности включен, в противном случае false. Альтернативно, он возвращает boolean напрямую.
 */
export const featureFlagGuard: CanActivateFn = (route): Promise<boolean> | boolean => {
  const featureFlagService = inject(FeatureFlagService);
  const router = inject(Router);

  return featureFlagService.isFeatureOn(route.data.featureFlagKey) ? true : router.navigate(['/']);
};
