import { Clipboard } from '@angular/cdk/clipboard';
import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  HostBinding,
  Inject,
  OnInit,
  computed,
  effect,
  inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Observable, filter, map, take } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import { slideUpDownAnimation } from '../../animations';
import { ANIMATION_TIME, DELAY_TIME } from '../../constants/app.constant';
import { RpcMessagesBackend } from '../../enums/rpc-messages.enum';
import { Status } from '../../enums/status.enum';
import { FeatureFlagService } from '../../modules/feature-flag/feature-flag.service';
import { OsmCardService } from '../../modules/osm/osm-card.service';
import { OsmIframeService } from '../../modules/osm/osm-iframe.service';
import {
  OSMIncidentGroupKeysOrder,
  OSMIncidentGroupedProperties,
  OSMIncidentProperty,
  OSMIncidentPropertyGroup,
  OSMObjectGroupKeysOrder,
  OSMObjectGroupedProperties,
  OSMObjectProperty,
  OSMObjectPropertyGroup,
} from '../../modules/osm/osm.interface';
import { BuildingCard } from '../../modules/toolbar/favorites/favorites.interface';
import { FavoritesService } from '../../modules/toolbar/favorites/favorites.service';
import { BackendEventsService } from '../../services/backend-events.service';
import { ConfigService } from '../../services/config.service';
import { FrontendEventsService } from '../../services/frontend-events.service';
import { PixelStreamingService } from '../../services/pixelstreaming.service';
import { GetCurrentBuildingOptionsResult, SUCCESS } from '../../types/jsonrpc.interface';
import { scrollTo } from '../../utils/scroll-to.util';
import { is } from '../../utils/typeguard.util';
import { ControlMode } from '../control-modes/control-modes.interface';
import { OBJECT_CARD_TITLE } from './object-card.enum';
import { GroupedProperties, MenuGroup, MenuItem, Property, PropertyGroup, PropertyGroupKeysOrder, View } from './object-card.interface';
import { ObjectCardService } from './object-card.service';

/**
 * Представляет класс ObjectCardComponent, который отвечает за отображение карточки объекта.
 * @component
 */
@Component({
  selector: 'app-object-card',
  templateUrl: './object-card.component.html',
  styleUrls: ['./object-card.component.scss'],
  animations: [slideUpDownAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ObjectCardComponent implements OnInit {
  selectedTabIndex = this.osmCardService.osmAdditionData()?.activeTabIdx;

  readonly tabs = [
    { label: $localize`:@@object-card.header.osm.tab-accident:Инцидент` },
    { label: $localize`:@@object-card.header.osm.tab-object:Объект` },
  ];

  /**
   * Хранит дополнительные данные, полученные от `objectCardService`.
   *
   * @type {Object}
   */
  readonly additionData = this.objectCardService.additionData;

  readonly osmAdditionData = this.osmCardService.osmAdditionData;

  /**
   * Выполняет дополнительный эффект данных.
   * Выполняет функцию additionData и устанавливает значение свойства isCurrentBuildingOption2 на основе результата функции getCurrentBuildingOption.
   */
  additionDataEffect = effect(() => {
    this.additionData();

    this.getCurrentBuildingOption()
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((isSecondOn) => {
        this.isCurrentBuildingOption2.set(isSecondOn);
      });
  });

  /**
   * Возвращает свойства карточки объекта.
   *
   * @returns {Object} Объект, содержащий свойства карточки объекта.
   */
  readonly groupedProperties = this.objectCardService.groupedProperties;

  readonly osmObjectGroupedProperties = this.osmCardService.osmObjectGroupedProperties;
  readonly osmIncidentGroupedProperties = this.osmCardService.osmIncidentGroupedProperties;

  readonly isOSMCardActive = computed(() => {
    return this.osmObjectGroupedProperties() && this.osmIncidentGroupedProperties();
  });

  /**
   * Вычисляемое свойство, возвращающее ID здания.
   *
   * @function buildingId
   * @returns {string|null} ID здания, или null.
   *
   * @example
   * // Пример использования:
   * const id = buildingId();
   */
  readonly buildingId = this.featureFlagService.isFeatureOn('ACT_AGR_ID')
    ? computed(() => (this.groupedProperties() ? (this.objectCardService.getPropertyValueByCode('act_AGR') as string) : ''))
    : computed(() => this.additionData().buildingId);

  /**
   * Переменная, содержащая значение ошибки загрузки изображения.
   * @type {boolean}
   */
  readonly imageState = this.objectCardService.imageState;

  /**
   * Возвращает URL изображения, вычисленный на основе текущего состояния.
   * Если произошла ошибка загрузки изображения, возвращает пустую строку.
   * Если идентификатор здания доступен, возвращает URL изображения для этого здания.
   * @returns {string} URL изображения или пустая строка
   */
  readonly imageUrl = computed(() => {
    if (this.imageState().error) {
      return '';
    }

    return this.buildingId() ? `${this.configService.getValue('WEB_ADMIN_URL')}/images/${this.buildingId()}` : '';
  });

  readonly photos = this.osmCardService.photos;

  /**
   * Представляет заголовок карточки объекта.
   *
   * @constant {string}
   */
  readonly objectCardTitle = OBJECT_CARD_TITLE;

  /**
   * Получает значение свойства по данному коду.
   *
   * @param {keyof ObjectInfo['features']['properties']} code - Код свойства.
   * @return {string} - Значение свойства. Возвращает пустую строку, если #objectInfoFeaturesProperties равен null.
   */
  readonly getFavoriteByBuildingId = this.favoritesService.getFavoriteByBuildingId;

  /**
   * Представляет порядок ключей группы свойств.
   *
   * @typedef {Array<string>} PropertyGroupKeysOrder
   * @description Массив строк, представляющий порядок, в котором следует отображать
   * или обрабатывать ключи группы свойств.
   */
  propertyGroupKeysOrder = PropertyGroupKeysOrder;

  readonly OSMIncidentGroupKeysOrder = OSMIncidentGroupKeysOrder;
  readonly OSMObjectGroupKeysOrder = OSMObjectGroupKeysOrder;

  /**
   * Представляет группу свойств.
   *
   * @class
   * @constructor
   */
  propertyGroup = PropertyGroup;

  readonly OSMIncidentPropertyGroup = OSMIncidentPropertyGroup;
  readonly OSMObjectPropertyGroup = OSMObjectPropertyGroup;

  /**
   * Представляет булевую переменную, указывающую, включены ли действия или нет.
   *
   * @type {boolean}
   */
  isActionsEnabled = this.getIsActionsEnabled();

  /**
   * Внедренный экземпляр DestroyRef.
   *
   * @type {DestroyRef}
   */
  readonly #destroyRef = inject(DestroyRef);

  /**
   * Представляет собой сигнал, который излучает массив групп меню для меню карты объекта.
   *
   * @typedef {Array<MenuGroup>} objectCardMenu
   *
   * @property {string} uuid - Уникальный идентификатор группы меню.
   * @property {string} title - Заголовок группы меню.
   * @property {Array<MenuItem>} items - Массив пунктов меню в группе меню.
   * @property {boolean} isActive - Указывает, активен ли в настоящее время пункт меню.
   * @property {View} type - Тип пункта меню.
   *
   * @typedef {Object} MenuGroup
   * @property {string} uuid - Уникальный идентификатор группы меню.
   * @property {string} title - Заголовок группы меню.
   * @property {Array<MenuItem>} items - Массив пунктов меню в группе меню.
   *
   * @typedef {Object} MenuItem
   * @property {string} uuid - Уникальный идентификатор пункта меню.
   * @property {string} text - Текст пункта меню.
   * @property {boolean} isActive - Указывает, активен ли в настоящее время пункт меню.
   * @property {View} type - Тип пункта меню.
   *
   * @constant {objectCardMenu} objectCardMenu - Сигнал, который излучает массив групп меню для меню карты объекта.
   *
   * @example
   * objectCardMenu.subscribe((menuGroups) => {
   *   // обработка излученного массива групп меню
   * });
   */
  readonly objectCardMenu = signal<MenuGroup[]>([
    {
      uuid: uuidv4(),
      title: $localize`:@@object-card.menu.view.title.:Вид:`,
      items: [
        { uuid: uuidv4(), text: $localize`:@@object-card.menu.item.minimum:Минимальный`, isActive: false, type: View.MINIMUM },
        { uuid: uuidv4(), text: $localize`:@@object-card.menu.item.standard:Стандартный`, isActive: false, type: View.STANDARD },
        { uuid: uuidv4(), text: $localize`:@@object-card.menu.item.revealed:Раскрытый`, isActive: false, type: View.REVEALED },
      ].map((item) => ({ ...item, isActive: item.type === this.objectCardService.objectCardView })),
    },
  ]);

  /**
   * Представляет статус определенного объекта.
   *
   * @typedef {string} Status
   */
  readonly status = Status;
  /**
   * Представляет статус загрузки службы избранного.
   *
   * @type {FavoriteServiceLoadingStatus}
   * @name favoritesLoadingStatus
   * @memberOf Namespace
   */
  readonly favoritesLoadingStatus = this.favoritesService.loadingStatus;

  /**
   * Представляет статус текущей опции отображения здания.
   *
   * @typedef {Signal<boolean>} isCurrentBuildingOption2
   */
  readonly isCurrentBuildingOption2 = signal(true);

  /**
   * Указывает значение правого отступа, когда включен определенный флаг функции.
   * Значение рассчитывается на основе состояния флага функции и константы.
   *
   * @type {number}
   * @readonly
   * @see featureFlagService
   * @see RIGHT_MARGIN_WHEN_LIGHT_TOGGLE_ON
   */
  @HostBinding('class.move-right')
  rightMargin = this.featureFlagService.isFeatureOn('LIGHT_TOGGLE');

  /**
   * Проверяет, включен ли флаг объекта изображения карточки.
   *
   * @returns {boolean} Возвращает true, если флаг включен, иначе false.
   */
  objectCardImageEnabled = this.featureFlagService.isFeatureOn('OBJECT_CARD_IMAGE_ENABLED');

  /**
   * Конструктор класса.
   *
   * @param {Document} document - Объект Document используется для доступа к DOM.
   * @param {Clipboard} clipboard - Сервис clipboard.
   * @param {MatSnackBar} matSnackBar - Сервис snackbar от Material.
   * @param {ObjectCardService} objectCardService - Сервис для работы с карточками объектов.
   * @param {PixelStreamingService} pixelStreamingService - Сервис для работы с потоковой передачей пикселей.
   * @param {ActivatedRoute} activatedRoute - Сервис активированного маршрута.
   * @param {FavoritesService} favoritesService - Сервис для работы с избранным.
   * @param {FeatureFlagService} featureFlagService - Сервис для работы с функциями.
   * @param {BackendEventsService} backendEventsService - Сервис для работы с событиями бэкенда.
   * @param {FrontendEventsService} frontendEventsService - Экземпляр FrontendEventsService для использования в конструкторе.
   * @param {BackendEventsService} backendEventsService - Экземпляр BackendEventsService для использования в конструкторе.
   * @param {ConfigService} configService - сервис для управления конфигурацией приложения
   *
   * @return {void}
   */
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private clipboard: Clipboard,
    private matSnackBar: MatSnackBar,
    private objectCardService: ObjectCardService,
    private pixelStreamingService: PixelStreamingService,
    private activatedRoute: ActivatedRoute,
    private favoritesService: FavoritesService,
    private featureFlagService: FeatureFlagService,
    private backendEventsService: BackendEventsService,
    private frontendEventsService: FrontendEventsService,
    private osmCardService: OsmCardService,
    private osmIframeService: OsmIframeService,
    private configService: ConfigService,
  ) {}

  /**
   * Инициализирует компонент при его первом создании.
   */
  ngOnInit(): void {
    this.favoritesService.getFavoritesAction();
    this.frontendEventsService
      .updateControlModeEvent()
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((controlMode: ControlMode) => {
        this.objectCardService.closeObjectCardWhenAvatarMode(controlMode);
      });
  }

  /**
   * Закрывает текущую сессию и очищает данные в сервисе карточек объектов.
   *
   * @returns {void} Этот метод не возвращает никакого значения.
   */
  async close(): Promise<void> {
    if (this.isOSMCardActive()) {
      this.backendEventsService.onIncidentCardClosed().pipe(takeUntilDestroyed(this.#destroyRef)).subscribe();
    }

    if (this.activatedRoute.snapshot?.queryParams) {
      this.activatedRoute.snapshot.queryParams.data && (await this.frontendEventsService.updateQueryParams('data'));
      this.activatedRoute.snapshot.queryParams.coords && (await this.frontendEventsService.updateQueryParams('coords'));
    }

    this.objectCardService.clearData();
    this.osmCardService.clearData();
    this.pixelStreamingService.destroySearchAnchor();
    this.additionData().icon === 'objectCardSearch' && this.setBuildingsOption(false);
  }

  /**
   * Производит клик по элементу заголовка и останавливает распространение события.
   *
   * @param {MouseEvent} event - объект MouseEvent, представляющий событие клика.
   *
   * @return {void}
   */
  clickOnTitle(event: MouseEvent): void {
    event.stopPropagation();
  }

  /**
   * Выбирает пункт меню и обновляет группу меню и пункты меню соответственно.
   *
   * @param {MenuItem} menuItem - Пункт меню, который надо выбрать.
   * @param {MenuGroup} menuGroup - Группа меню, к которой принадлежит пункт меню.
   *
   * @return {void}
   */
  selectMenuItem(menuItem: MenuItem, menuGroup: MenuGroup): void {
    this.objectCardService.updateCollapsedGroups(menuItem.type);

    if (menuItem.isActive) {
      return;
    }

    this.objectCardMenu.update((menuGroups) => {
      const index = menuGroups.findIndex((item) => item.uuid === menuGroup.uuid);

      if (index > -1) {
        menuGroups[index].items = menuGroups[index].items.map((groupItem) => ({
          ...groupItem,
          isActive: groupItem.uuid === menuItem.uuid,
        }));
      }

      return menuGroups;
    });
  }

  /**
   * Копирует значение свойства в буфер обмена.
   *
   * @param {Property} property - Объект свойства, содержащий значение для копирования.
   * @return {void}
   */
  copyToClipboard(property: OSMIncidentProperty | OSMObjectProperty | Property | string): void {
    let value;

    if (typeof property === 'string') {
      value = this.clipboard.copy(property);
    } else {
      value = this.clipboard.copy(
        (property.code === 'coordinates'
          ? property.value.toString().replaceAll(', ', ',')
          : property.value
            ? property.value.toString()
            : ''
        ).trim(),
      );
    }

    if (value) {
      this.matSnackBar.open($localize`:@@object-card.copied:Скопировано в буфер обмена`, undefined, {
        duration: DELAY_TIME,
        panelClass: 'copy',
      });
    }
  }

  /**
   * Переключает состояние свернутости группы.
   *
   * @param {object} group - Группа, состояние свернутости которого следует переключить.
   * @throws {TypeError} - Если параметр группы не является действительным объектом.
   * @returns {void}
   */
  toggleCollapsedGroup(group: GroupedProperties[keyof GroupedProperties]): void {
    this.objectCardService.toggleCollapsedGroup(group);

    setTimeout(() => scrollTo(group.type, this.document, 'smooth'), ANIMATION_TIME);
  }

  toggleCollapsedOSMIncidentsGroup(group: OSMIncidentGroupedProperties[keyof OSMIncidentGroupedProperties]): void {
    this.osmCardService.toggleCollapsedOSMIncidentsGroup(group);

    setTimeout(() => scrollTo(group.type, this.document, 'smooth'), ANIMATION_TIME);
  }

  toggleCollapsedOSMObjectGroup(group: OSMObjectGroupedProperties[keyof OSMObjectGroupedProperties]): void {
    this.osmCardService.toggleCollapsedOSMObjectGroup(group);

    setTimeout(() => scrollTo(group.type, this.document, 'smooth'), ANIMATION_TIME);
  }

  /**
   * Выполняет действие при клике на презентацию.
   *
   * Если функция с флагом «OBJECT_CARD_PRESENTATION_ENABLED» отключена, метод возвращает.
   *
   * Метод активирует демонстрационный режим здания, используя ID здания из сгруппированных свойств.
   * Если активация прошла успешно, метод закрывает презентацию.
   * Если происходит ошибка или в ответе нет свойства успеха, метод вызывает ошибку.
   *
   * @returns {void}
   */
  openDemoView(): void {
    if (!this.featureFlagService.isFeatureOn('OBJECT_CARD_PRESENTATION_ENABLED')) {
      return;
    }

    const buildingId = this.buildingId();

    if (buildingId) {
      this.pixelStreamingService
        .sendRequest<{
          params: { buildingId: string };
          result: SUCCESS;
        }>(RpcMessagesBackend.ACTIVATE_BUILDING_DEMO_MODE, { buildingId })
        .pipe(
          map((data) => {
            if (data.result && 'success' in data.result && data.result.success) {
              return data.result.success;
            } else {
              throw new Error('UE result activateBuildingDemoMode error, no success property in response');
            }
          }),
          take(1),
          filter(is(true)),
          takeUntilDestroyed(this.#destroyRef),
        )
        .subscribe(() => {
          this.close();
        });
    }
  }

  /**
   * Добавляет текущий элемент в избранное.
   *
   * @returns {void}
   */
  addFavorite(): void {
    this.favoritesService.addFavoriteAction({
      name: this.additionData().name,
      buildingId: this.buildingId(),
      address: this.objectCardService.getPropertyValueByCode('address') as string,
    });
  }

  /**
   * Удаляет избранное из списка избранного.
   *
   * @param {BuildingCard} favorite - Избранное, которое нужно удалить.
   * @return {void}
   */
  removeFavorite(favorite: BuildingCard): void {
    this.favoritesService.removeFavoriteAction(favorite);
  }

  /**
   * Устанавливает опцию отображения зданий.
   *
   * @param {boolean} second - Определяет, включен ли второй вариант отображения зданий.
   * @returns {Observable<unknown>} - Observable объект, который испускает значение при установке опции.
   */
  setBuildingsOption(second: boolean): void {
    this.backendEventsService
      .setBuildingsOption(second)
      .pipe(take(1))
      .subscribe(() => this.isCurrentBuildingOption2.set(second));
  }

  /**
   * Устанавливает состояние изображения объекта карты.
   * @returns {void}
   */
  objectCardImageLoad(): void {
    this.objectCardService.imageState.set({ error: null, loaded: true });
  }

  /**
   * Обработчик ошибки загрузки изображения карточки объекта.
   *
   * @param {Event} event - Событие ошибки загрузки изображения.
   * @returns {void}
   */
  objectCardImageError(event: Event): void {
    this.objectCardService.imageState.set({ error: event, loaded: true });
  }

  openOSMDashboardCard(): void {
    const id = this.osmAdditionData()?.id;
    id && this.osmIframeService.openDashboardCard(id);
  }

  /**
   * Устанавливает опцию отображения зданий.
   *
   * @param {boolean} isSecondOn - Определяет, включен ли второй вариант отображения зданий.
   * @returns {Observable<unknown>} - Observable объект, который испускает значение при установке опции.
   */
  private getCurrentBuildingOption(): Observable<boolean> {
    return this.pixelStreamingService
      .sendRequest<{ result: GetCurrentBuildingOptionsResult }>(RpcMessagesBackend.GET_CURRENT_BUILDING_OPTION)
      .pipe(
        take(1),
        map((data) => data?.result?.second ?? false),
      );
  }

  /**
   * Проверяет, включены ли действия.
   *
   * @return {boolean} Возвращает true, если действия включены, иначе false.
   */
  private getIsActionsEnabled(): boolean {
    return (
      (this.additionData().hasDemoViewMode && this.featureFlagService.isFeatureOn('OBJECT_CARD_PRESENTATION_ENABLED')) ||
      (this.additionData().hasBuildingsOptionIcon && this.featureFlagService.isFeatureOn('BUILDINGS_OPTIONS'))
    );
  }
}
