import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, catchError, map, of } from 'rxjs';

export type ConfigKeys =
  | '3D-MODELS'
  | 'ACT_AGR_ID'
  | 'BUILDINGS_OPTIONS'
  | 'CONFIG'
  | 'CONTROL_MODES'
  | 'DESTROY_SEARCH_ANCHOR'
  | 'FAVORITES'
  | 'LAYERS'
  | 'LEVELS'
  | 'LIGHT_TOGGLE'
  | 'MATCH_MAKER_ENABLE_CHECK'
  | 'MATCH_MAKER'
  | 'OBJECT_CARD_ENABLED'
  | 'OBJECT_CARD_IMAGE_ENABLED'
  | 'OBJECT_CARD_PRESENTATION_ENABLED'
  | 'PCG'
  | 'RECONNECT'
  | 'RPC_LOGS_ENABLED'
  | 'SEARCH'
  | 'SETTINGS'
  | 'SHOW_PING_ERROR'
  | 'SHOW_PIXEL_STREAMING_EXTENDED_LOGS'
  | 'SHOW_PIXEL_STREAMING_LOGS'
  | 'TELEPORT_CAMERA_TO_OBJECT'
  | 'TELEPORT_CAMERA'
  | 'UE_PING';
export type Config = Record<ConfigKeys, boolean>;

/**
 * Представляет собой сервис для обработки флагов функций.
 *
 * @remarks
 * Этот класс позволяет проверить, включена ли функция и загрузить конфигурацию с сервера.
 *
 * @example
 * const featureService = new FeatureFlagService(httpClient);
 *
 * const isFeatureOn = featureService.isFeatureOn('feature1');
 *
 */
@Injectable()
export class FeatureFlagService {
  /**
   * Представляет конфигурационный объект.
   *
   * @typedef {Object} Config
   * @property {string} option1 - Значение для варианта 1.
   * @property {number} option2 - Значение для варианта 2.
   * @property {boolean} option3 - Значение для варианта 3.
   * @property {Array} option4 - Массив значений для варианта 4.
   */
  #config?: Config;

  /**
   * Флаг, указывающий на наличие ошибки загрузки конфигурации.
   *
   * @type {boolean}
   */
  hasLoadConfigError = false;

  /**
   * Конструктор класса.
   *
   * @param {HttpClient} httpClient - Экземпляр класса HttpClient.
   * @param {MatSnackBar} matSnackBar - Экземпляр класса MatSnackBar.
   * @return {void}
   */
  constructor(
    private httpClient: HttpClient,
    private matSnackBar: MatSnackBar,
  ) {}

  /**
   * Проверяет, включена ли данная функция.
   *
   * @param {ConfigKeys} featureName - Название функции для проверки.
   * @return {boolean} - Возвращает true, если функция включена, иначе false.
   */
  isFeatureOn(featureName?: ConfigKeys): boolean {
    return featureName && this.#config ? (this.#config[featureName] ?? false) : false;
  }

  /**
   * Загружает конфигурацию с сервера.
   *
   * @returns {Observable<Config>} Наблюдаемый объект, который выдает загруженную конфигурацию.
   */
  loadConfig(): Observable<Config | undefined> {
    const uniqueParam = new Date().getTime();
    const url = `./config/feature-flag.json?cacheBuster=${uniqueParam}`;

    return this.httpClient.get<Config>(url).pipe(
      map((config) => {
        this.setConfig(config);
        return config;
      }),
      catchError((error) => {
        console.error('loadConfig', error);
        this.hasLoadConfigError = true;
        this.loadConfigErrorSnackBar(error.status);

        return of(undefined);
      }),
    );
  }

  /**
   * Отображает snack bar с сообщением об ошибке на основе предоставленного кода статуса.
   *
   * @param {number} status - Код статуса HTTP.
   *
   * @return {void}
   */
  private loadConfigErrorSnackBar(status: number): void {
    this.matSnackBar.open(
      status === 429
        ? $localize`:@@feature-flag.error-429-snack-bar:Слишком частые запросы, пожалуйста попробуйте через несколько минут`
        : $localize`:@@feature-flag.error-other-snack-bar:Ошибка сервера, пожалуйста попробуйте через несколько минут`,
      undefined,
      {
        duration: 0,
        panelClass: 'error',
      },
    );
  }

  /**
   * Устанавливает конфигурацию для объекта.
   *
   * @param {Config} config - Конфигурация для установки.
   * @return {void}
   */
  private setConfig(config: Config): void {
    this.#config = config;
  }
}
