@if (controlMode(); as controlMode) {
  <ul class="modes" [class.modes_visible]="!isHidden() && !isUninitialized()">
    @if (isMovementMode()) {
      <li class="modes__item hover">
        @if (prevActiveNonMovementType() === 'cartographic') {
          <button
            #cartographicBtn
            blurAfterFocus
            class="modes__item-btn"
            i18n-matTooltip="@@control-mode.tooltip.cartographic-non-active"
            matTooltip="Включить картографическое управление"
            matTooltipClass="large"
            matTooltipPosition="above"
            type="button"
            (click)="setControlMode({ type: 'cartographic', mode: 'none', modifier: 'none' })"
            (mouseenter)="setBtnHover(true, 'cartographic')"
            (mouseleave)="setBtnHover(false, 'cartographic')"
          >
            <svg-icon class="modes__item-icon" name="cartographic" />
          </button>
        } @else {
          <button
            #freecamBtn
            blurAfterFocus
            class="modes__item-btn"
            i18n-matTooltip="@@control-mode.tooltip.freecam-non-active"
            matTooltip="Включить свободнау камеру"
            matTooltipClass="large"
            matTooltipPosition="above"
            type="button"
            (click)="setControlMode({ type: 'direct', mode: 'freecam', modifier: 'none' })"
            (mouseenter)="setBtnHover(true, 'freecam')"
            (mouseleave)="setBtnHover(false, 'freecam')"
          >
            <svg-icon class="modes__item-icon" name="freecam" />
          </button>
        }
      </li>
    } @else {
      <li class="modes__item modes__item_active movement-mode">
        <button
          #freecamBtn
          blurAfterFocus
          class="modes__item-btn"
          i18n-matTooltip="@@control-mode.tooltip.freecam"
          matTooltip="Включить свободную камеру"
          matTooltipClass="large"
          matTooltipPosition="above"
          type="button"
          [class.modes__item-btn_active]="controlMode.type === 'cartographic'"
          (click)="setControlMode({ type: 'direct', mode: 'freecam', modifier: 'none' })"
          (mouseenter)="setBtnHover(true, 'freecam')"
          (mouseleave)="setBtnHover(false, 'freecam')"
        >
          <svg-icon class="modes__item-icon" name="cartographic" />
          <svg-icon class="modes__item-icon" name="freecam" />
        </button>
        <button
          #cartographicBtn
          blurAfterFocus
          class="modes__item-btn"
          i18n-matTooltip="@@control-mode.tooltip.cartographic"
          matTooltip="Включить картографическое управление"
          matTooltipClass="large"
          matTooltipPosition="above"
          type="button"
          [class.modes__item-btn_active]="controlMode.mode === 'freecam'"
          (click)="setControlMode({ type: 'cartographic', mode: 'none', modifier: 'none' })"
          (mouseenter)="setBtnHover(true, 'cartographic')"
          (mouseleave)="setBtnHover(false, 'cartographic')"
        >
          <svg-icon class="modes__item-icon" name="freecam" />
          <svg-icon class="modes__item-icon" name="cartographic" />
        </button>
      </li>
    }
    <li
      #characterModeItem
      class="modes__item"
      [class.modes__item_active]="controlMode.type === 'direct' && controlMode.mode === 'character'"
      (mouseenter)="setHover(true, 'character')"
      (mouseleave)="setHover(false, 'character')"
    >
      <button
        blurAfterFocus
        class="modes__item-btn"
        i18n-matTooltip="@@control-mode.tooltip.character-on"
        matTooltip="Включить режим аватара"
        matTooltipClass="large"
        matTooltipPosition="above"
        type="button"
        (click)="setControlMode({ type: 'direct', mode: 'character', modifier: 'none' })"
      >
        <svg-icon class="modes__item-icon" name="character" />
      </button>
      @if (controlMode.type === 'direct' && controlMode.mode === 'character') {
        <button
          class="modes__item-btn modes__item-btn_cancel"
          i18n-matTooltip="@@control-mode.tooltip.character-off"
          matTooltip="Выключить режим аватара"
          matTooltipClass="large"
          matTooltipPosition="above"
          type="button"
          [disabled]="isSaving()"
          (click)="exitCharacterPlacementMode()"
        >
          <svg-icon class="modes__item-icon" name="close" />
        </button>
      }
    </li>
    <li
      #vehicleModeItem
      class="modes__item"
      [class.modes__item_active]="controlMode.type === 'direct' && controlMode.mode === 'vehicle'"
      (mouseenter)="setHover(true, 'vehicle')"
      (mouseleave)="setHover(false, 'vehicle')"
    >
      <button
        blurAfterFocus
        class="modes__item-btn"
        i18n-matTooltip="@@control-mode.tooltip.vehicle-on"
        matTooltip="Включить режим автомобиля"
        matTooltipClass="large"
        matTooltipPosition="above"
        type="button"
        (click)="setControlMode({ type: 'direct', mode: 'vehicle', modifier: 'none' })"
      >
        <svg-icon class="modes__item-icon" name="vehicle" />
      </button>
      @if (controlMode.type === 'direct' && controlMode.mode === 'vehicle') {
        <button
          class="modes__item-btn modes__item-btn_cancel"
          i18n-matTooltip="@@control-mode.tooltip.vehicle-off"
          matTooltip="Выключить режим автомобиля"
          matTooltipClass="large"
          matTooltipPosition="above"
          type="button"
          [disabled]="isSaving()"
          (click)="exitCharacterPlacementMode()"
        >
          <svg-icon class="modes__item-icon" name="close" />
        </button>
      }
    </li>
  </ul>
}
