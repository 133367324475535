import { Routes } from '@angular/router';

import { featureFlagGuard } from '../../guards/feature-flag.guard';
import { RouterData } from '../../types/router-data.interface';
import { ToolbarComponent } from './toolbar.component';

export const TOOLBAR_ROUTES: Routes = [
  {
    path: '',
    component: ToolbarComponent,
    children: [
      {
        canActivate: [featureFlagGuard],
        path: 'search',
        data: { title: $localize`Поиск`, hiddenTitle: true, featureFlagKey: 'SEARCH' } as RouterData,
        loadComponent: () => import('./search/search.component').then((m) => m.SearchComponent),
      },
      {
        canActivate: [featureFlagGuard],
        path: 'levels',
        data: { title: $localize`Локации`, featureFlagKey: 'LEVELS' } as RouterData,
        loadComponent: () => import('./levels/levels.component').then((m) => m.LevelsComponent),
      },
      {
        canActivate: [featureFlagGuard],
        path: 'layers',
        data: { title: $localize`Подложки`, featureFlagKey: 'LAYERS' } as RouterData,
        loadComponent: () => import('./layers/layers.component').then((m) => m.LayersComponent),
      },
      {
        canActivate: [featureFlagGuard],
        path: 'favorites',
        data: { title: $localize`Избранное`, featureFlagKey: 'FAVORITES', noBorder: true, icon: 'favorites' } as RouterData,
        loadComponent: () => import('./favorites/favorites.component').then((m) => m.FavoritesComponent),
      },
      {
        canActivate: [featureFlagGuard],
        path: '3d-models',
        data: { title: $localize`Трёхмерные модели`, featureFlagKey: '3D-MODELS', noBorder: true, icon: '3DModels' } as RouterData,
        loadComponent: () => import('./3dmodels/models3d.component').then((m) => m.Models3dComponent),
      },
      {
        canActivate: [featureFlagGuard],
        path: 'pcg',
        data: { title: $localize`Генеративное проектирование`, featureFlagKey: 'PCG', noBorder: true, icon: 'pcg' } as RouterData,
        redirectTo: '',
      },
      {
        canActivate: [featureFlagGuard],
        path: 'settings',
        data: { title: $localize`Настройки`, featureFlagKey: 'SETTINGS', noBorder: true } as RouterData,
        loadComponent: () => import('./settings/settings.components').then((m) => m.SettingsComponent),
      },
    ],
  },
];
