import { IconType } from '../svg-icon/generated-svg/svg-icons';

export const TAB_NAME_TO_INDEX = {
  incident: 0,
  object: 1,
} as const;

export interface OsmPostMessageData {
  jsonrpc: '2.0';
  method: OsmPostMessageMethod;
  params: {
    id: string;
    type: 'incident' | 'object';
  };
  id: string;
}

export const OsmPostMessageMethod = {
  OPEN_OSM_CARD: 'openOsmCard',
  OPEN_DASHBOARD_CARD: 'openDashboardCard',
  DEACTIVATE_OSM_MODE: 'deactivateOsmMode',
} as const;

export type OsmPostMessageMethod = (typeof OsmPostMessageMethod)[keyof typeof OsmPostMessageMethod];

export type OSMIncidentGroupedProperties = Record<
  | 'additionalInfo'
  | 'administrativeResponse'
  | 'complaintsViolations'
  | 'controlWork'
  | 'general'
  | 'monitoringData'
  | 'relatedIncidents'
  | 'servicesWork',
  {
    type:
      | 'additionalInfo'
      | 'administrativeResponse'
      | 'complaintsViolations'
      | 'controlWork'
      | 'general'
      | 'monitoringData'
      | 'relatedIncidents'
      | 'servicesWork';
    collapsed: boolean;
    values: OSMIncidentProperty[];
  }
>;

export type OSMObjectGroupedProperties = Record<
  'characteristics' | 'general' | 'status',
  { type: 'characteristics' | 'general' | 'status'; collapsed: boolean; values: OSMObjectProperty[] }
>;

export interface OSMIncidentProperty {
  text: string;
  icon: IconType | undefined;
  code: keyof Omit<OSMIncident, 'propertyAttributes'> | keyof OSMIncident['propertyAttributes'];
  value: RelatedIncident[] | string[] | number | string;
  group: {
    type:
      | 'additionalInfo'
      | 'administrativeResponse'
      | 'complaintsViolations'
      | 'controlWork'
      | 'general'
      | 'monitoringData'
      | 'relatedIncidents'
      | 'servicesWork';
    text: string;
  };
}

export interface OSMObjectProperty {
  text: string;
  icon: IconType;
  code: keyof OSMObject;
  value: number | string | null;
  group: { type: 'characteristics' | 'general' | 'status'; text: string };
}

export const OSMIncidentPropertyGroup: Record<
  | 'ADDITIONALINFO'
  | 'ADMINISTRATIVERESPONSE'
  | 'COMPLAINTSVIOLATIONS'
  | 'CONTROLWORK'
  | 'GENERAL'
  | 'MONITORINGDATA'
  | 'RELATEDINCIDENTS'
  | 'SERVICESWORK',
  OSMIncidentProperty['group']
> = {
  GENERAL: { type: 'general', text: $localize`Общая информация` },
  ADDITIONALINFO: { type: 'additionalInfo', text: $localize`Дополнительная информация` },
  SERVICESWORK: { type: 'servicesWork', text: $localize`Работа городских служб` },
  CONTROLWORK: { type: 'controlWork', text: $localize`Работа контрольных органов` },
  MONITORINGDATA: { type: 'monitoringData', text: $localize`Данные видеомониторинга` },
  COMPLAINTSVIOLATIONS: { type: 'complaintsViolations', text: $localize`Жалобы и нарушения на объекте` },
  ADMINISTRATIVERESPONSE: { type: 'administrativeResponse', text: $localize`Административное и ведомственное реагирование` },
  RELATEDINCIDENTS: { type: 'relatedIncidents', text: $localize`Связанные инциденты` },
};

export const OSMObjectPropertyGroup: Record<'CHARACTERISTICS' | 'GENERAL' | 'STATUS', OSMObjectProperty['group']> = {
  GENERAL: { type: 'general', text: $localize`Общая информация` },
  CHARACTERISTICS: { type: 'characteristics', text: $localize`Основные характеристики` },
  STATUS: { type: 'status', text: $localize`Состояние объекта` },
};

export const OSMIncidentGroupKeysOrder = [
  'general',
  'monitoringData',
  'servicesWork',
  'controlWork',
  'complaintsViolations',
  'administrativeResponse',
  'additionalInfo',
  'relatedIncidents',
] as const;

export const OSMObjectGroupKeysOrder = ['general', 'status', 'characteristics'] as const;

export interface OSMIncidentResponse {
  _id: string;
  type: string;
  geometry: Geometry;
  properties: OSMIncident;
}

export interface Geometry {
  type: string;
  coordinates: number[];
}

export interface OSMIncident {
  id: string;
  title: string;
  dateTime: string;
  Type: string;
  address: string;
  coverage: number;
  description: string;
  photo: string[];
  servicesWork: string;
  controlWork: string;
  complaintsInfo: string;
  additionalInfo: string;
  userId: string;
  propertyAttributes: PropertyAttributes;
  relatedIncidents: RelatedIncident[];
}

export interface RelatedIncident {
  date: string;
  title: string;
  url: string;
}

export interface PropertyAttributes {
  area?: number;
  yardArea?: string;
  lawnsArea?: string;
  floors?: string;
  entrances?: number;
  apartments?: number;
  buildYear?: number;
  objectPassport?: string;
  monitoringData: string;
  urbanServices: string;
  controlAuthorities?: string;
  complaintsViolations?: string;
  administrativeResponse?: string;
}

export interface OSMObjectResponse {
  _id: string;
  type: string;
  geometry: Geometry;
  properties: OSMObject;
}

export interface OSMObject {
  id: string;
  objectType: string;
  address: string;
  balancer: string;
  status: string;
  characteristics: Characteristics;
  userId: string;
}

export interface Characteristics {
  area: number | null;
  floors: number | null;
  apartments: number | null;
}
