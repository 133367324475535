import { createFeatureSelector, createSelector } from '@ngrx/store';

import { LayersState } from './layers.interface';
import { adapter } from './layers.reducer';

/**
 * Получает все элементы, которые соответствуют заданному селектору из DOM.
 *
 * @param {string} селектор - CSS-селектор для совпадения.
 * @returns {Array<Element>} - Массив элементов, соответствующие селектору.
 */
const { selectAll } = adapter.getSelectors();

/**
 * Извлекает состояние слоев из хранилища Redux.
 *
 * @typedef {LayersState} selectLayersState
 *
 * @return {LayersState} Состояние слоев.
 */
export const selectLayersState = createFeatureSelector<LayersState>('layers');
/**
 * Функция селектора Redux для выбора слоев из состояния.
 *
 * @param {Function} selectLayersState - Функция селектора для выбора состояния слоев.
 * @param {Function} selectAll - Функция селектора для выбора всех слоев.
 * @returns {Function} - Мемоизированная функция селектора для выбора слоев из состояния.
 */
export const selectLayers = createSelector(selectLayersState, selectAll);
/**
 * Возвращают селектор, который извлекает значение hybridEnabled из состояния слоев.
 *
 * @param {Function} selectLayersState - Функция селектора для извлечения состояния слоев.
 * @returns {Function} Функция селектора, которая извлекает значение hybridEnabled.
 */
export const selectHybridEnabled = createSelector(selectLayersState, (state) => state.hybridEnabled);
/**
 * Извлекает активные слои из предоставленного массива слоев.
 *
 * @param {Array} selectLayers - Массив слоев для фильтрации.
 * @returns {Array} - Массив активных слоев.
 */
export const selectEnabledLayers = createSelector(selectLayers, (layers) => layers.filter((layer) => layer.isEnabled));
/**
 * Извлекает состояние загрузки слоев из состояния слоев
 * с использованием селекторов selectLayersState и состояния.
 *
 * @param {function} selectLayersState - Функция селектора для получения состояния слоев
 * @param {object} state - Текущее состояние приложения
 * @returns {string} Cостояние загрузки слоев
 */
export const selectLayersLoadingStatus = createSelector(selectLayersState, (state) => state.status);
