import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';

import { NotificationsComponent } from './component/notifications.component';
import { SNACKBAR_DURATION } from './notifications.constant';
import { NotificationData } from './notifications.type';

/**
 * Сервис для отображения уведомлений.
 */
@Injectable({ providedIn: 'root' })
export class NotificationsService {
  /**
   * Представляет ссылку на открытое уведомление.
   *
   * @type {MatSnackBarRef<NotificationsComponent> | null}
   */
  openedNotification: MatSnackBarRef<NotificationsComponent> | null = null;

  /**
   * Создает экземпляр конструктора.
   *
   * @param {MatSnackBar} snackBar - Экземпляр MatSnackBar.
   * @param {NgZone} zone - Экземпляр NgZone.
   */
  constructor(
    private snackBar: MatSnackBar,
    private zone: NgZone,
  ) {}

  /**
   * Открывает уведомление с указанными данными.
   *
   * @param {NotificationData} notificationData - Данные для уведомления.
   * @returns {MatSnackBarRef<NotificationsComponent>} - Ссылка на открытое уведомление.
   */
  open({
    type,
    text,
    title,
    action,
    isClosable = false,
    duration = SNACKBAR_DURATION,
    withIcon = true,
  }: NotificationData): MatSnackBarRef<NotificationsComponent> {
    return this.zone.run(() => {
      this.openedNotification = this.snackBar.openFromComponent(NotificationsComponent, {
        duration,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        data: {
          withIcon,
          type,
          text,
          title,
          isClosable,
          action,
        } satisfies NotificationData,
      });

      return this.openedNotification;
    });
  }

  /**
   * Закрывает текущее открытое уведомление.
   *
   * @return {void}
   */
  dismiss(): void {
    this.openedNotification?.dismiss();
    this.openedNotification = null;
  }
}
