import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, signal } from '@angular/core';

import { WINDOW } from '../tokens/window.token';

/**
 * Представляет тему для приложения.
 * @typedef {('dark' | 'light')} Theme
 */
export type Theme = 'dark' | 'light';

/**
 * Сервис, ответственный за управление темой приложения.
 */
@Injectable({ providedIn: 'root' })
export class ThemeService {
  /**
   * Представляет переменную с именем "theme", которая представляет сигнал с начальным значением типа "Theme".
   *
   * @type {Signal<Theme>}
   */
  theme = signal<Theme>(this.getThemeInitialValue());

  /**
   * Создает новый экземпляр конструктора.
   *
   * @param {Document} document - Внедренный объект Document.
   * @param {Window} window - Внедренный объект Window.
   * @return {void}
   */
  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window,
  ) {}

  /**
   * Устанавливает тему материала на элементе body.
   *
   * @param {('dark' | 'light')} theme - Устанавливаемая материальная тема.
   * @return {void} - Этот метод ничего не возвращает.
   */
  setMaterialTheme(theme: Theme): void {
    this.theme.set(theme);
    this.document.body.classList.remove(theme === 'light' ? 'dark-theme' : 'light-theme');
    this.document.body.classList.add(theme + '-theme');
    this.window.localStorage.setItem('theme', theme);
  }

  /**
   * Переключает тему между светлой и темной.
   *
   * @return {void} Нет возвращаемого значения.
   */
  toggleTheme(): void {
    this.setMaterialTheme(this.theme() === 'light' ? 'dark' : 'light');
  }

  /**
   * Устанавливает начальное значение темы.
   *
   * @returns {void}
   */
  setThemeInitialValue(): void {
    this.setMaterialTheme(this.getThemeInitialValue());
  }

  /**
   * Извлекает начальное значение темы из localStorage.
   *
   * @private
   * @returns {Theme} Начальное значение темы.
   */
  private getThemeInitialValue(): Theme {
    const theme = this.window.localStorage.getItem('theme');
    return theme === 'light' || theme === 'dark' ? theme : 'dark';
  }
}
