import { createFeatureSelector, createSelector } from '@ngrx/store';

import { LevelsState, adapter } from './levels.reducer';

const { selectAll, selectEntities } = adapter.getSelectors();

export const selectLevelsState = createFeatureSelector<LevelsState>('levels');
export const selectLevels = createSelector(selectLevelsState, selectAll);
export const selectLevelsEntities = createSelector(selectLevelsState, selectEntities);
export const selectLevelsLoadingStatus = createSelector(selectLevelsState, (state) => state.status);
export const selectCurrentLevelName = createSelector(selectLevelsState, (state) => state.currentLevelName);
export const selectCurrentLevel = createSelector(selectCurrentLevelName, selectLevelsEntities, (currentLevelName, entities) =>
  currentLevelName && entities ? entities[currentLevelName] : undefined,
);
