import { Injectable, signal } from '@angular/core';

/**
 * @class PCGService
 *
 * Представляет службу PCGService, которую можно использовать для переключения состояния isActive.
 *
 * @property {boolean} isGeographicToolPanelActive - Указывает, активна ли служба или нет.
 *
 * @method toggleGeographicToolPanel - Переключает состояние isActive.
 */
@Injectable({
  providedIn: 'root',
})
export class PCGService {
  /**
   * isActive
   *
   * Переменная, представляющая текущее состояние сигнала.
   *
   * @typedef {Object} isGeographicToolPanelActive
   * @property {boolean} value - Текущее значение сигнала.
   *
   * @param {boolean} initialValue - Начальное значение сигнала.
   * @returns {isGeographicToolPanelActive} - Текущее состояние сигнала.
   */
  isGeographicToolPanelActive = signal(false);

  /**
   * Переключает активное состояние компонента.
   *
   * @returns {void}
   */
  toggleGeographicToolPanel(): void {
    this.isGeographicToolPanelActive.update((val) => !val);
  }
}
