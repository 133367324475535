/**
 * Задержка в миллисекундах, используемая для дебаунсинга (устранения дребезга).
 *
 * @type {number}
 * @constant
 */
export const DEBOUNCE_TIME = 250;
export const DELAY_TIME = 500;
export const ANIMATION_TIME = 200;
export const MATCHMAKER_RETRY_DELAY = 5_000;

/**
 * Время в миллисекундах, через которое нужно скрыть прелоадер после аутентификации.
 *
 * @type {number}
 * @constant
 * @default 700
 */
export const AUTH_HIDE_PRELOADER_TIME = 700;

/**
 * Время в миллисекундах, которое нужно ожидать перед повторной попыткой неудавшейся операции.
 *
 * @constant
 * @type {number}
 */
export const RETRY_TIME = 1500;

/**
 * Константа INTERVAL_TIME представляет интервал времени в миллисекундах.
 * Она используется, чтобы определить продолжительность между двумя последовательными событиями.
 *
 * @constant
 * @type {number}
 * @default 5000
 */
export const INTERVAL_TIME = 5000;

/**
 * Представляет задержку в миллисекундах для повторного подключения к потоковому сервису.
 *
 * @constant {number} STREAMING_RECONNECT_DELAY
 * @default 5000
 * @description Задержка, используемая для определения, сколько времени ждать перед попыткой повторного подключения
 *              к потоковому сервису в случае отключения или других ошибок.
 */
export const STREAMING_RECONNECT_DELAY = 10_000;

export const STREAMING_CONNECT_FAIL_TIME = 30_000;

/**
 * Представляет коды ошибок RPC.
 *
 * @typedef {Object} RpcError
 * @property {number} code - Код ошибки.
 * @property {string} value - Значение ошибки.
 * @property {string} description - Описание ошибки.
 */
export const RpcErrorsCodes: Record<string, { code: number; value: string; description: string }> = {
  '-32700': { code: -32700, value: 'Parse error', description: 'Ошибка при разборе JSON.' },
  '-32600': { code: -32600, value: 'Invalid Request', description: 'Некорректный запрос JSON-RPC.' },
  '-32601': { code: -32601, value: 'Method not found', description: 'Запрошенный метод не найден.' },
  '-32602': { code: -32602, value: 'Invalid params', description: 'Некорректные параметры метода.' },
  '-32603': { code: -32603, value: 'Internal error', description: 'Внутренняя ошибка сервера при выполнении метода.' },
  '-32000': { code: -32000, value: 'Server error', description: 'Ошибка сервера, несвязанная с JSON-RPC.' },
  '-32001': {
    code: -32001,
    value: 'Server not ready',
    description: 'Сервер не готов обработать запрос (поддерживается не во всех' + ' реализациях).',
  },
};

/**
 * ключ в виде URL сигнального сервера, сохраненный в локальном хранилище.
 *
 * @type {string}
 * @const
 */
export const SIGNALLING_URL_LOCAL_STORAGE = 'signalling.url' as const;

/**
 * Константная переменная, представляющая имя атрибута для маркировки input HTML элемента для синхронизации с событиями UE
 *
 * @constant
 * @type {string}
 */
export const INPUT_UE_EVENTS = 'data-ue-events';
