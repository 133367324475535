@for (incident of incidents(); track incident.title) {
  <li class="list-item" (click)="copyToClipBoard(incident)">
    <p class="list-item__description">
      {{ incident.date }} -  {{ incident.title }}
    </p>
    <button blurAfterFocus class="list-item__copy" type="button">
      <svg-icon class="list-item__copy-icon" name="copy" />
    </button>
  </li>
}
