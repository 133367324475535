import { Pipe, PipeTransform } from '@angular/core';

/**
 * AvifPipe - это пользовательский пайп в Angular, который преобразует строковое значение для добавления расширения '.avif'.
 *
 * @pipe
 * @publicApi
 */
@Pipe({ name: 'avif', standalone: true })
export class AvifPipe implements PipeTransform {
  /**
   * Преобразует данное значение, заменяя его расширение файла на '.avif'.
   *
   * @param {string} value - Исходное строковое значение.
   * @return {string} Преобразованное строковое значение с расширением файла '.avif'.
   */
  transform(value: string): string {
    return value.replace(/\.[^.]+$/, '.avif');
  }
}
