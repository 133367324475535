import { Pipe, PipeTransform } from '@angular/core';

import { OSMIncidentProperty, RelatedIncident } from '../osm.interface';

@Pipe({
  name: 'isRelatedIncident',
  standalone: true,
})
export class IsRelatedIncidentPipe implements PipeTransform {
  transform(value: OSMIncidentProperty['value']): value is RelatedIncident[] {
    return Array.isArray(value) && value.every((incident) => (incident as RelatedIncident)?.date);
  }
}
