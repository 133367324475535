<div class="introWrapper">
  <div class="splash-image-wrapper">
    <div class="splash-image">
      <app-keen-slider [slides]="slides" (move)="moveSlide($event)" />
    </div>
  </div>
  <div class="content">
    <div class="logo">
      <img alt="Иконка логотипа" i18n-alt="@@intro.image-alt" src="/assets/images/logo.svg" />
      <h1 class="logo__title" i18n="@@intro.title">Мета</h1>
    </div>
    <button
      class="start-button"
      i18n="@@intro.button-text"
      type="button"
      [disabled]="!(isStreamReady$ | ngrxPush)"
      (click)="onStartStreaming()"
    >
      Начать работу
    </button>
  </div>
</div>
