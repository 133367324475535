export const LightType = {
  NO_LIGHT: 'noLight',
  NIGHT_LIGHT: 'nightLight',
  DAY_LIGHT: 'dayLight',
} as const;

export type LightType = (typeof LightType)[keyof typeof LightType];

export const LightButtonTooltipText = {
  DAY_ON: $localize`:@@light-toggle-button.button.tooltip.day:Включить день`,
  NIGHT_ON: $localize`:@@light-toggle-button.button.tooltip.night:Включить ночь`,
} as const;

export type LightButtonTooltipText = (typeof LightButtonTooltipText)[keyof typeof LightButtonTooltipText];

export const LightIconState = {
  FILLED: 'filled',
  NON_FILLED: 'non-filled',
} as const;

export type LightIconStateType = (typeof LightIconState)[keyof typeof LightIconState];
