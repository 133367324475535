import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { Status } from '../../../../enums/status.enum';
import { BuildingCard } from '../favorites.interface';
import { addFavorite, getFavorites, getFavoritesSuccess, removeFavorite, replaceFavorites } from './favourites.actions';

export interface FavouritesState extends EntityState<BuildingCard> {
  status: Status;
  error: string | null;
}

export const adapter: EntityAdapter<BuildingCard> = createEntityAdapter<BuildingCard>({
  selectId: (event: BuildingCard) => event.buildingId,
  sortComparer: false,
});

export const initialState: FavouritesState = {
  status: Status.UNINITIALIZED,
  error: null,
  ids: [],
  entities: {},
};

const reducer = createReducer(
  initialState,
  on(getFavorites, (state): FavouritesState => ({ ...state, status: Status.LOADING })),
  on(getFavoritesSuccess, (state, { buildingCards }) => adapter.addMany(buildingCards, { ...state, status: Status.LOADED })),
  on(addFavorite, (state, { favorite }) => adapter.addOne(favorite, state)),
  on(removeFavorite, (state, { favorite }) => adapter.removeOne(favorite.buildingId, state)),
  on(replaceFavorites, (state, { newFavorites }) => adapter.setAll(newFavorites, state)),
);

export function favoritesReducer(state: FavouritesState | undefined, action: Action): FavouritesState {
  return reducer(state, action);
}
