import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';

import { environment } from '../../../environments/environment';
import { OSMIncident, OSMIncidentResponse, OSMObject, OSMObjectResponse } from './osm.interface';

@Injectable({
  providedIn: 'root',
})
export class OsmService {
  constructor(private httpClient: HttpClient) {}

  addIncident(incident: OSMIncident): Observable<OSMIncident> {
    return this.httpClient.post<OSMIncident>(`${environment.WEB_ADMIN_URL}/addIncident`, incident);
  }

  getIncidentById(id: string): Observable<OSMIncident> {
    return this.httpClient
      .get<OSMIncidentResponse>(`${environment.WEB_ADMIN_URL}/getIncidentById/${id}`)
      .pipe(map((response) => response.properties));
  }

  getIncidentsByUserId(userId: string): Observable<OSMIncident> {
    return this.httpClient.get<OSMIncident>(`${environment.WEB_ADMIN_URL}/getIncidentsByUserId/${userId}`);
  }

  getObjectById(id: string): Observable<OSMObject> {
    return this.httpClient
      .get<OSMObjectResponse>(`${environment.WEB_ADMIN_URL}/getObjectById/${id}`)
      .pipe(map((response) => response.properties));
  }
}
