import { JsonPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';

import { BlurAfterFocusDirective } from '../../../../directives/blur-after-focus/blur-after-focus.directive';
import { SvgIconModule } from '../../../svg-icon/svg-icon.module';
import { RelatedIncident } from '../../osm.interface';

@Component({
  selector: 'app-related-incidents',
  standalone: true,
  imports: [SvgIconModule, BlurAfterFocusDirective, JsonPipe],
  templateUrl: './related-incidents.component.html',
  styleUrl: './related-incidents.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RelatedIncidentsComponent {
  incidents = input.required<RelatedIncident[]>();
  copyIncident = output<string>();

  copyToClipBoard(incident: RelatedIncident): void {
    this.copyIncident.emit(`${incident.date} -  ${incident.title}`);
  }
}
