import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, catchError, map, of } from 'rxjs';

import { Status } from '../../../enums/status.enum';
import { ConfigService } from '../../../services/config.service';
import { LayersState } from '../layers/store/layers.interface';
import { AddFavorite, BuildingCard, RemoveFavorite, ReplaceFavorites } from './favorites.interface';
import { addFavorite, getFavorites, removeFavorite, replaceFavorites } from './store/favourites.actions';
import { selectFavoriteByBuildingId, selectFavourites, selectFavouritesLoadingStatus } from './store/favourites.selectors';

/**
 * Сервис для управления избранными пользователями.
 * @class СлужбаИзбранного
 * @constructor
 */
@Injectable({ providedIn: 'root' })
export class FavoritesService {
  /**
   * Извлекает избранное из функции selectSignal магазина.
   *
   * @returns {Observable} - Observable, эмитирующий текущее избранное.
   */
  favourites = this.store$.selectSignal(selectFavourites);
  /**
   * Представляет статус загрузки избранного.
   * @type {Observable<boolean>}
   */
  loadingStatus = this.store$.selectSignal(selectFavouritesLoadingStatus);
  /**
   * Извлекает избранное по ID здания из хранилища.
   *
   * @function getFavoriteByBuildingId
   * @returns {Observable<Favorite>} Обсервабл, который эмитирует избранное с указанным ID здания.
   */
  readonly getFavoriteByBuildingId = this.store$.selectSignal(selectFavoriteByBuildingId);

  /**
   * Представляет id пользователя, полученный из hostname текущего документа.
   *
   * @type {string}
   */
  #userId = this.document.location.hostname;

  /**
   * Создает экземпляр класса Constructor.
   *
   * @param {Document} document - Объект document, внедренный в конструктор.
   * @param {HttpClient} httpClient - Экземпляр клиента HTTP, используемого для выполнения HTTP-запросов.
   * @param {Store<LayersState>} store$ - Экземпляр хранилища RxJS, используемый для управления состоянием слоев.
   * @param {ConfigService} configService - сервис для управления конфигурацией приложения
   */
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private httpClient: HttpClient,
    private store$: Store<LayersState>,
    private configService: ConfigService,
  ) {}

  /**
   * Выполняет действие по извлечению избранного.
   *
   * @returns {void} Указывает, что метод не возвращает значение.
   */
  getFavoritesAction(): void {
    this.loadingStatus() === Status.UNINITIALIZED && this.store$.dispatch(getFavorites());
  }

  /**
   * Извлекает избранные карточки зданий для пользователя.
   *
   * @returns {Observable<BuildingCard[]>} - Обсервабл, который эмитирует массив объектов BuildingCard, представляющих избранные карточки зданий.
   */
  getFavorites(): Observable<BuildingCard[]> {
    return this.httpClient.get<BuildingCard[]>(`${this.configService.getValue('WEB_ADMIN_URL')}/getFavorites?userId=${this.#userId}`).pipe(
      map((buildingCards) => buildingCards),
      catchError((error) => {
        console.error('getFavorites', error);

        return of([]);
      }),
    );
  }

  /**
   * Добавляет действие в избранное в хранилище.
   *
   * @param {BuildingCard} favorite - Карточка здания, добавляемая в избранное.
   * @return {void}
   */
  addFavoriteAction(favorite: BuildingCard): void {
    this.store$.dispatch(addFavorite({ favorite }));
  }

  /**
   * Добавляет карточку здания в избранное для пользователя.
   *
   * @param {BuildingCard} buildingCard - Карточка здания, добавляемая в избранное.
   * @return {Observable<AddFavorite>} - Обсервабл, который эмитирует результат добавления карточки здания в избранное.
   */
  addFavorite(buildingCard: BuildingCard): Observable<AddFavorite> {
    return this.httpClient.post<AddFavorite>(`${this.configService.getValue('WEB_ADMIN_URL')}/addFavorite`, {
      userId: this.#userId,
      favorite: buildingCard,
    });
  }

  /**
   * Удаляет действие из избранного из хранилища.
   *
   * @param {BuildingCard} favorite - Избранное, которое необходимо удалить.
   *
   * @return {void}
   */
  removeFavoriteAction(favorite: BuildingCard): void {
    this.store$.dispatch(removeFavorite({ favorite }));
  }

  /**
   * Удаляет избранное здание.
   *
   * @param {number} buildingId - ID здания, которое нужно удалить из избранного.
   * @return {Observable<RemoveFavorite>} - Обсервабл типа RemoveFavorite.
   */
  removeFavorite(buildingId: BuildingCard['buildingId']): Observable<RemoveFavorite> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: { userId: this.#userId, buildingId },
    };

    return this.httpClient.delete<RemoveFavorite>(`${this.configService.getValue('WEB_ADMIN_URL')}/deleteFavorite`, httpOptions);
  }

  /**
   * Заменяет избранное в хранилище на новое избранное.
   *
   * @param {BuildingCard[]} prevFavorites - Массив предыдущего избранного.
   * @param {BuildingCard[]} newFavorites - Массив нового избранного.
   * @return {void} - Этот метод ничего не возвращает.
   */
  replaceFavoritesAction(prevFavorites: BuildingCard[], newFavorites: BuildingCard[]): void {
    this.store$.dispatch(replaceFavorites({ prevFavorites, newFavorites }));
  }

  /**
   * Заменяет избранные карточки зданий пользователя на предоставленный список избранного.
   *
   * @param {BuildingCard[]} favorites - Новые избранные карточки зданий для замены существующих избранных у пользователя.
   * @return {Observable<ReplaceFavorites>} - Обсервабл, который эмитирует результат замены избранного.
   */
  replaceFavorites(favorites: BuildingCard[]): Observable<ReplaceFavorites> {
    return this.httpClient.post<ReplaceFavorites>(`${this.configService.getValue('WEB_ADMIN_URL')}/replaceFavorites`, {
      userId: this.#userId,
      favorites,
    });
  }
}
