@if (!isOSMCardActive()) {
  <header class="header header_border">
    <!-- TODO:  <header class="header" [class.header_border]="!additionData().imageBase64">-->
    <h2 class="header__title">
      <svg-icon class="header__title-icon" name="dots" [matMenuTriggerFor]="menu" />
      <mat-menu #menu="matMenu" class="object-card-menu" xPosition="before" yPosition="above">
        @for (menuGroup of objectCardMenu(); track $index) {
          <h2 class="object-card-menu__title" [textContent]="menuGroup.title" (click)="clickOnTitle($event)"></h2>
          @for (item of menuGroup.items; track $index) {
            <div
              class="object-card-menu__btn"
              [class.object-card-menu__btn_active]="item.isActive"
              (click)="selectMenuItem(item, menuGroup)"
            >
              <svg-icon class="object-card-menu__btn-icon" name="check" />
              <span class="object-card-menu__btn-text" [textContent]="item.text"></span>
            </div>
          }
        }
      </mat-menu>
      {{ additionData().title ?? objectCardTitle.ARCHITECTURALLY_SIGNIFICANT_OBJECTS }}
    </h2>
    <button blurAfterFocus class="header__close prevent-close-outside" type="button" (click)="close()">
      <svg-icon name="close" />
    </button>
  </header>
  <div class="info">
    @if (objectCardImageEnabled) {
      @if (imageUrl(); as imageUrl) {
        <div class="image-block">
          <img
            alt="Картинка для блока карточки объекта"
            class="info__img"
            i18n-alt="@@object-card.info.image-alt"
            loading="eager"
            [src]="imageUrl"
            (error)="objectCardImageError($event)"
            (load)="objectCardImageLoad()"
          />
          @if (!imageState().loaded) {
            <app-loading i18n="@@app.loading" type="animation" [isVisible]="true" />
          }
        </div>
      }
    }
    @if (additionData().name) {
      <div class="info__description">
        <svg-icon class="info__description-icon" [name]="additionData().icon" />
        {{ additionData().name }}
      </div>
    }
    <ul
      *ngIf="isActionsEnabled || (this.additionData().icon === 'objectCard' && favoritesLoadingStatus() === status.LOADED && buildingId())"
      class="actions"
    >
      @if (favoritesLoadingStatus() === status.LOADED && buildingId()) {
        <li class="actions__item">
          <button
            *ngrxLet="getFavoriteByBuildingId()(buildingId()) as favorite"
            blurAfterFocus
            class="action"
            type="button"
            [class.action_active]="favorite"
            (click)="favorite ? removeFavorite(favorite) : addFavorite()"
          >
            <span class="action__icon-block">
              <svg-icon class="action__icon" [name]="favorite ? 'favorites' : 'favoritesOutline'" />
            </span>
            <span class="action__text" i18n="@@object-card.actions.favourites">В избранное</span>
          </button>
        </li>
      }
      @if (additionData().hasDemoViewMode) {
        <li *appFeatureFlag="'OBJECT_CARD_PRESENTATION_ENABLED'" class="actions__item">
          <button blurAfterFocus class="action" type="button" (click)="openDemoView()">
            <span class="action__icon-block">
              <svg-icon class="action__icon" name="presentation" />
            </span>
            <span class="action__text" i18n="@@object-card.actions.presentation">Презентация</span>
          </button>
        </li>
      }
      <!--<li class="actions__item">
        <button blurAfterFocus class="action" type="button">
          <span class="action__icon-block">
            <svg-icon class="action__icon" name="findCd" />
          </span>
          <span class="action__text" i18n="@@object-card.actions.find-cd">Найти в ЦД</span>
        </button>
      </li>-->
      @if (additionData().hasBuildingsOptionIcon) {
        <li *appFeatureFlag="'BUILDINGS_OPTIONS'" class="actions__item">
          <button
            blurAfterFocus
            class="action"
            type="button"
            [class.action_active]="isCurrentBuildingOption2()"
            (click)="setBuildingsOption(!isCurrentBuildingOption2())"
          >
            <span class="action__icon-block">
              <svg-icon class="action__icon" name="onSurfaceVariant" />
            </span>
            <span class="action__text" i18n="@@object-card.actions.alt-building">Алт. вариант</span>
          </button>
        </li>
      }
    </ul>
    <ul class="info__list">
      @if (groupedProperties(); as groupedProperties) {
        @for (groupKey of propertyGroupKeysOrder; track $index) {
          <li>
            @if (groupedProperties[groupKey] && groupedProperties[groupKey].values.length) {
              <button
                blurAfterFocus
                class="group-btn"
                type="button"
                [id]="groupKey"
                (click)="toggleCollapsedGroup(groupedProperties[groupKey])"
              >
                <span class="group-btn__icon-block">
                  <svg-icon
                    class="group-btn__icon"
                    name="chevronRight"
                    [class.group-btn__icon_active]="!groupedProperties[groupKey].collapsed"
                  ></svg-icon>
                </span>
                <span
                  class="group-btn__text"
                  [textContent]="propertyGroup[groupedProperties[groupKey].type | uppercase | convertType]['text']"
                ></span>
              </button>
              <ul [@slideUpDown]="{ value: !groupedProperties[groupKey].collapsed, params: { startHeight: 0 } }" class="group">
                @for (property of groupedProperties[groupKey].values; track property.value) {
                  <li class="list-item" (click)="copyToClipboard(property)">
                    <h3 class="list-item__title">
                      <svg-icon class="list-item__icon" [name]="property.icon" />
                      <span [innerHTML]="property.text"></span>
                    </h3>
                    <p class="list-item__description" [textContent]="property.value"></p>
                    <button blurAfterFocus class="list-item__copy" type="button">
                      <svg-icon class="list-item__copy-icon" name="copy" />
                    </button>
                  </li>
                }
              </ul>
            }
          </li>
        }
      }
    </ul>
  </div>
} @else {
  <div class="tab-container">
    <button blurAfterFocus class="header__close prevent-close-outside" type="button" (click)="close()">
      <svg-icon name="close" />
    </button>
    <mat-tab-group dynamicHeight mat-align-tabs="center" mat-stretch-tabs="false" [selectedIndex]="selectedTabIndex">
      <mat-tab class="tab" [label]="tabs[0].label">
        <ng-container *ngTemplateOutlet="accident" />
      </mat-tab>
      <mat-tab class="tab" [label]="tabs[1].label">
        <ng-container *ngTemplateOutlet="object" />
      </mat-tab>
    </mat-tab-group>
  </div>
}

<ng-template #object>
  <div class="info">
    <div class="info__description padding">
      <button class="action-btn" type="button" (click)="openOSMDashboardCard()">
        <svg-icon name="ditMos"></svg-icon>
        <span i18n="@@object-card.info.button.object">Объединенная система мониторинга</span>
      </button>
    </div>
    <ul class="info__list">
      @if (osmObjectGroupedProperties(); as groupedProperties) {
        @for (groupKey of OSMObjectGroupKeysOrder; track $index) {
          <li>
            @if (groupedProperties[groupKey] && groupedProperties[groupKey].values.length) {
              <button
                blurAfterFocus
                class="group-btn"
                type="button"
                [id]="groupKey"
                (click)="toggleCollapsedOSMObjectGroup(groupedProperties[groupKey])"
              >
                <span class="group-btn__icon-block">
                  <svg-icon
                    class="group-btn__icon"
                    name="chevronRight"
                    [class.group-btn__icon_active]="!groupedProperties[groupKey].collapsed"
                  ></svg-icon>
                </span>
                <span
                  class="group-btn__text"
                  [textContent]="OSMObjectPropertyGroup[groupedProperties[groupKey].type | uppercase | convertType]['text']"
                ></span>
              </button>
              <ul [@slideUpDown]="{ value: !groupedProperties[groupKey].collapsed, params: { startHeight: 0 } }" class="group">
                @for (property of groupedProperties[groupKey].values; track $index) {
                  <li class="list-item" (click)="copyToClipboard(property)">
                    <h3 class="list-item__title">
                      <svg-icon class="list-item__icon" [name]="property.icon" />
                      <span [innerHTML]="property.text"></span>
                    </h3>
                    <p class="list-item__description" [textContent]="property.value"></p>
                    <button blurAfterFocus class="list-item__copy" type="button">
                      <svg-icon class="list-item__copy-icon" name="copy" />
                    </button>
                  </li>
                }
              </ul>
            }
          </li>
        }
      }
    </ul>
  </div>
</ng-template>

<ng-template #accident>
  <div class="info">
    @if (osmAdditionData()?.title) {
      <div class="info__description">
        <svg-icon class="info__description-icon" name="leadingIcon" />
        {{ osmAdditionData()?.title }}
      </div>
    }

    @if (photos().length) {
      <app-carousel class="carousel" [images]="photos()" />
    }

    <ul class="info__list">
      @if (osmIncidentGroupedProperties(); as groupedProperties) {
        @for (groupKey of OSMIncidentGroupKeysOrder; track $index) {
          <li>
            @if (groupedProperties[groupKey] && groupedProperties[groupKey].values.length && groupedProperties[groupKey].values[0].value) {
              <button
                blurAfterFocus
                class="group-btn"
                type="button"
                [id]="groupKey"
                (click)="toggleCollapsedOSMIncidentsGroup(groupedProperties[groupKey])"
              >
                <span class="group-btn__icon-block">
                  <svg-icon
                    class="group-btn__icon"
                    name="chevronRight"
                    [class.group-btn__icon_active]="!groupedProperties[groupKey].collapsed"
                  ></svg-icon>
                </span>
                <span
                  class="group-btn__text"
                  [textContent]="OSMIncidentPropertyGroup[groupedProperties[groupKey].type | uppercase | convertType]['text']"
                ></span>
              </button>

              <ul [@slideUpDown]="{ value: !groupedProperties[groupKey].collapsed, params: { startHeight: 0 } }" class="group">
                @for (property of groupedProperties[groupKey].values; track property.code) {
                  @if (property.code === 'relatedIncidents' && (property.value | isRelatedIncident)) {
                    <app-related-incidents [incidents]="property.value" (copyIncident)="copyToClipboard($event)" />
                  } @else {
                    <li class="list-item" (click)="copyToClipboard(property)">
                      <h3 class="list-item__title">
                        <svg-icon class="list-item__icon" [name]="property.icon" />
                        @if (property.text) {
                          <span [innerHTML]="property.text"></span>
                        }
                      </h3>
                      <p class="list-item__description" [textContent]="property.value"></p>
                      <button blurAfterFocus class="list-item__copy" type="button">
                        <svg-icon class="list-item__copy-icon" name="copy" />
                      </button>
                    </li>
                  }
                }
              </ul>
            }
          </li>
        }
      }
    </ul>
  </div>
</ng-template>
