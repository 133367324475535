import { ChangeDetectionStrategy, Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatTooltip } from '@angular/material/tooltip';
import { ofType } from '@ngrx/effects';
import { ActionsSubject } from '@ngrx/store';
import { delay, filter } from 'rxjs';

import { DELAY_TIME } from '../../constants/app.constant';
import { BlurAfterFocusDirective } from '../../directives/blur-after-focus/blur-after-focus.directive';
import { IconType } from '../../modules/svg-icon/generated-svg/svg-icons';
import { SvgIconModule } from '../../modules/svg-icon/svg-icon.module';
import { openLevelByName } from '../../modules/toolbar/levels/store/levels.actions';
import { BackendEventsService } from '../../services/backend-events.service';
import { LightToggleService } from './light-toggle.service';
import { LightButtonTooltipText, LightIconState, LightIconStateType, LightType } from './light.enum';

/**
 * Представляет компонент для кнопки-переключателя света.
 *
 * @class
 * @implements {OnInit}
 */
@Component({
  selector: 'app-light-toggle-button',
  standalone: true,
  imports: [SvgIconModule, MatTooltip, BlurAfterFocusDirective],
  templateUrl: './light-toggle-button.component.html',
  styleUrl: './light-toggle-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LightToggleButtonComponent implements OnInit {
  /**
   * Представляет тип света.
   *
   * @type {signal<Light>}
   */
  readonly lightType = this.lightToggleService.lightType.asReadonly();

  /**
   * Представляет объект Light.
   * @typedef {object} light
   */
  readonly light = LightType;

  /**
   * Представляет название иконки для конкретного дня.
   *
   * @typedef {'sun' | 'sunFilled'} IconType - Доступные типы иконок.
   * @typedef Extract<IconType, 'sun' | 'sunFilled'> - Ограниченное подмножество IconType.
   * @type {Extract<IconType, 'sun' | 'sunFilled'>} - Имя иконки для текущего дня.
   */
  dayIconName: Extract<IconType, 'sun' | 'sunFilled'> = 'sun';

  /**
   * Представляет название иконки для ночи.
   *
   * @type {Extract<IconType, 'moon' | 'moonFilled'>}
   * @default 'moon'
   */
  nightIconName: Extract<IconType, 'moon' | 'moonFilled'> = 'moon';

  /**
   * Переменная, представляющая внедрённую зависимость 'DestroyRef'.
   *
   * @type {DestroyRef}
   */
  readonly #destroyRef = inject(DestroyRef);

  /**
   * Текст подсказки для кнопки света.
   *
   * @type {string}
   * @name LightButtonTooltipText
   * @memberOf module:Constants
   */
  readonly LightButtonTooltipText = LightButtonTooltipText;

  /**
   * Конструктор класса.
   *
   * @param {BackendEventsService} backendEventsService - Сервис для работы с событиями бэкенда.
   * @param {ActionsSubject} actionsSubject - Подписчик на действия.
   * @param {LightToggleService} lightToggleService - Сервис для переключения света.
   */
  constructor(
    private backendEventsService: BackendEventsService,
    private actionsSubject: ActionsSubject,
    private lightToggleService: LightToggleService,
  ) {}

  /**
   * Инициализирует компонент.
   * Данный метод вызывается Angular'ом после инициализации компонента.
   *
   * @return {void}
   */
  ngOnInit(): void {
    this.backendEventsService
      .getLightType()
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((lightType) => {
        if (lightType === LightType.NO_LIGHT || lightType === LightType.NIGHT_LIGHT) {
          this.lightToggleService.setLightType(lightType);
        }
      });
    this.switchLight2DayAfterChangeLocation();
  }

  /**
   * Переключает свет вкл/выкл и обновляет тип света.
   *
   * @return {void}
   */
  lightToggle(): void {
    this.lightToggleService.updateLightType(this.lightType());
    this.animateHover();
    this.backendEventsService.setLightType(this.lightType()).pipe(takeUntilDestroyed(this.#destroyRef)).subscribe();
  }

  /**
   * Устанавливает иконки в зависимости от заданного состояния.
   *
   * @param {LightIconStateType} iconsState - Состояние иконок.
   *                                          Возможные значения: LightIconState.FILLED или LightIconState.NON_FILLED.
   * @throws {Error} Если состояние иконок не валидно.
   * @returns {void}
   */
  setIcons(iconsState: LightIconStateType): void {
    if (iconsState === LightIconState.FILLED) {
      this.nightIconName = 'moonFilled';
      this.dayIconName = 'sunFilled';
    } else if (iconsState === LightIconState.NON_FILLED) {
      this.nightIconName = 'moon';
      this.dayIconName = 'sun';
    } else {
      throw new Error('Invalid light icon state');
    }
  }

  /**
   * Анимирует переключение света между ночью и днем.
   *
   * @returns {void}
   */
  private animateHover(): void {
    this.dayIconName = 'sunFilled';
    this.nightIconName = 'moonFilled';
  }

  /**
   * Переключает свет на дневной режим после изменения новой локации.
   * @returns {void}
   */
  private switchLight2DayAfterChangeLocation(): void {
    this.actionsSubject
      .pipe(
        ofType(openLevelByName),
        filter(() => this.lightType() === LightType.NIGHT_LIGHT),
        delay(DELAY_TIME),
        takeUntilDestroyed(this.#destroyRef),
      )
      .subscribe(() => this.lightToggleService.setLightType(LightType.NO_LIGHT));
  }
}
