import { Routes } from '@angular/router';

import { TOOLBAR_ROUTES } from './modules/toolbar/toolbar.routes';

// https://www.angulararchitects.io/en/blog/routing-and-lazy-loading-with-standalone-components/
export const APP_ROUTES: Routes = [
  ...TOOLBAR_ROUTES,
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];
