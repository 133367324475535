import { Injectable, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Doc, ShortSearchResult, isBuildingSearchResult } from '../modules/toolbar/search/search.interface';
import { Tuple } from '../types/tuple.type';
import { isEmpty } from '../utils/is-empty.util';

/**
 * Сервис для обработки параметров запроса данных в URL.
 * @class
 * @constructor
 */
@Injectable({
  providedIn: 'root',
})
export class DataUrlQueryParamService {
  /**
   * Содержит ссылку на объект активного маршрута, внедренного через внедрение зависимостей.
   * `ActivatedRoute` предоставляет доступ к информации о маршруте, связанной с компонентом.
   *
   * @type {ActivatedRoute}
   */
  #activatedRoute = inject(ActivatedRoute);

  /**
   * Внедренный экземпляр класса Router.
   *
   * @type {Router}
   */
  #router = inject(Router);

  /**
   * Обновляет параметр данных в параметрах запроса текущего маршрута и переходит на обновленный маршрут.
   * @param {string} data - Новое значение для параметра данных.
   * @return {void}
   */
  updateDataQueryParam(data: string): void {
    const { q, data: qData, coords, ...qp } = this.#activatedRoute.snapshot.queryParams;
    const queryParams = { ...qp, ...(data && { data }) };
    this.#router.navigate([''], { queryParams: isEmpty(queryParams) ? null : queryParams });
  }

  /**
   * Преобразует данный объект Doc в строковый хеш.
   *
   * @param {Doc} place - Объект Doc, который нужно преобразовать.
   * @return {string} Преобразованная url строка.
   * @throws Exception если во время процесса кодирования возникла ошибка.
   */
  encodeUrl(place: Doc): string {
    try {
      return isBuildingSearchResult(place)
        ? `${place.buildingId ? '|buildingId-' + place.buildingId : ''}`
        : `${place.center ? JSON.parse(place.center).coordinates.join(',') : ''}${place.name ? '|name-' + place.name : ''}${place.address ? '|address-' + place.address : ''}`;
    } catch (error) {
      console.error('Search encodeUrl error', error);
      throw error;
    }
  }

  /**
   * Декодирует хеш в объект данных который будет отправлен на UE для совершения телепорта камеры
   *
   * @param {string} data - Url для декодирования в данные.
   * @returns {ShortSearchResult} - Декодированный объект ShortSearchResult.
   */
  decodeUrl(data: string): ShortSearchResult & { buildingId?: string } {
    try {
      const { coordinates, name, address, buildingId } = this.validateUrlDataString(data);

      return {
        ...(coordinates && {
          center: JSON.stringify({ type: 'Point', coordinates }),
        }),
        ...(name && { name }),
        ...(address && { address }),
        ...(buildingId && { buildingId }),
      };
    } catch (error) {
      console.error('Search decodeUrl error', error);
      throw error;
    }
  }

  /**
   * Проверяет строку данных в URL.
   * Строка данных должна содержать centerCoordinates, name, и address, разделенные "|".
   *
   * @param {string} data - Проверяемая строка данных.
   * @throws {Error} Если строка данных не в правильном формате.
   * @return {Object} Проверенный объект данных, содержащий координаты, имя и адрес.
   */
  private validateUrlDataString(data: string): {
    coordinates?: Tuple<number, 3>;
    name: string;
    address: string;
    buildingId?: string;
  } {
    const split = data.split('|').filter((val) => !!val);

    if (!(split.length >= 1 && split.length <= 4)) {
      throw new Error('Wrong data in url: ' + data);
    }

    let centerCoordinates;
    let str1, str2;

    data.includes('buildingId') ? ([str1] = split) : ([centerCoordinates, str1, str2] = split);

    let name = '';
    let address = '';
    let buildingId = '';

    if (str1?.startsWith('name-')) {
      name = str1.replace('name-', '');
    }

    if (str1?.startsWith('address-')) {
      address = str1.replace('address-', '');
    }

    if (str1?.startsWith('buildingId-')) {
      buildingId = str1.replace('buildingId-', '');
    }

    if (str2?.startsWith('address-')) {
      address = str2.replace('address-', '');
    }

    let coordinates;

    if (centerCoordinates) {
      const centerCoordinatesData = centerCoordinates.split(',');

      if (centerCoordinatesData.length !== 3 || !centerCoordinatesData.every((item) => /^[+-]?\d+(\.\d+)?$/.test(item))) {
        throw new Error('Wrong centerCoordinates in url: ' + centerCoordinates);
      }

      coordinates = centerCoordinatesData.map((item) => +item).slice(0, 3) as Tuple<number, 3>;
    }

    return { coordinates, name, address, buildingId };
  }
}
