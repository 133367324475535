import { DestroyRef, Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter, fromEvent, map, tap } from 'rxjs';
import { share } from 'rxjs/operators';

import { WINDOW } from '../../tokens/window.token';
import { generateGUID } from '../../utils/uuid.util';
import { OsmPostMessageData } from './osm.interface';

@Injectable({
  providedIn: 'root',
})
export class OsmIframeService {
  #window = inject(WINDOW);
  #OsmWindow: MessageEventSource | null = null;
  #postMessage$ = fromEvent<MessageEvent<OsmPostMessageData>>(this.#window, 'message').pipe(
    filter((event) => !!event.data.jsonrpc),
    tap((event) => console.info('PostMessage method: ', { method: event.data.method, params: event.data.params })),
    share(),
  );

  openOsmCard$ = this.#postMessage$.pipe(
    filter((event) => event.data.method === 'openOsmCard'),
    map((event) => event.data),
  );

  deactivateOsmMode$ = this.#postMessage$.pipe(
    filter((event) => event.data.method === 'deactivateOsmMode'),
    map((event) => event.data),
  );

  destroyRef = inject(DestroyRef);

  init(): Promise<void> {
    return new Promise((resolve) => {
      this.#postMessage$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((event) => {
        if (event.source) {
          this.#OsmWindow = event.source;
        } else {
          console.warn('Message event has no source:', event);
        }
      });
      resolve();
    });
  }

  openDashboardCard(id: string): void {
    if (!this.#OsmWindow) {
      console.error('No Parent IFrame Window object');
      return;
    }

    this.#OsmWindow?.postMessage(
      {
        jsonrpc: '2.0',
        method: 'openDashboardCard',
        params: {
          id,
          type: 'incident',
        },
        id: generateGUID(),
      },
      { targetOrigin: '*' },
    );
  }
}
