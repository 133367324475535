import { createFeatureSelector, createSelector } from '@ngrx/store';

import { BuildingCard } from '../favorites.interface';
import { FavouritesState, adapter } from './favorites.reducer';

const { selectAll } = adapter.getSelectors();

export const selectFavouritesState = createFeatureSelector<FavouritesState>('favorites');
export const selectFavourites = createSelector(selectFavouritesState, selectAll);
export const selectFavoriteByBuildingId = createSelector(
  selectFavourites,
  (favorites: BuildingCard[]) =>
    (buildingId?: BuildingCard['buildingId']): BuildingCard | undefined =>
      favorites.length ? favorites.find((item) => item.buildingId === buildingId) : undefined,
);
export const selectFavouritesLoadingStatus = createSelector(selectFavouritesState, (state) => state.status);
