import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, signal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import type { DialogData } from '../../carousel.interface';

@Component({
  selector: 'app-carousel-modal',
  templateUrl: './carousel-modal.component.html',
  styleUrl: './carousel-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselModalComponent implements AfterViewInit {
  visible = signal(false);

  constructor(
    private dialogRef: MatDialogRef<CarouselModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {}

  ngAfterViewInit(): void {
    this.visible.set(true);
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
