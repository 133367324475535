import { Attribute, DestroyRef, Directive, ElementRef, EventEmitter, Input, Output, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter } from 'rxjs';

import { EventService } from '../../services/event.service';

/**
 * Директива для обнаружения событий клика за пределами элемента.
 *
 * @Directive({selector: '[appClickOutside]'})
 */
@Directive({ selector: '[appClickOutside]' })
export class ClickOutsideDirective {
  /**
   * Включено ли поведение при клике за пределами или нет.
   *
   * @type {boolean}
   */
  @Input() clickOutsideDisabled = false;
  /**
   * Переменная clickOutside - это EventEmitter, который используется для вызова события, когда клик происходит за пределами определенного элемента.
   *
   * @event clickOutside.emit - Это событие вызывается, когда клик происходит за пределами определенного элемента.
   */
  @Output() clickOutside = new EventEmitter<Event>();
  /**
   * Максимальное значение глубины.
   *
   * @type {number}
   */
  readonly #maxDepth = 15;
  /**
   * Представляет переменную `destroyRef`.
   *
   * @typedef {DestroyRef} destroyRef
   * @description Эта переменная инжектируется с зависимостью `DestroyRef`.
   *              Она может использоваться для управления уничтожением ссылок.
   */
  readonly #destroyRef = inject(DestroyRef);
  /**
   * Метод конструктора для класса.
   *
   * @param {ElementRef} elementRef - Экземпляр ElementRef для компонента.
   * @param {EventService} eventService - Экземпляр EventService для обработки событий.
   * @param {string} ignoreClass - CSS-класс для игнорирования при проверке события клика за пределами.
   */
  constructor(
    private elementRef: ElementRef,
    private eventService: EventService,
    @Attribute('ignoreClass') private ignoreClass: string,
  ) {
    this.eventService.mouseUp$
      .pipe(
        filter(() => !this.clickOutsideDisabled),
        takeUntilDestroyed(this.#destroyRef),
      )
      .subscribe((mouseEvent) => {
        const clickedInside =
          this.elementRef.nativeElement?.contains(mouseEvent.target) ||
          (!!this.ignoreClass && this.hasParentClass((mouseEvent.target as HTMLElement).parentNode as HTMLElement));
        !clickedInside && this.clickOutside.emit(mouseEvent);
      });
  }

  /**
   * Проверяет, есть ли у данного элемента родительский класс.
   * @param {HTMLElement} target - Цельевой элемент для проверки на наличие родительского класса.
   * @param {number} [depth=0] - Глубина иерархии родителей для обхода (по умолчанию: 0).
   * @return {boolean} - Возвращает true, если цель или любой из его родительских элементов имеют указанный класс, иначе false.
   */
  private hasParentClass(target: HTMLElement, depth = 0): boolean {
    if (depth >= this.#maxDepth || !target) {
      return false;
    }
    return target.classList?.contains(this.ignoreClass) ? true : this.hasParentClass(target.parentNode as HTMLElement, ++depth);
  }
}
