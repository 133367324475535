import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, of, switchMap } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { LayersService } from '../layers.service';
import { getLayers, getLayersSuccess, setLayers, setLayersSuccess, setLayersWithRequest, toggleHybridEnabled } from './layers.actions';

/**
 * Внедряемый класс, который определяет эффекты для слоев.
 */
@Injectable()
export class LayersEffects {
  /**
   * Переменная, представляющая эффект для получения слоев.
   *
   * @type {Effect}
   * @name getLayers$
   *
   * @return {Observable} Наблюдаемый, который выдает действия для получения слоев.
   *
   * @example
   * getLayers$.subscribe(action => {});
   */
  getLayers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getLayers),
      switchMap(() => this.layersService.getLayers().pipe(map((layers) => getLayersSuccess({ layers })))),
    );
  });

  /**
   * Переключает включенное гибридное состояние слоев.
   *
   * @function toggleHybridEnabled$
   * @returns {Observable<Action>} Возвращает наблюдаемый, который выдает действия при переключении гибридного включенного состояния.
   *
   * @example
   * toggleHybridEnabled$();
   */
  toggleHybridEnabled$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(toggleHybridEnabled),
      filter(() => !this.layersService.hybridEnabled() && !!this.layersService.enabledLayers().length),
      map(() => setLayers({ layers: this.layersService.enabledLayers().map((layer) => ({ ...layer, isEnabled: false })) })),
    );
  });

  /**
   * setLayers$ - переменная, представляющая эффект RxJS, который обрабатывает действие setLayers.
   * Когда действие setLayers задействовано, этот эффект активизируется.
   * Он прослушивает действие setLayers с использованием оператора ofType из ngRx, а затем выполняет необходимые операции.
   *
   * @typedef {Observable<Action>} SetLayersEffect
   */
  setLayers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(setLayers),
      mergeMap(({ layers, prevLayers = [] }) => {
        return this.layersService.toggleLayers(layers).pipe(
          catchError((error) => {
            error.message !== 'LayersEffects message from specs' && console.error('setLayers error', error);
            return of({ error });
          }),
          mergeMap((data) => {
            if (Array.isArray(data)) {
              return [setLayersWithRequest({ layers: prevLayers }), setLayersSuccess({ layersState: data })];
            } else {
              return [setLayersSuccess({ layersState: prevLayers.map(({ guid, isEnabled }) => ({ guid, isEnabled })) })];
            }
          }),
        );
      }),
    );
  });

  /**
   * Конструктор для класса.
   *
   * @param {Actions} actions$ - Сервис действий.
   * @param {LayersService} layersService - Сервис слоев.
   */
  constructor(
    private actions$: Actions,
    private layersService: LayersService,
  ) {}
}
