import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
  computed,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject, EMPTY, Observable, catchError, combineLatest, delay, filter, switchMap, take } from 'rxjs';

import { environment } from '../environments/environment';
import { ObjectCardService } from './components/object-card/object-card.service';
import { Status } from './enums/status.enum';
import { ConfigKeys, FeatureFlagService } from './modules/feature-flag/feature-flag.service';
import { OsmCardService } from './modules/osm/osm-card.service';
import { OsmIframeService } from './modules/osm/osm-iframe.service';
import { BackendEventsService } from './services/backend-events.service';
import { FrontendEventsService } from './services/frontend-events.service';
import { PixelStreamingService } from './services/pixelstreaming.service';
import { PreloaderService } from './services/preloader.service';
import { ThemeService } from './services/theme.service';
import { WINDOW } from './tokens/window.token';

/**
 * Класс AppComponent представляет корневой компонент приложения.
 * Он отвечает за инициализацию приложения, управление состоянием аутентификации пользователя,
 * а также подписку на различные события от PixelStreamingService.
 *
 * @component
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  /**
   * Устанавливает потоковый сервис пикселей вручную показать прелоадер
   * @type {Observable<boolean>}
   */
  manuallyShowPreloader$ = this.pixelStreamingService.manuallyShowPreloader$;

  /**
   * Представляет ссылку на элемент-контейнер для видео.
   *
   * @typedef {ElementRef<HTMLElement>} VideoContainer
   * @property {HTMLElement} nativeElement - Соответствующий элемент HTML для контейнера видео.
   */
  @ViewChild('videoContainer', { static: false }) videoContainer?: ElementRef<HTMLElement>;

  /**
   * Переменная isPreloaderVisible$ - это Observable, которая представляет собой статус видимости прелоадера.
   *
   * @type {Observable<boolean>}
   * @name isPreloaderVisible$
   * @memberof [Component/Service Name]
   */
  readonly isPreloaderVisible$: Observable<boolean> = this.preloaderService.isVisible$;

  /**
   * Представляет статус системы или процесса.
   *
   * @class
   * @constructor
   */
  readonly status = Status;

  /**
   * Определяет, является ли текущая страница корневой страницей.
   *
   * @returns {boolean} Верно, если текущая страница является корневой страницей, иначе неверно.
   */
  get showIntoPage(): boolean {
    const pathname = this.window.location.pathname;

    if (!environment.hasIntoPage || (pathname === '/' && this.window.location.href.includes('?mode'))) {
      return false;
    }

    if (pathname !== '/') {
      return !this.featureFlagService.isFeatureOn(pathname.replace('/', '').toUpperCase() as ConfigKeys);
    }

    return pathname === '/' && !this.window.location.href.includes('?data=');
  }

  /**
   * Определяет, видима ли карточка объекта.
   *
   * @функция
   * @returns {boolean} - Верно, если карточка объекта видна, иначе неверно.
   */
  isObjectCardVisible = computed(
    () =>
      !!this.objectCardService.groupedProperties() ||
      !!(this.osmCardService.osmObjectGroupedProperties() && this.osmCardService.osmIncidentGroupedProperties()),
  );

  /**
   * RxJS Observable, представляющий состояние возможности перетаскивания картографического изображения.
   *
   * @type {Observable<boolean>}
   */
  cartographicDragEnabled$ = this.frontendEventsService.cartographicDragEnabled$;
  /**
   * Представляет статус готовности потока.
   *
   * @returns {Observable<boolean>} - An observable that emits a boolean value indicating whether the stream is ready or not.
   */
  readonly isStreamReady$ = this.pixelStreamingService.isStreamReady$;
  /**
   * Представляет Observable поток видимости элемента клика для воспроизведения.
   *
   * @type {BehaviorSubject<boolean>}
   */
  readonly clickToPlayVisible$ = new BehaviorSubject(true);
  /**
   * Представляет текущее состояние входа пользователя в систему.
   *
   * @type {BehaviorSubject<boolean>}
   */
  readonly loggedIn$ = new BehaviorSubject(false);
  /**
   * Представляет собой BehaviorSubject RxJS, который отслеживает состояние загрузки роутера.
   *
   * @class
   * @name routerIsLoaded$
   * @type {BehaviorSubject}
   *
   * @property {boolean} value - Текущее состояние загрузки роутера.
   *
   * @example
   * // Создание нового BehaviorSubject со стартовым состоянием загрузки false
   * const routerIsLoaded$ = new BehaviorSubject(false);
   *
   * // Подписка на routerIsLoaded$ для получения уведомлений при изменении состояния загрузки
   *
   * // Обновление состояния загрузки на true
   * routerIsLoaded$.next(true);
   */
  readonly routerIsLoaded$ = new BehaviorSubject(false);

  /**
   * Проверяет, имеет ли текущее окружение страницу "into".
   *
   * @param {object} environment - Объект окружения, который содержит свойство "hasIntoPage".
   * @return {boolean} - Возвращает true, если текущее окружение имеет страницу "into", в противном случае - false.
   */
  canShowIntoPage = this.showIntoPage;

  /**
   * Внедренный экземпляр DestroyRef.
   *
   * @type {DestroyRef}
   */
  readonly #destroyRef = inject(DestroyRef);

  /**
   * Constructs a new instance of the class.
   * @param {Window} window - Объект window, внедренный в конструктор.
   * @param {Title} titleService - Сервис title, внедренный в конструктор.
   * @param {PixelStreamingService} pixelStreamingService - Сервис pixelStreaming, внедренный в конструктор.
   * @param {ObjectCardService} objectCardService - Сервис objectCard, внедренный в конструктор.
   * @param {FeatureFlagService} featureFlagService - Сервис featureFlag, внедренный в конструктор.
   * @param {ChangeDetectorRef} cdRef - Ссылка на детектор изменений, внедренная в конструктор.
   * @param {PreloaderService} preloaderService - Сервис предварительного загрузчика.
   * @param {ThemeService} themeService - Сервис смены темы на сайте.
   * @param {FrontendEventsService} frontendEventsService - Сервис для обработки событий фронтенда, связанных с функциональностью потоковой передачи пикселей.
   */
  constructor(
    @Inject(WINDOW) private window: Window,
    private titleService: Title,
    private pixelStreamingService: PixelStreamingService,
    private objectCardService: ObjectCardService,
    private featureFlagService: FeatureFlagService,
    private cdRef: ChangeDetectorRef,
    private preloaderService: PreloaderService,
    private themeService: ThemeService,
    private frontendEventsService: FrontendEventsService,
    private backendEventsService: BackendEventsService,
    private osmCardService: OsmCardService,
    private osmIframeService: OsmIframeService,
  ) {}

  /**
   * Инициализирует компонент, дожидается готовности компонента к вызову методом установки title сайта и установки темы сайта на основе
   * данных из localStorage или tесли данных там нет то по умолчанию ставиться светлая тема
   */
  ngOnInit(): void {
    this.titleService.setTitle(this.window.location.host);
    this.themeService.setThemeInitialValue();
    this.enableUEManagesEvents();
    this.enableOpenOsmCard();
    this.enableDeactivateOsmMode();
  }

  /**
   * Обновляет статус входа в систему и выполняет необходимые действия.
   *
   * @param {boolean} loggedIn - Новый статус входа в систему.
   * @returns {void}
   */
  loggedInChange(loggedIn: boolean): void {
    if (this.loggedIn$.value !== loggedIn) {
      this.loggedIn$.next(loggedIn);
      this.cdRef.detectChanges();

      if (loggedIn) {
        if (this.videoContainer) {
          this.pixelStreamingService.init(this.videoContainer.nativeElement);
          combineLatest([this.isStreamReady$, this.routerIsLoaded$.pipe(filter((value) => value))])
            .pipe(take(1), takeUntilDestroyed(this.#destroyRef))
            .subscribe(() => this.frontendEventsService.initEvents());
        } else {
          console.error('No videoContainer found');
        }
      }
    }
  }

  /**
   * Метод для изменения видимости "нажать, чтобы играть". Этот метод обновляет значение
   * субъекта clickToPlayVisible$.
   *
   * @param {boolean} value - Новое значение видимости "нажать, чтобы играть".
   * @return {void}
   */
  clickToPlayVisibleChange(value: false): void {
    this.clickToPlayVisible$.next(value);
  }

  /**
   * Активирует маршрут, излучая булево значение, чтобы указать, что
   * роутер загружен.
   *
   * @returns {void}
   */
  activateRoute(): void {
    this.routerIsLoaded$.next(true);
  }

  /**
   * Включить UE-вводы.
   * Этот метод включает ввод пользователя для Unreal Engine.
   *
   * @private
   * @returns {void}
   */
  private enableUEManagesEvents(): void {
    this.isStreamReady$.pipe(take(1), delay(0), takeUntilDestroyed(this.#destroyRef)).subscribe(() => {
      !this.showIntoPage && this.window.location.pathname !== '/search' && this.pixelStreamingService.setInputState(true);
    });
  }

  private enableOpenOsmCard(): void {
    this.osmIframeService.openOsmCard$
      .pipe(
        switchMap((data) =>
          this.backendEventsService.activateOsmIncident({ type: data.params.type, id: data.params.id }).pipe(
            catchError((err) => {
              console.error('activateOsmIncident error: ', err);
              return EMPTY;
            }),
          ),
        ),
        takeUntilDestroyed(this.#destroyRef),
      )
      .subscribe();
  }

  private enableDeactivateOsmMode(): void {
    this.osmIframeService.deactivateOsmMode$
      .pipe(
        switchMap(() =>
          this.backendEventsService.deactivateOsmMode().pipe(
            catchError((err) => {
              console.error('deactivateOsmMode error: ', err);
              return EMPTY;
            }),
          ),
        ),
        takeUntilDestroyed(this.#destroyRef),
      )
      .subscribe();
  }
}
