import { createAction, props } from '@ngrx/store';

import { Layer, LayerState } from '../layers.interface';

/**
 * Действие: Получить Слои
 *
 * Описание: Создает действие для запроса слоев.
 *
 * @type {string}
 * @memberof module:actions
 */
export const getLayers = createAction('[LAYERS] Получить слои');

/**
 * Действие для обработки успешного ответа на получение слоев.
 *
 * @param {Layer[]} layers - Массив слоев, полученных от сервера.
 *  @returns {Action} - Объект действия с полезной нагрузкой слоев.
 */
export const getLayersSuccess = createAction('[LAYERS] Успешное получение слоев', props<{ layers: Layer[] }>());

/**
 * Переключает состояние "гибрид включен" для слоев.
 *
 * @type {string}
 * @name toggleHybridEnabled
 * @memberOf Actions
 * @description Это действие используется для переключения состояния "гибрид включен" для слоев.
 *
 * @example
 * // Вызов действия
 * dispatch(toggleHybridEnabled);
 */
export const toggleHybridEnabled = createAction('[LAYERS] Переключить гибрид включен');

/**
 * Создает действие для установки слоев.
 *
 * @param {Layer[]} layers - Массив слоев для установки.
 * @param {Layer[]} [prevLayers] - Дополнительный массив предыдущих слоев.
 * @returns {Action} Действие установки слоев.
 *
 * @example
 * const layers = [
 *   { name: 'Слой 1', active: true },
 *   { name: 'Слой 2', active: false }
 * ];
 * const prevLayers = [
 *   { name: 'Слой А', active: true },
 *   { name: 'Слой B', active: false }
 * ];
 * const action = setLayers({ layers, prevLayers });
 */
export const setLayers = createAction('[LAYERS] Установить слои', props<{ layers: Layer[]; prevLayers?: Layer[] }>());

/**
 * Устанавливает слои без выполнения запроса.
 *
 * @param layers Массив слоев для установки.
 * @returns Действие, которое устанавливает слои без выполнения запроса.
 */
export const setLayersWithRequest = createAction('[LAYERS] Установка слоев без запроса', props<{ layers: Layer[] }>());

/**
 * Создает действие для успешной установки слоев.
 *
 * @param {LayerState[]} layersState - Массив состояния слоя.
 * @returns {Action} - Действие успешной установки слоев.
 */
export const setLayersSuccess = createAction('[LAYERS] Успех установки слоев', props<{ layersState: LayerState[] }>());
