import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, catchError, of, tap } from 'rxjs';

import { Credentials } from './auth-creds.type';

/**
 * Класс AuthCredsService отвечает за загрузку и управление учетными данными аутентификации.
 *
 * @remarks
 * Этот класс предоставляет методы для загрузки учетных данных из JSON файла и установки их в качестве текущих учетных данных.
 * Он также обрабатывает ошибки при загрузке учетных данных и отображает соответствующие сообщения об ошибках.
 *
 * @public
 * @remarks
 * Этот класс может быть внедрен в качестве зависимости в другие классы с использованием декоратора @Injectable().
 */
@Injectable()
export class AuthCredsService {
  /**
   * Представляет данные для учетных данных аутентификации.
   * @typedef {Object} УчетныеДанные
   * @property {string} username - Имя пользователя для аутентификации.
   * @property {string} password - Пароль для аутентификации.
   */
  credentials?: Credentials;

  /**
   * Создает экземпляр конструктора.
   *
   * @param {HttpClient} httpClient - Экземпляр HttpClient, используемый для отправки HTTP-запросов.
   * @param {MatSnackBar} matSnackBar - Экземпляр MatSnackBar, используемый для отображения уведомлений в виде снэк-бара.
   */
  constructor(
    private httpClient: HttpClient,
    private matSnackBar: MatSnackBar,
  ) {}

  /**
   * Загружает учетные данные из файла auth.json.
   *
   * @returns {Observable<Credentials | undefined>} Observable, который выдает загруженные учетные данные или undefined, если произошла ошибка.
   */
  loadCredentials(): Observable<Credentials | undefined> {
    const uniqueParam = new Date().getTime();
    const url = `./config/auth.json?cacheBuster=${uniqueParam}`;

    return this.httpClient.get<Credentials | undefined>(url).pipe(
      tap((credentials) => {
        this.setCredentials(credentials);
      }),
      catchError((error) => {
        console.error('load credentials error ', error);
        this.loadCredentialsErrorSnackBar(error.status);

        return of(undefined);
      }),
    );
  }

  /**
   * Устанавливает учетные данные для аутентификации.
   *
   * @param {Credentials | undefined} credentials - Установка учетных данных. Передайте undefined для удаления учетных данных.
   * @return {void}
   */
  private setCredentials(credentials: Credentials | undefined): void {
    this.credentials = credentials;
  }

  /**
   * Отображает снэк-бар с сообщением об ошибке на основе данного кода статуса.
   *
   * @param {number} status - Код статуса, возвращенный сервером.
   * @return {void}
   */
  private loadCredentialsErrorSnackBar(status: number): void {
    this.matSnackBar.open(
      status === 429
        ? $localize`:@@auth-cred.error-429-snack-bar:Слишком частые запросы, пожалуйста попробуйте через несколько минут`
        : $localize`:@@auth-cred.error-other-snack-bar:Ошибка сервера, пожалуйста попробуйте через несколько минут`,
      undefined,
      {
        duration: 0,
        panelClass: 'error',
      },
    );
  }
}
