import { computed, inject } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { catchError, filter, of, pipe, tap } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { Status } from '../../enums/status.enum';
import { BackendEventsService } from '../../services/backend-events.service';
import { ControlMode, SetControlMode } from './control-modes.interface';

type ControlModesState = {
  controlMode: ControlMode;
  prevActiveNonMovementType: 'cartographic' | 'direct';
  status: Status;
};

/**
 * Представляет начальное состояние модели управления.
 *
 * @typedef {Object} ControlModelState
 * @property {Object} controlMode - Информация о режиме управления.
 * @property {string} controlMode.type - Тип режима управления.
 * @property {string} controlMode.mode - Режим режима управления.
 * @property {Status} status - Статус модели управления.
 */
const initialState: ControlModesState = {
  controlMode: { type: 'cartographic', mode: 'none', modifier: 'none' },
  prevActiveNonMovementType: 'cartographic',
  status: Status.UNINITIALIZED,
};

/**
 * Переменная ControlModelStore представляет собой хранилище, которое управляет состоянием модели управления
 * @param {Object} options - Опции для хранилища.
 * @param {string} options.providedIn - Предоставлено в корневом модуле.
 * @param {Function} devtools - Функция для отладки.
 * @param {Object} initialState - Начальное состояние хранилища.
 * @param {Object} controlMode - Режим управления.
 * @returns {Object} - Объект с методами для управления моделью.
 */
export const ControlModesStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withComputed(({ status, controlMode }) => ({
    isSaving: computed(() => status() === Status.SAVING),
    isUninitialized: computed(() => status() === Status.UNINITIALIZED),
    isHidden: computed(() => controlMode().type === 'direct' && controlMode().mode === 'freecam' && controlMode().modifier === 'demoView'),
    isMovementMode: computed(
      () => controlMode().type === 'direct' && (controlMode().mode === 'character' || controlMode().mode === 'vehicle'),
    ),
  })),
  withMethods((store, backendEventsService = inject(BackendEventsService)) => ({
    getControlMode: rxMethod<void>(
      pipe(
        mergeMap(() => backendEventsService.getControlMode()),
        catchError((err) => {
          patchState(store, { status: Status.UNINITIALIZED });
          return of(err);
        }),
        mergeMap((controlMode) => {
          patchState(store, { status: Status.LOADING });

          if (controlMode.type === 'cartographic' && controlMode.modifier === 'actorPlacementPrompt') {
            return backendEventsService.setControlMode({ type: 'cartographic', mode: 'none', modifier: 'none' }).pipe(
              tapResponse({
                next: () => {
                  patchState(store, {
                    controlMode: { type: 'cartographic', mode: 'none', modifier: 'none' },
                    status: Status.LOADED,
                    prevActiveNonMovementType: 'cartographic',
                  });
                },
                error: (err) => {
                  console.error('getControlMode error', err);
                  return patchState(store, { status: Status.UNINITIALIZED });
                },
              }),
            );
          }

          if (controlMode.mode === 'freecam' && controlMode.modifier === 'actorPlacementPrompt') {
            return backendEventsService.setControlMode({ type: 'direct', mode: 'freecam', modifier: 'none' }).pipe(
              tapResponse({
                next: () => {
                  patchState(store, {
                    controlMode: { type: 'direct', mode: 'freecam', modifier: 'none' },
                    status: Status.LOADED,
                    prevActiveNonMovementType: 'direct',
                  });
                },
                error: (err) => {
                  console.error('getControlMode error', err);
                  return patchState(store, { status: Status.UNINITIALIZED });
                },
              }),
            );
          }

          patchState(store, {
            controlMode,
            status: Status.LOADED,
            prevActiveNonMovementType:
              controlMode.mode === 'character' || controlMode.mode === 'vehicle' ? 'cartographic' : controlMode.type,
          });

          return of({ success: true });
        }),
      ),
    ),
    exitCharacterPlacementMode: rxMethod<void>(
      pipe(
        mergeMap(() => {
          patchState(store, { status: Status.SAVING });

          return backendEventsService.exitCharacterPlacementMode().pipe(
            tapResponse({
              next: () => {
                patchState(store, { status: Status.LOADED });
              },
              error: (err) => {
                console.error('exitCharacterPlacementMode error', err);
              },
            }),
          );
        }),
      ),
    ),
    setControlMode: rxMethod<SetControlMode>(
      pipe(
        filter(
          ({ controlMode }) =>
            !(
              controlMode.type === store.controlMode().type &&
              controlMode.mode === store.controlMode().mode &&
              controlMode.modifier === store.controlMode().modifier
            ),
        ),
        mergeMap(({ controlMode, sendReq = true }) => {
          const prevControlMode = store.controlMode();

          return sendReq
            ? backendEventsService.setControlMode(controlMode).pipe(
                tapResponse({
                  next: () => {
                    if (controlMode.mode === 'character') {
                      patchState(store, {
                        controlMode,
                        ...((prevControlMode.mode === 'freecam' || prevControlMode.type === 'cartographic') && {
                          prevActiveNonMovementType: prevControlMode.mode === 'freecam' ? 'direct' : 'cartographic',
                        }),
                      });
                    } else if (controlMode.mode === 'vehicle') {
                      patchState(store, {
                        controlMode,
                        ...((prevControlMode.mode === 'freecam' || prevControlMode.type === 'cartographic') && {
                          prevActiveNonMovementType: prevControlMode.mode === 'freecam' ? 'direct' : 'cartographic',
                        }),
                      });
                    } else {
                      patchState(store, { controlMode });
                    }
                  },
                  error: (err) => {
                    console.error('setControlMode error', err);
                    patchState(store, { controlMode: prevControlMode });
                  },
                }),
              )
            : of({ success: true }).pipe(tap(() => patchState(store, { controlMode })));
        }),
      ),
    ),
  })),
);
