import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, catchError, map, of } from 'rxjs';

import { environment } from '../../environments/environment';
import { FeatureFlagService } from '../modules/feature-flag/feature-flag.service';
import { Config } from '../types/config.type';
import { ValueOf } from '../types/value-of.type';

/**
 * Класс, представляющий сервис конфигурации.
 * @constructor
 */
@Injectable()
export class ConfigService {
  /**
   * Устанавливает переменную config как пустой объект типа Config.
   * @type {Config}
   */
  #config = {} as Config;

  /**
   * Конструктор класса.
   *
   * @param {HttpClient} httpClient - Экземпляр класса HttpClient.
   * @param {MatSnackBar} matSnackBar - Экземпляр класса MatSnackBar.
   * @param {FeatureFlagService} featureFlagService - Сервис featureFlag, внедренный в конструктор.
   * @return {void}
   */
  constructor(
    private httpClient: HttpClient,
    private matSnackBar: MatSnackBar,
    private featureFlagService: FeatureFlagService,
  ) {}

  /**
   * Загружает конфигурацию с сервера.
   *
   * @returns {Observable<Config>} Наблюдаемый объект, который выдает загруженную конфигурацию.
   */
  loadConfig(): Observable<Config | undefined> {
    const uniqueParam = new Date().getTime();
    const url = `./config/config.json?cacheBuster=${uniqueParam}`;

    return this.httpClient.get<Config>(url).pipe(
      map((config) => {
        this.setConfig(config);
        return config;
      }),
      catchError((error) => {
        console.error('loadConfig', error);
        this.loadConfigErrorSnackBar(error.status);

        return of(undefined);
      }),
    );
  }

  /**
   * Получить значение по ключу из конфигурации.
   *
   * @param {keyof Config} key - Ключ для получения значения из конфигурации.
   * @returns {ValueOf<Config>} - Значение из конфигурации или из окружения.
   */
  getValue(key: keyof Config): ValueOf<Config> {
    if (this.featureFlagService.isFeatureOn('CONFIG')) {
      return this.#config?.[key];
    } else {
      return environment[key];
    }
  }

  /**
   * Отображает snack bar с сообщением об ошибке на основе предоставленного кода статуса.
   *
   * @param {number} status - Код статуса HTTP.
   *
   * @return {void}
   */
  private loadConfigErrorSnackBar(status: number): void {
    this.matSnackBar.open(
      status === 429
        ? $localize`:@@feature-flag.error-429-snack-bar:Слишком частые запросы, пожалуйста попробуйте через несколько минут`
        : $localize`:@@feature-flag.error-other-snack-bar:Ошибка сервера, пожалуйста попробуйте через несколько минут`,
      undefined,
      { duration: 0, panelClass: 'error' },
    );
  }

  /**
   * Устанавливает конфигурацию для объекта.
   *
   * @param {Config} config - Конфигурация для установки.
   * @return {void}
   */
  private setConfig(config: Config): void {
    this.#config = config;
  }
}
