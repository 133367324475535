import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { Status } from '../../../../enums/status.enum';
import { Level } from '../levels.interface';
import { getLevels, getLevelsSuccess, openLevelByName, openLevelByNameSuccess } from './levels.actions';

export interface LevelsState extends EntityState<Level> {
  status: Status;
  error: string | null;
  currentLevelName: string;
}

export const adapter: EntityAdapter<Level> = createEntityAdapter<Level>({ selectId: (event: Level) => event.name });

export const initialState: LevelsState = {
  status: Status.UNINITIALIZED,
  error: null,
  currentLevelName: '',
  ids: [],
  entities: {},
};

const reducer = createReducer(
  initialState,
  on(getLevels, (state): LevelsState => ({ ...state, ...(state.status !== Status.SAVING && { status: Status.LOADING }) })),
  on(getLevelsSuccess, (state, { levels }) =>
    adapter.setAll(levels, { ...state, status: Status.LOADED, currentLevelName: levels.find((item) => item.isCurrent)?.name ?? '' }),
  ),
  on(openLevelByName, (state): LevelsState => ({ ...state, status: Status.SAVING })),
  on(openLevelByNameSuccess, (state, { error }): LevelsState => (error ? { ...state, status: Status.LOADED } : state)),
);

export function levelsReducer(state: LevelsState | undefined, action: Action): LevelsState {
  return reducer(state, action);
}
