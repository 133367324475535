import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, of, switchMap } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { LevelsService } from '../levels.service';
import { getLevels, getLevelsSuccess, openLevelByName, openLevelByNameSuccess } from './levels.actions';

/**
 *  Класс эффектов для взаимодейсвия с хранилицем уровней.
 */
@Injectable()
export class LevelsEffects {
  /**
   * Представляет переменную, инициализирующую эффект для получения уровней.
   * @type {import('rxjs').Observable<Action>}
   */
  getLevels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getLevels),
      switchMap(() => this.levelsService.getLevels().pipe(map((levels) => getLevelsSuccess({ levels })))),
    ),
  );

  /**
   * Создает эффект, который открывает уровень по его имени.
   *
   * @returns {Observable<Action>} Наблюдаемый объект, который излучает действия.
   */
  openLevelByName$ = createEffect(() =>
    this.actions$.pipe(
      ofType(openLevelByName),
      mergeMap(({ level, fromMocks }) => {
        return this.levelsService.openLevelByName(level.name, fromMocks).pipe(
          catchError((error) => {
            !fromMocks && console.error('openLevelByName error', error);
            return of({ error });
          }),
          map((data) => ('error' in data ? openLevelByNameSuccess({ error: 'error' in data }) : getLevels())),
        );
      }),
    ),
  );

  /**
   * Создает новый экземпляр класса. В контексте класса LevelsEffects, этот конструктор используется для внедрения зависимостей Actions и LevelsService, которые затем используются в методах класса для захвата действий и взаимодействия с сервисом уровней.
   *
   * @param {Actions} actions$ - Сервис действий.
   * @param {LevelsService} levelsService - Сервис уровней.
   */
  constructor(
    private actions$: Actions,
    private levelsService: LevelsService,
  ) {}
}
