import { Injectable, signal } from '@angular/core';

import { ObjectInfo } from '../../components/object-card/object-card.interface';
import {
  OSMIncident,
  OSMIncidentGroupedProperties,
  OSMIncidentProperty,
  OSMIncidentPropertyGroup,
  OSMObject,
  OSMObjectGroupedProperties,
  OSMObjectProperty,
  OSMObjectPropertyGroup,
  RelatedIncident,
  TAB_NAME_TO_INDEX,
} from './osm.interface';

@Injectable({
  providedIn: 'root',
})
export class OsmCardService {
  osmAdditionData = signal<{ title: string; activeTabIdx: number; id: string } | null>(null);

  osmIncidentGroupedProperties = signal<OSMIncidentGroupedProperties | null>(null);
  osmObjectGroupedProperties = signal<OSMObjectGroupedProperties | null>(null);

  readonly photos = signal<string[]>([]);

  #osmIncidentPropertiesList: Omit<OSMIncidentProperty, 'value'>[] = [
    { text: $localize`Дата и время`, icon: 'calendarClock', code: 'dateTime', group: OSMIncidentPropertyGroup.GENERAL },
    { text: $localize`Адрес`, icon: 'locationOn', code: 'address', group: OSMIncidentPropertyGroup.GENERAL },
    { text: $localize`Тип инцидента`, icon: 'emergencyAlarmLight', code: 'Type', group: OSMIncidentPropertyGroup.GENERAL },
    { text: $localize`Охват`, icon: 'groups2', code: 'coverage', group: OSMIncidentPropertyGroup.GENERAL },
    { text: $localize`Описание`, icon: 'description', code: 'description', group: OSMIncidentPropertyGroup.GENERAL },
    {
      text: '',
      icon: undefined,
      code: 'servicesWork',
      group: OSMIncidentPropertyGroup.SERVICESWORK,
    },
    {
      text: '',
      icon: undefined,
      code: 'controlWork',
      group: OSMIncidentPropertyGroup.CONTROLWORK,
    },
    {
      text: '',
      icon: undefined,
      code: 'additionalInfo',
      group: OSMIncidentPropertyGroup.ADDITIONALINFO,
    },
    {
      text: '',
      icon: undefined,
      code: 'monitoringData',
      group: OSMIncidentPropertyGroup.MONITORINGDATA,
    },
    {
      text: '',
      icon: undefined,
      code: 'administrativeResponse',
      group: OSMIncidentPropertyGroup.COMPLAINTSVIOLATIONS,
    },
    {
      text: '',
      icon: undefined,
      code: 'complaintsViolations',
      group: OSMIncidentPropertyGroup.ADMINISTRATIVERESPONSE,
    },
    {
      text: '',
      icon: undefined,
      code: 'relatedIncidents',
      group: OSMIncidentPropertyGroup.RELATEDINCIDENTS,
    },
  ];

  #osmObjectPropertiesList: Omit<OSMObjectProperty, 'value'>[] = [
    { text: $localize`Тип объекта`, icon: 'villa', code: 'objectType', group: OSMObjectPropertyGroup.GENERAL },
    { text: $localize`Адрес`, icon: 'locationOn', code: 'address', group: OSMObjectPropertyGroup.GENERAL },
    { text: '', icon: 'locationOn', code: 'status', group: OSMObjectPropertyGroup.STATUS },
    { text: $localize`Балансодержатель`, icon: 'business', code: 'balancer', group: OSMObjectPropertyGroup.CHARACTERISTICS },
    { text: $localize`Количество этажей`, icon: 'floorsNumber', code: 'characteristics', group: OSMObjectPropertyGroup.CHARACTERISTICS },
    { text: $localize`Количество квартир`, icon: 'homeAccount', code: 'characteristics', group: OSMObjectPropertyGroup.CHARACTERISTICS },
  ];

  #osmIncidentCollapsedGroups: OSMIncidentProperty['group']['type'][] = [
    'additionalInfo',
    'servicesWork',
    'controlWork',
    'administrativeResponse',
    'complaintsViolations',
    'monitoringData',
    'relatedIncidents',
  ];

  #osmObjectCollapsedGroups: OSMObjectProperty['group']['type'][] = ['characteristics', 'status'];

  setOsmData(objectInfo: ObjectInfo | { osm: { incident: OSMIncident; object: OSMObject; tabType: 'incident' | 'object' } }): void {
    const osmIncidentProps = objectInfo.osm?.incident;
    const { propertyAttributes, ...osmIncidentWithPropertyAttributesFlat } = {
      ...osmIncidentProps,
      ...objectInfo.osm?.incident.propertyAttributes,
    };

    if (osmIncidentWithPropertyAttributesFlat?.photo) {
      this.photos.set(osmIncidentWithPropertyAttributesFlat.photo);
    }

    const osmIncidentGroupedProperties = this.#osmIncidentPropertiesList.reduce((acc, item) => {
      if (osmIncidentWithPropertyAttributesFlat) {
        let value = osmIncidentWithPropertyAttributesFlat[item.code];

        if (value) {
          if (item.code === 'coverage') {
            value = `${value} человек`;
          }
          if (Array.isArray(value) && value.length < 1) {
            return acc;
          }
          acc = this.pushOSMIncidentProp(acc, item, value);
        }
      }

      return acc;
    }, {} as OSMIncidentGroupedProperties);

    const osmObjectProps = objectInfo.osm?.object;
    const osmObjectGroupedProperties = this.#osmObjectPropertiesList.reduce((acc, item) => {
      if (osmObjectProps) {
        let value;

        if (item.code === 'characteristics' && item.icon.includes('floor')) {
          value = osmObjectProps[item.code].floors;
        } else if (item.code === 'characteristics' && item.icon.includes('home')) {
          value = osmObjectProps[item.code].apartments;
        } else {
          value = osmObjectProps[item.code];
        }

        if (value && typeof value !== 'object') {
          acc = this.pushOSMObjectProp(acc, item, value);
        }
      }

      return acc;
    }, {} as OSMObjectGroupedProperties);

    this.osmAdditionData.set({
      id: osmIncidentProps?.id ?? '',
      title: osmIncidentProps?.title ?? '',
      activeTabIdx: objectInfo.osm?.tabType ? TAB_NAME_TO_INDEX[objectInfo.osm?.tabType] : 0,
    });
    this.osmIncidentGroupedProperties.set(osmIncidentGroupedProperties);
    this.osmObjectGroupedProperties.set(osmObjectGroupedProperties);
  }

  toggleCollapsedOSMIncidentsGroup(group: OSMIncidentGroupedProperties[keyof OSMIncidentGroupedProperties]): void {
    this.osmIncidentGroupedProperties.update((groupedProperties) => {
      if (!groupedProperties) {
        return groupedProperties;
      }

      groupedProperties[group.type].collapsed = !groupedProperties[group.type].collapsed;
      return groupedProperties;
    });
  }

  toggleCollapsedOSMObjectGroup(group: OSMObjectGroupedProperties[keyof OSMObjectGroupedProperties]): void {
    this.osmObjectGroupedProperties.update((groupedProperties) => {
      if (!groupedProperties) {
        return groupedProperties;
      }

      groupedProperties[group.type].collapsed = !groupedProperties[group.type].collapsed;
      return groupedProperties;
    });
  }

  clearData(): void {
    this.osmObjectGroupedProperties.set(null);
    this.osmIncidentGroupedProperties.set(null);
  }

  private pushOSMIncidentProp(
    acc: OSMIncidentGroupedProperties,
    item: Omit<OSMIncidentProperty, 'value'>,
    value: RelatedIncident[] | string[] | number | string,
  ): OSMIncidentGroupedProperties {
    if (acc[item.group.type]) {
      acc[item.group.type].values.push({ ...item, value });
    } else {
      acc[item.group.type] = {
        type: item.group.type,
        collapsed: this.#osmIncidentCollapsedGroups.includes(item.group.type),
        values: [{ ...item, value }],
      };
    }

    return acc;
  }

  private pushOSMObjectProp(
    acc: OSMObjectGroupedProperties,
    item: Omit<OSMObjectProperty, 'value'>,
    value: number | string | null,
  ): OSMObjectGroupedProperties {
    if (acc[item.group.type]) {
      acc[item.group.type].values.push({ ...item, value });
    } else {
      acc[item.group.type] = {
        type: item.group.type,
        collapsed: this.#osmObjectCollapsedGroups.includes(item.group.type),
        values: [{ ...item, value }],
      };
    }

    return acc;
  }
}
