import { Level } from './levels.interface';

export const FROM_MOCKS = false;
export const MAIN_MAP_LEVEL_NAME = 'BP_MetaMoscow_map';

export const mockLevels: Level[] = [
  { name: 'BP_MetaMoscow_map', displayName: 'Локация 1', isEnabled: false, isCurrent: true },
  { name: 'Level name 2', displayName: 'Локация 2', isEnabled: true, isCurrent: false },
  { name: 'Level name 3', displayName: 'Локация 3', isEnabled: false, isCurrent: false },
];
