import { APP_INITIALIZER, Provider } from '@angular/core';
import { Observable, map, mergeMap, of } from 'rxjs';

import { environment } from '../../environments/environment';
import { FeatureFlagService } from '../modules/feature-flag/feature-flag.service';
import { OsmIframeService } from '../modules/osm/osm-iframe.service';
import { AuthCredsService } from '../services/auth-creds.service';
import { ConfigService } from '../services/config.service';

/**
 * Возвращает массив провайдеров, которые будут использоваться в качестве инициализаторов для приложения.
 *
 * @returns {Provider[]} Массив провайдеров для инициализаторов приложения.
 */
export function provideInitializers(): Provider[] {
  return [
    {
      provide: APP_INITIALIZER,
      useFactory:
        (featureFlagService: FeatureFlagService, configService: ConfigService, authCredsService: AuthCredsService) =>
        (): Observable<boolean> => {
          return featureFlagService.loadConfig().pipe(
            mergeMap(() => {
              return (
                featureFlagService.isFeatureOn('CONFIG')
                  ? configService.loadConfig()
                  : of({
                      SIGNALING_URL: environment.SIGNALING_URL,
                      MATCHMAKER_URL: environment.MATCHMAKER_URL,
                      API_URL: environment.API_URL,
                      AUTH_TYPE: environment.AUTH_TYPE,
                      WEB_ADMIN_URL: environment.WEB_ADMIN_URL,
                    })
              ).pipe(
                mergeMap((config) => {
                  if (!config) {
                    return of(false);
                  }

                  return configService.getValue('AUTH_TYPE') === 'application'
                    ? authCredsService.loadCredentials().pipe(map((credentials) => !!credentials))
                    : of(!!config);
                }),
              );
            }),
          );
        },
      deps: [FeatureFlagService, ConfigService, AuthCredsService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (osmIframeService: OsmIframeService) => (): Promise<void> => {
        return osmIframeService.init();
      },
      deps: [OsmIframeService],
      multi: true,
    },
  ];
}
