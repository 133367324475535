import { Environment } from './environment.interface';

/**
 * Представляет текущую конфигурацию среды.
 * @typedef {Object} Environment
 * @property {boolean} production - Указывает, находится ли приложение в режиме production или нет.
 * @property {string} environment - Имя среды.
 * @property {string} MATCHMAKER_URL - URL-адрес сервера сигнализации matchmaker.
 * @property {string} API_URL - URL-адрес сервера API.
 * @property {string} AUTH_TYPE - Тип аутентификации для запросов API.
 * @property {string} WEB_ADMIN_URL - URL-адрес веб-панели администратора.
 * @property {boolean} hasIntoPage - Указывает, есть ли у приложения вступительная страница или нет.
 * @property {boolean} disableUEPing - Указывает на отключение пинга пользовательского опыта или нет.
 */
export const environment: Environment = {
  production: true,
  SIGNALING_URL: $ENV.SIGNALING_URL,
  MATCHMAKER_URL: $ENV.MATCHMAKER_URL ?? 'https://manager.meta.mos.ru/signallingserver',
  API_URL: $ENV.API_URL,
  AUTH_TYPE: $ENV.AUTH_TYPE || '',
  WEB_ADMIN_URL: $ENV.WEB_ADMIN_URL || 'https://webadmin.metapolis.su',
  hasIntoPage: true,
  disableUEPing: false,
  disableShowPSExtendedLogs: false,
};
