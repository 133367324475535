/**
 * Прокручивает страницу до целевого html элемента с указанным id.
 *
 * @param {string} id - id целевого html элемента.
 * @param {Document} document - Объект Document используется для доступа к DOM.
 * @param {'auto' | 'smooth'} [behavior='auto'] - Поведение прокрутки, либо 'auto' (мгновенное), либо 'smooth' (плавная прокрутка).
 * @returns {void}
 */
export function scrollTo(id: string, document: Window['document'], behavior: 'auto' | 'instant' | 'smooth' = 'smooth'): void {
  const element = document.getElementById(id);

  if (element) {
    element.scrollIntoView({ behavior, block: 'start', inline: 'nearest' });
  }
}
