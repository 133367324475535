import { Directive, ElementRef, HostListener } from '@angular/core';

/**
 * Представляет директиву, которая применяет эффект размытия к элементу после того, как он был сфокусирован.
 */
// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[blurAfterFocus]', standalone: true })
export class BlurAfterFocusDirective {
  /**
   * Создает новый экземпляр класса.
   *
   * @param {ElementRef} elRef - Объект ElementRef, представляющий элемент для инициализации.
   */
  constructor(private elRef: ElementRef) {}

  /**
   * Размывает ближайший элемент кнопки или текущий элемент на основе данного события.
   *
   * @param {Event} event - Событие, которое вызвало размытие.
   * @return {void}
   */
  @HostListener('click', ['$event'])
  blur(event: Event): void {
    const closestButton = this.elRef.nativeElement.querySelector('button');

    if (closestButton) {
      closestButton.blur();
    } else if ((event.target as HTMLElement)?.getAttribute('type') === 'button') {
      (event.target as HTMLElement)?.blur();
    } else {
      this.elRef.nativeElement?.blur();
    }
  }
}
