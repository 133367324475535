import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { Status } from '../../../../enums/status.enum';
import { Layer } from '../layers.interface';
import { getLayers, getLayersSuccess, setLayers, setLayersSuccess, setLayersWithRequest, toggleHybridEnabled } from './layers.actions';
import { LayersState } from './layers.interface';

/**
 * В этом коде объявлена переменная 'adapter' типа 'EntityAdapter<Layer>'.
 * Он создается с помощью функции 'createEntityAdapter', которая возвращает объект с вспомогательными методами
 * для работы с коллекцией сущностей. 'EntityAdapter' - это обобщенный тип, параметризованный
 * типом сущности, с которым он будет использоваться, в данном случае 'Layer'.
 *
 * @type {EntityAdapter<Layer>}
 */
export const adapter: EntityAdapter<Layer> = createEntityAdapter<Layer>({ selectId: (event: Layer) => event.guid });
/**
 * Представляет начальное состояние объекта LayersState.
 *
 * @typedef {Object} LayersState
 * @property {boolean} hybridEnabled - Указывает, включен ли гибридный режим.
 * @property {Status} status - Состояние статуса.
 * @property {null|Error} error - Произошедшая ошибка, если таковая имеется.
 * @property {Array} ids - Массив идентификаторов слоев.
 * @property {Object} entities - Объект, содержащий сущности слоев.
 */
export const initialState: LayersState = {
  hybridEnabled: false,
  status: Status.UNINITIALIZED,
  error: null,
  ids: [],
  entities: {},
};
/**
 * Создает функцию редуктора, которая обрабатывает действия, связанные с состоянием слоев.
 *
 * @param initialState Начальное состояние слоев.
 * @param actions Обработчики действий для состояния слоев.
 * @returns Функция редуктора для состояния слоев.
 */
const reducer = createReducer(
  initialState,
  on(getLayers, (state): LayersState => ({ ...state, status: Status.LOADING })),
  on(getLayersSuccess, (state, { layers }) =>
    adapter.addMany(layers, { ...state, hybridEnabled: layers.some((layer) => layer.isEnabled), status: Status.LOADED }),
  ),
  on(toggleHybridEnabled, (state): LayersState => ({ ...state, hybridEnabled: !state.hybridEnabled })),
  on(setLayers, setLayersWithRequest, (state, { layers }) => {
    const updates = layers.map((layer) => ({ id: layer.guid, changes: { isEnabled: layer.isEnabled } }));
    return adapter.updateMany(updates, { ...state, status: Status.SAVING });
  }),
  on(setLayersSuccess, (state, { layersState }): LayersState => {
    const updates = layersState.map((item) => ({ id: item.guid, changes: { isEnabled: item.isEnabled } }));
    return adapter.updateMany(updates, { ...state, status: Status.LOADED });
  }),
);

/**
 * Применяет указанное действие к текущему состоянию слоев.
 *
 * @param {LayersState|undefined} state - Текущее состояние слоев.
 * @param {Action} action - Действие для применения.
 * @returns {LayersState} Обновленное состояние слоев.
 */
export function layersReducer(state: LayersState | undefined, action: Action): LayersState {
  return reducer(state, action);
}
