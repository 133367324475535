import { Pipe, PipeTransform } from '@angular/core';

/**
 * @description Трубка, преобразующая тип данных.
 * @usageNotes
 * Эта трубка может быть использована в шаблонах Angular для преобразования типа данных.
 * Она преобразует тип данных в `never`.
 *
 * @example
 * <!-- Преобразование типа данных в `never` -->
 * <span>{{ value | convertType }}</span>
 */
@Pipe({ name: 'convertType', standalone: true })
export class ConvertTypePipe implements PipeTransform {
  /**
   * Преобразует заданные данные.
   *
   * @param {T} data - Данные для преобразования.
   * @return {never} - Преобразованные данные.
   */
  transform<T>(data: T): never {
    return data as never;
  }
}
