/**
 * ErrorsConstant представляет собой коллекцию сообщений об ошибках.
 *
 * Сообщения об ошибках разделяются по разным модулям и подкатегориям.
 *
 * @typedef {Object} ErrorsConstant
 * @property {Object} dataBridge - Сообщения об ошибках, связанные с data bridge.
 * @property {string} dataBridge.server - Сообщение об ошибке внутреннего сервера.
 * @property {string} dataBridge.timeout - Сообщение об ошибке по истечению времени запроса.
 * @property {Object} search - Сообщения об ошибках, связанные с поиском.
 * @property {string} search.empty - Сообщение об ошибке для пустого результата поиска.
 */
export const ErrorsConstant = {
  dataBridge: {
    server: $localize`:@@errors.dataBridge.server:Произошла внутренняя ошибка сервера. Пожалуйста, попробуйте ещё раз позже.`,
    timeout: $localize`:@@errors.dataBridge,timeout:Время ожидания запроса истекло. Проверьте ваше интернет-соединение и попробуйте снова.`,
  },
  search: {
    empty: $localize`:@@errors.search.empty:К сожалению, по вашему запросу ничего не найдено. Попробуйте изменить или уточнить запрос.`,
  },

  frontendEvents: {
    setCameraCoordinatesEvent: $localize`:@@errors.frontEndEvents.setCameraCoordinatesEvent:Ошибка установки координат камеры.`,
  },

  pixelStreaming: {
    ping: $localize`:@@errors.pixelStreaming.ping:Ошибка видеопотока. Пожалуйста, попробуйте обновить страницу.`,
    setInputState: $localize`:@@errors.pixelStreaming.setInputState:Ошибка видеопотока. Пожалуйста, попробуйте обновить страницу.`,
    streamingEvents: $localize`:@@errors.pixelStreaming.streamingEvents:Соединение с сервером отсутствует. Пожалуйста, проверьте соединение и попробуйте снова.`,
    intervalPingCheck: $localize`:@@errors.pixelStreaming.intervalPingCheck:Соединение с интернетом отсутствует. Пожалуйста обновите страницу`,
  },
  levelsEffect: {
    openLevelByName: $localize`:@@errors.levelsEffect.openLevelByName:Ошибка открытия уровня. Пожалуйста, попробуйте обновить страницу.`,
  },
};

export default ErrorsConstant;
