import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';

import { ANIMATION_TIME } from './constants/app.constant';

/*
   <div [@slideUpDown]="!isDownloadSectionCollapsed"> ...
    or if need to change default timing '0.2s' to '0.7s' should use
   <div [@slideUpDown]="{ value: !isCollapsed, params: { timing: '0.7s', startHeight: '50px' } }"> ...
*/
/**
 * Анимация для скрытия и показа элемента с эффектом скольжения вверх и вниз.
 *
 * @param {number} startHeight - Начальная высота элемента.
 * @param {string} timing - Длительность анимации.
 *
 * @returns {AnimationTriggerMetadata} Триггер анимации.
 */
export const slideUpDownAnimation = trigger('slideUpDown', [
  state('0', style({ height: '{{ startHeight }}', overflow: 'hidden' }), { params: { startHeight: '0' } }),
  state('1', style({ height: '*', overflow: 'visible' })),
  transition(
    '0 => 1',
    [
      animate(
        '{{ timing }}',
        keyframes([
          style({ overflow: 'hidden', height: '{{ startHeight }}', offset: 0 }),
          style({ overflow: 'hidden', height: '*', offset: 0.99 }),
          style({ overflow: 'visible', height: '*', offset: 1 }),
        ]),
      ),
    ],
    { params: { timing: ANIMATION_TIME + 'ms', startHeight: '0' } },
  ),
  transition(
    '1 => 0',
    [
      animate(
        '{{ timing }}',
        keyframes([
          style({ overflow: 'hidden', height: '*', offset: 0 }),
          style({ overflow: 'hidden', height: '{{ startHeight }}', offset: 0.99 }),
          style({ overflow: 'hidden', height: '{{ startHeight }}', offset: 1 }),
        ]),
      ),
    ],
    { params: { timing: ANIMATION_TIME + 'ms', startHeight: '0' } },
  ),
]);

/**
 * Анимация для исчезновения элемента.
 *
 * @type {AnimationTriggerMetadata}
 */
export const fadeOutAnimation = trigger('fadeOut', [
  state('0', style({ opacity: '0', pointerEvents: 'none' })),
  state('1', style({ opacity: '1', pointerEvents: 'auto' })),
  transition(':enter', [style({ opacity: '0', pointerEvents: 'none' })]),
  transition('1 <=> 0', animate('0.4s 0.3s ease')),
]);
