<div class="auth-wrapper">
  <div class="auth-window">
    <h2 class="auth-title" i18n="@@auth.title">Авторизация</h2>
    <form class="auth-form" [formGroup]="form" (ngSubmit)="login()">
      <div class="form-group">
        <label for="username" i18n="@@auth.form.username">Имя пользователя:</label>
        <input class="input" formControlName="username" id="username" name="username" type="text" />
      </div>

      <div class="form-group">
        <label for="password" i18n="@@auth.form.password">Пароль:</label>
        <input autocomplete="on" class="input" formControlName="password" id="password" name="password" type="password" />
      </div>
      <div class="form-group">
        <button i18n="@@auth.form.sign-in" type="submit">Войти в Meta</button>
        @if (message) {
          <p class="login-message" [textContent]="message"></p>
        }
      </div>
    </form>
  </div>
</div>
