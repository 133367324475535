/**
 * Удаляет указанное свойство из объекта и возвращает измененный объект.
 *
 * @template T - Тип объекта.
 * @param {T} obj - Объект, из которого нужно удалить свойство.
 * @param {keyof T} property - Свойство, которое нужно удалить.
 * @returns {Pick<T, Exclude<keyof T, keyof T>>} - Измененный объект без удаленного свойства.
 */
export const deleteObjectProperty = <T extends object>(obj: T, property: keyof T): Pick<T, Exclude<keyof T, keyof T>> => {
  // delete filter[selectName]; -> this mutates.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { [property]: remove, ...rest } = obj;
  return rest;
};
