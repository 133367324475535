import { Pipe, PipeTransform } from '@angular/core';

import { IconType } from '../svg-icon/generated-svg/svg-icons';
import { NotificationType } from './notifications.enum';

/**
 * @description Пайп, которая преобразует значение типа NotificationType в значение типа IconType.
 * @example
 *  <div>
 *    {{ notification.type | notificationsIcon }}
 *  </div>
 */
@Pipe({
  name: 'notificationsIcon',
  standalone: true,
})
export class NotificationsIconPipe implements PipeTransform {
  /**
   * Преобразует NotificationType в IconType.
   * @param {NotificationType} value - Значение ввода NotificationType.
   * @return {IconType} - Соответствующее значение IconType.
   */
  transform(value: NotificationType): IconType {
    switch (value) {
      case NotificationType.ERROR: {
        return 'error';
      }
      case NotificationType.USER_ACTION: {
        return 'checkCircle';
      }

      case NotificationType.INFO:
      default: {
        return 'infoFilled';
      }
    }
  }
}
