import { ChangeDetectionStrategy, Component, Input, OnInit, input, signal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { CarouselModalComponent } from './components/carousel-modal/carousel-modal.component';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrl: './carousel.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselComponent implements OnInit {
  @Input() isModal = false;
  readonly images = input.required<string[]>();
  readonly initIndex = input(0);

  readonly activeSlideIndex = signal(0);

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {
    this.activeSlideIndex.set(this.initIndex());
  }

  switchSlide(value: -1 | 1): void {
    this.activeSlideIndex.set(this.activeSlideIndex() + value);
  }

  openModal(): void {
    this.dialog.open(CarouselModalComponent, {
      width: '100%',
      height: '90%',
      maxWidth: '1030px',
      maxHeight: '686px',
      panelClass: 'no-bg',
      data: { images: this.images(), initIndex: this.activeSlideIndex() },
    });
  }
}
