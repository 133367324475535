<div class="notification" [class.error]="data.type === 'error'">
  <div class="notification__wrapper">
    @if (data.withIcon) {
      <svg-icon class="notification__icon" [name]="data.type | notificationsIcon" />
    }
    <div class="notification__text-block" matSnackBarLabel>
      @if (data.title) {
        <div class="notification__title">{{ data.title }}</div>
      }
      <div class="notification__text">{{ data.text }}</div>
    </div>
  </div>
  <div class="notification__actions-block" matSnackBarActions>
    @if (data.action) {
      <button class="action-btn" mat-button matSnackBarAction type="button" (click)="doAction()">{{ data.action }}</button>
    }

    @if (data.isClosable) {
      <button class="close-btn" matSnackBarAction type="button" (click)="close()">
        <svg-icon class="close-icon" name="close" />
      </button>
    }
  </div>
</div>
